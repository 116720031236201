/* eslint-disable no-underscore-dangle */

import { EMPTY_ARRAY, isDefined } from "@regrello/core-utils";
import { ViewColumnFields, WorkflowDefaultColumnOption } from "@regrello/graphql-api";

import {
  ActualEndDate,
  ConditionalPlannedEndDate,
  CreatedBy,
  EndDate,
  EstimatedEndDate,
  HomeDelayed,
  HomeLatestActivity,
  HomePercentComplete,
  Messages,
  OpenActionItems,
  Owner,
  PlannedEndDate,
  Problem,
  StageStatus,
  StartDate,
  Tags,
  Workflow,
  WorkflowTemplate,
} from "@/strings";

export type HomeDefaultColumnsList = Array<{
  id: WorkflowDefaultColumnOption;
  name: string;
}>;

// The options that are available by default on clicking Columns in the Home page.
export const HOME_DEFAULT_COLUMNS_LIST: HomeDefaultColumnsList = [
  { id: WorkflowDefaultColumnOption.WORKFLOW_EXCEPTION, name: Problem },
  { id: WorkflowDefaultColumnOption.STAGE_STATUS, name: StageStatus },
  { id: WorkflowDefaultColumnOption.WORKFLOW_NAME, name: Workflow },
  { id: WorkflowDefaultColumnOption.CREATED_BY_V2, name: CreatedBy },
  { id: WorkflowDefaultColumnOption.PERCENT_COMPLETE, name: HomePercentComplete },
  { id: WorkflowDefaultColumnOption.WORKFLOW_DAYS_DELAYED, name: HomeDelayed },
  { id: WorkflowDefaultColumnOption.WORKFLOW_UNREAD_MESSAGES, name: Messages },
  { id: WorkflowDefaultColumnOption.WORKFLOW_LAST_UPDATED, name: HomeLatestActivity },
  { id: WorkflowDefaultColumnOption.PENDING_TASKS, name: OpenActionItems },
  { id: WorkflowDefaultColumnOption.START_DATE, name: StartDate },
  // (surya): In favor of least change, we continue showing legacy end date on default columns list.
  { id: WorkflowDefaultColumnOption.END_DATE, name: EndDate },
  { id: WorkflowDefaultColumnOption.OWNER_PARTY, name: Owner },
  { id: WorkflowDefaultColumnOption.WORKFLOW_TEMPLATE_NAME, name: WorkflowTemplate },
  { id: WorkflowDefaultColumnOption.WORKFLOW_TAGS, name: Tags },
  { id: WorkflowDefaultColumnOption.PLANNED_END_DATE, name: PlannedEndDate },
  { id: WorkflowDefaultColumnOption.CONDITIONAL_PLANNED_END_DATE, name: ConditionalPlannedEndDate },
  { id: WorkflowDefaultColumnOption.ESTIMATED_END_DATE, name: EstimatedEndDate },
  { id: WorkflowDefaultColumnOption.ACTUAL_END_DATE, name: ActualEndDate },
];

export function getHomeStartingDefaultColumns(columnsData?: ViewColumnFields[] | undefined) {
  const enabledDefaultColumnOptions = columnsData?.map((column) => column.defaultColumnOption).filter(isDefined);
  return enabledDefaultColumnOptions ?? EMPTY_ARRAY;
}

export function getHomeStartingFieldColumns(columnsData?: ViewColumnFields[] | undefined): ViewColumnFields[] {
  const enabledFieldColumnOptions = columnsData?.filter((column) => column.fieldId != null);
  return enabledFieldColumnOptions ?? EMPTY_ARRAY;
}

export function getColumnFieldId(id: string) {
  const idAsNumber = Number.parseInt(id, 10);
  return !Number.isNaN(idAsNumber) ? idAsNumber : undefined;
}

export function getColumnOption(id: string) {
  return Object.values(WorkflowDefaultColumnOption).find((option) => option === id);
}

/**
 * Util for joining the field ID and property ID into a string for the table column.
 */
export function getColumnIdFromViewColumn(viewColumn: ViewColumnFields): `${number}` | `${number}-${number}` {
  const fieldId = viewColumn.field?.id ?? -1;
  const regrelloObjectPropertyId = viewColumn.regrelloObjectProperty?.id ?? -1;

  if (regrelloObjectPropertyId !== -1) {
    return `${fieldId}-${regrelloObjectPropertyId}`;
  }

  return `${fieldId}`;
}
