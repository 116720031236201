import { t } from "@lingui/macro";
import { clsx, useSimpleHover, WithDataTestId } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { Column, flexRender, Header, Table } from "@tanstack/react-table";
import React, { useCallback, useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { RegrelloDataGridColumnHeaderDragPreview } from "./RegrelloDataGridColumnHeaderDragPreview";
import { RegrelloDataGridColumnHeaderFilter } from "./RegrelloDataGridColumnHeaderFilter";
import { RegrelloDataGridDndType } from "./regrelloDataGridConstants";
import { RegrelloDragPreviewPositioner } from "../../../atoms/dnd/RegrelloDragPreviewPositioner";
import { RegrelloIcon, RegrelloIconName } from "../../../atoms/icons/RegrelloIcon";
import { RegrelloTooltippedInfoIcon } from "../../../atoms/tooltip/RegrelloTooltippedInfoIcon";
import { RegrelloTooltipV4 } from "../../../atoms/tooltip/RegrelloTooltipV4";

export interface RegrelloDataGridColumnHeaderProps<TData> extends WithDataTestId {
  /** Alignment of display content and control buttons in header. */
  alignment: "left" | "center" | "right";

  /**
   * Whether to allow columns in the data grid to be resized. Setting this does not affect
   * individual columns that have `resizable` set to `false`.
   */
  dataGridResizable: boolean;

  /**
   * Column ID to sort data grid by default when all other sorting is cleared. The column will be
   * sorted in ascending order. If this is not set, the default data grid sort will be determined by
   * the order of rows in `data`.
   */
  defaultColumnSort?: keyof TData;

  /**
   * Whether to allow columns to be filtered and display the column-level filter controls. Setting
   * this to `true` will not affect individual columns with `filterable` set to `false`.
   *
   * @default false
   */
  filterable?: boolean;

  /** The header object for this component to reference. */
  header: Header<TData, unknown>;

  /** Callback to handle reordering the columns when a column header is dragged and dropped.  */
  onReorderColumns?: (draggedColumnId: string, targetColumnId: string) => void;

  /** The react-table `Table` object for this component to reference. */
  table: Table<TData>;

  title: string;

  /** If specified, a {@link RegrelloTooltippedInfoIcon} will be displayed alongside this column header containing the given text. */
  tooltipText?: string;
}

/**
 * Internal component used to render a data grid header with drag 'n' drop reordering and resizing
 * functionality.
 */
const RegrelloDataGridColumnHeaderInternal = function RegrelloDataGridColumnHeaderFn<TData>({
  alignment,
  dataGridResizable: isDataGridResizable,
  dataTestId,
  filterable,
  header,
  onReorderColumns,
  table,
  title,
  tooltipText: tooltip,
}: RegrelloDataGridColumnHeaderProps<TData>) {
  const { columnOrder } = table.getState();
  const { isHovered: isHoveredRoot, onMouseEnter: onMouseEnterRoot, onMouseLeave: onMouseLeaveRoot } = useSimpleHover();
  const {
    isHovered: isHoveredResizer,
    onMouseEnter: onMouseEnterResizer,
    onMouseLeave: onMouseLeaveResizer,
  } = useSimpleHover();

  const isHoveredResizerWithNoResizeInProgress = isHoveredResizer && !isAnyColumnResizing(table);

  const [{ isDragOverWithDropTargetLeft, isDragOverWithDropTargetRight }, dropRef] = useDrop({
    accept: RegrelloDataGridDndType.HEADER,
    collect: (monitor) => {
      const draggedItem = monitor.getItem<Column<TData, unknown>>() ?? undefined;

      return {
        isDragOverWithDropTargetLeft:
          monitor.isOver() && isDropTargetLeftOnThePage(draggedItem, header.column, columnOrder),
        isDragOverWithDropTargetRight:
          monitor.isOver() && isDropTargetRightOnThePage(draggedItem, header.column, columnOrder),
      };
    },
    drop: (draggedColumn: Column<TData>) => {
      if (onReorderColumns != null) {
        onReorderColumns(draggedColumn.id, header.column.id);
      }
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => header.column,
    type: RegrelloDataGridDndType.HEADER,
  });

  useEffect(() => {
    previewRef(getEmptyImage());
  }, [previewRef]);

  // (zstanik): State variables that track the current change in column size and whether or not the
  // column is being resized (i.e., whether or not the the resizer handle is active)
  const [deltaOffset, setDeltaOffset] = useState<number>(0);
  const [deltaPercentage, setDeltaPercentage] = useState<number>(0);
  const [isResizing, setIsResizing] = useState(false);

  // (zstanik): This callback for the column resizer handles replaces the default `react-table`
  // resizing interactions. The default implementation calls `table.setColumnResizingInfo()` on
  // every `mouseMouse` event, thus resulting in several spurious table rerenders and laggy
  // performance. This implementation doesn't set the table state until the `mouseUp` event
  // triggers.
  const handleMouseDownResizer = useCallback(
    (event: React.MouseEvent) => {
      if (!header.column.getCanResize()) {
        return;
      }

      table.setColumnSizingInfo((old) => ({
        ...old,
        isResizingColumn: header.column.id,
      }));

      const startOffset = event.clientX;

      // (zstanik): These two callbacks should remain static so that the calls to
      // `document.removeEventListener()` remove the correct event listeners. Thus, they only set
      // the component state to control the table behavior.
      const handleMouseMove = (e: MouseEvent) => {
        setDeltaOffset(e.clientX - startOffset);
        setDeltaPercentage(Math.max((e.clientX - startOffset) / header.getSize(), -0.999999));
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        setIsResizing(false);
      };

      setIsResizing(true);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [header, table],
  );

  // (zstanik): This is the hook that ultimately updates the column size after the resizer handle is
  // released. Note this will be called multiple times unnecessarily as `deltaPercentage` updates.
  // But this didn't result in a noticeable performance hit and it won't get worse as more columns
  // are added, so punting for now. A better method may be determined later.
  useEffect(() => {
    if (!isResizing) {
      const newColumnSizing: Record<string, number> = {};
      newColumnSizing[header.column.id] = Math.round(Math.max(header.getSize() * (1 + deltaPercentage), 0) * 100) / 100;
      table.setColumnSizing((old) => ({ ...old, ...newColumnSizing }));
      table.setColumnSizingInfo((old) => ({
        ...old,
        isResizingColumn: false,
      }));
      setDeltaOffset(0);
      setDeltaPercentage(0);
    }
  }, [deltaPercentage, header, isResizing, table]);

  const sortedAscendingIconName: RegrelloIconName = "sort-ascending";
  const sortedDescendingIconName: RegrelloIconName = "sort-descending";

  const sortState = header.column.getIsSorted();
  const isSorted = sortState !== false;

  return (
    <th
      ref={onReorderColumns != null ? dropRef : null}
      className={clsx(
        "bg-neutralOpaque-soft border-b [&:last-child_.resizerContainer]:pr-0 [&:last-child_.resizerContainer]:right-0",
        {
          "cursor-pointer": header.column.getCanSort(),
          "cursor-grabbing": isDragging || header.column.getIsResizing(),
          "shadow-[inset_2px_0_0_0] shadow-primary-solid": isDragOverWithDropTargetLeft,
          "shadow-[inset_-2px_0_0_0] shadow-primary-solid": isDragOverWithDropTargetRight,
        },
      )}
      colSpan={header.colSpan}
      data-testid={dataTestId}
      style={{ width: header.getSize() }}
    >
      <div ref={onReorderColumns != null ? dragRef : null} className="flex flex-col">
        <div
          className={clsx("flex items-center min-w-0 h-10 px-2", "transition duration-100", {
            "pointer-events-none": isSomeOtherColumnResizing(table, header.column.id),
            "hover:bg-neutral-soft":
              (header.column.getCanSort() || (isDataGridResizable && header.column.getCanResize())) &&
              // (clewis): Prevent an initial hover-state flicker that can happen even though
              // pointer-events is disabled above:
              !isSomeOtherColumnResizing(table, header.column.id),
            "bg-neutral-soft": header.column.getIsResizing(),
            "justify-start": alignment === "left",
            "justify-center": alignment === "center",
            "justify-end": alignment === "right",
          })}
          onClick={header.column.getToggleSortingHandler()}
          onMouseEnter={onMouseEnterRoot}
          onMouseLeave={onMouseLeaveRoot}
        >
          {/* Title */}
          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}

          {/* Tooltip info icon */}
          <RegrelloTooltippedInfoIcon tooltipText={tooltip} />

          {/* Sort indicator */}
          {header.column.getCanSort() && (isSorted || !header.column.getIsResizing()) && (
            <RegrelloTooltipV4
              content={
                !isSorted
                  ? // Off state
                    t`Sort descending`
                  : sortState === "asc"
                    ? t`Sorted ascending`
                    : t`Sorted descending`
              }
            >
              <div className={clsx("ml-0.5", sortState || isHoveredRoot ? "visible" : "hidden")}>
                <RegrelloIcon
                  dataTestId={sortState === "desc" ? DataTestIds.TABLE_SORT_ICON_DESC : DataTestIds.TABLE_SORT_ICON_ASC}
                  iconName={
                    alignment !== "left"
                      ? "blank"
                      : !isSorted || sortState === "desc"
                        ? sortedDescendingIconName
                        : sortedAscendingIconName
                  }
                  intent="neutral"
                  size="x-small"
                />
              </div>
            </RegrelloTooltipV4>
          )}
        </div>

        {/* Filter */}
        {filterable ? (
          <RegrelloDataGridColumnHeaderFilter
            className={clsx("h-10", {
              "hover:bg-neutral-softHovered": header.column.getCanFilter(),
              "justify-start": alignment === "left",
              "justify-center": alignment === "center",
              "justify-end": alignment === "right",
            })}
            column={header.column}
          />
        ) : null}
      </div>

      {/* Resize handle */}
      {isDataGridResizable && header.column.getCanResize() && (
        <div
          className={clsx(
            "absolute flex justify-end pr-2 right--2 top-0 h-full w-4 cursor-col-resize select-none touch-none z-2",
            {
              resizerContainer: true,
              "[&>div]:bg-primary-solid": isHoveredResizerWithNoResizeInProgress || header.column.getIsResizing(),
            },
          )}
          onMouseDown={handleMouseDownResizer}
          onMouseEnter={onMouseEnterResizer}
          onMouseLeave={onMouseLeaveResizer}
          style={{
            transform: header.column.getIsResizing() ? `translateX(${deltaOffset}px)` : "",
          }}
        >
          <div
            className={clsx(
              "relative w-0.75 h-full",
              "after:hidden after:content-[''] after:absolute after:top-0 after:right-px after:w-px after:bottom-[-10000px] after:bg-primary-solid",
              {
                "after:block": header.column.getIsResizing(),
              },
            )}
          />
          {/* <div className={clsx("after:content-['']")} /> */}
        </div>
      )}

      {isDragging && (
        <RegrelloDragPreviewPositioner
          className="z-dragPreview"
          initialOffsetWithinDragHandle={{ x: 0, y: 0 }}
          item={header.column}
          itemType={RegrelloDataGridDndType.HEADER}
        >
          <RegrelloDataGridColumnHeaderDragPreview title={title} />
        </RegrelloDragPreviewPositioner>
      )}
    </th>
  );
};

export const RegrelloDataGridColumnHeader = React.memo(
  RegrelloDataGridColumnHeaderInternal,
) as typeof RegrelloDataGridColumnHeaderInternal;

function isDropTargetLeftOnThePage<TData>(
  draggedColumn: Column<TData, unknown>,
  droppedOverColumn: Column<TData, unknown>,
  columnOrder: string[],
): boolean {
  return (
    draggedColumn != null &&
    droppedOverColumn != null &&
    columnOrder.indexOf(droppedOverColumn.id) < columnOrder.indexOf(draggedColumn.id)
  );
}

function isDropTargetRightOnThePage<TData>(
  draggedColumn: Column<TData, unknown>,
  droppedOverColumn: Column<TData, unknown>,
  columnOrder: string[],
): boolean {
  return (
    draggedColumn != null &&
    droppedOverColumn != null &&
    columnOrder.indexOf(droppedOverColumn.id) > columnOrder.indexOf(draggedColumn.id)
  );
}

function isAnyColumnResizing<TData>(table: Table<TData>): boolean {
  return !table.getAllColumns().every((column) => !column.getIsResizing());
}

function isSomeOtherColumnResizing<TData>(table: Table<TData>, myColumnId: string): boolean {
  const resizingColumnIds = new Set(
    table
      .getAllColumns()
      .filter((column) => column.getIsResizing())
      .map((column) => column.id),
  );
  return resizingColumnIds.size > 0 && !resizingColumnIds.has(myColumnId);
}
