import { t } from "@lingui/macro";
import { clsx, WithClassName, WithDataTestId } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React, { RefObject } from "react";
import { CountryIso2, defaultCountries, parseCountry } from "react-international-phone";

import {
  RegrelloCommandInput,
  RegrelloCommandItem,
  RegrelloCommandList,
  RegrelloCommandRoot,
} from "../command/RegrelloCommand";

export interface RegrelloPhoneInputProps extends WithDataTestId, WithClassName {
  buttonRef: RefObject<HTMLButtonElement>;
  country: ReturnType<typeof parseCountry>;
  inputRef: RefObject<HTMLInputElement>;
  setCountry: (
    countryIso2: CountryIso2,
    options?:
      | {
          focusOnInput: boolean;
        }
      | undefined,
  ) => void;
}

function getFlagUrl(countryCode: string) {
  return `/flags/${countryCode}.svg`;
}

const RegrelloPhoneInputCountrySelector = React.memo<RegrelloPhoneInputProps>(
  ({ buttonRef, inputRef, country, setCountry }) => {
    return (
      <RegrelloCommandRoot
        data-testid={DataTestIds.FORM_FIELD_SELECT_OPTIONS_ROOT}
        style={{
          maxHeight: "var(--radix-popover-content-available-height)",
          // (dosipiuk): this arbitrary value of 300px is taken from previous implementation.
          maxWidth: "max(var(--radix-popover-trigger-width), 300px)",
        }}
      >
        <RegrelloCommandInput data-testid={DataTestIds.FORM_FIELD_SELECT_MENU_INPUT} placeholder={t`Search`} />

        <RegrelloCommandList>
          {defaultCountries.map((c) => {
            const parsedCountry = parseCountry(c);
            const isSelected = country.iso2 === parsedCountry.iso2;
            return (
              <RegrelloCommandItem
                key={parsedCountry.iso2}
                className={clsx({
                  "bg-primary-soft hover:bg-primary-softHovered active:bg-primary-softPressed aria-selected:bg-primary-softHovered":
                    isSelected,
                })}
                data-testid={DataTestIds.FORM_FIELD_SELECT_OPTION}
                onSelect={() => {
                  setCountry(parsedCountry.iso2);
                  buttonRef.current?.click();
                  inputRef.current?.focus();
                }}
                selected={isSelected}
                text={
                  <div className="flex items-center gap-2">
                    <img
                      alt={parsedCountry.name}
                      className="w-6 aspect-4/3 border rounded"
                      data-country={parsedCountry.iso2}
                      draggable={false}
                      loading="lazy"
                      src={getFlagUrl(parsedCountry.iso2)}
                    />
                    <span className="text-left">
                      {parsedCountry.name} (+{parsedCountry.dialCode})
                    </span>
                  </div>
                }
                value={`${parsedCountry.name} +${parsedCountry.dialCode}`}
              />
            );
          })}
        </RegrelloCommandList>
      </RegrelloCommandRoot>
    );
  },
);

RegrelloPhoneInputCountrySelector.displayName = "RegrelloPhoneInputCountrySelector";

export { RegrelloPhoneInputCountrySelector };
