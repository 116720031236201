import { t } from "@lingui/macro";
import { clsx } from "@regrello/core-utils";
import {
  RegrelloIconName,
  RegrelloTooltippedInfoIcon,
  RegrelloTooltipV4Props,
  RegrelloTypography,
} from "@regrello/ui-core";
import React from "react";

import { DETAIL_DIALOG_FORM_FIELD_WIDTH_PX } from "../../../../utils/actionItemUtils";

export interface RegrelloFormLabelProps {
  htmlFor?: string;

  /**
   * If defined, an info icon will be rendered to the right of the label with this text as tooltip
   * content.
   */
  infoTooltipText?: string;

  /**
   * The name of the tooltip triggering icon
   */
  infoTooltipIconName?: RegrelloIconName;

  /**
   * The visual treatment to give to the tooltip.
   * - `"default"`: A standard dark-background tooltip.
   * - `"popover"`: A light-background tooltip with a border.
   * @default "default"
   */
  infoTooltipVariant?: RegrelloTooltipV4Props["variant"];

  /**
   * Whether to zero out default margins around the form field label.
   *
   * @default false
   */
  isDefaultMarginsOmitted?: boolean;

  /** Whether to show a tooltiped deleted badge besides the label. */
  isDeleted?: boolean;

  isRequiredAsteriskShown?: boolean;

  /**
   * Whether the label text should be all uppercase. If not provided, the original letter case of
   * the label is preserved.
   */
  isLabelUppercase?: boolean;

  isLabelVerticallyCentered?: boolean;

  label: React.ReactChild;

  /**
   * How to align the label text in relation to the form field.
   */
  labelAlignment?: "left" | "center" | "right";

  labelWidth?: number | string;

  /** Whether the form field is rendered by a spectrum form. */
  variant?: "default" | "spectrum";
}

export const RegrelloFormLabel = React.memo<RegrelloFormLabelProps>(function RegrelloFormLabelFn({
  htmlFor,
  infoTooltipText,
  infoTooltipIconName = "info-outline",
  infoTooltipVariant = "default",
  isDeleted = false,
  isDefaultMarginsOmitted = false,
  isRequiredAsteriskShown = false,
  isLabelUppercase = false,
  isLabelVerticallyCentered = false,
  label,
  labelAlignment = "left",
  labelWidth,
  variant = "default",
}) {
  return (
    <label
      className={clsx("shrink-0", {
        uppercase: isLabelUppercase,
        "text-center": labelAlignment === "center",
        "text-left": labelAlignment === "left",
        "text-right": labelAlignment === "right",
      })}
      htmlFor={htmlFor}
      style={{ width: labelWidth ?? DETAIL_DIALOG_FORM_FIELD_WIDTH_PX }}
    >
      <RegrelloTypography
        // (clewis): Need to set an explicit color to avoid getting grayed out by FormLabel styles.
        className={clsx("pt-2", {
          "mt-2.25": isLabelVerticallyCentered && !isDefaultMarginsOmitted,
          "text-textDefault": variant === "default",
          "font-normal text-textMuted": variant === "spectrum",
        })}
        component="div"
        variant="body"
      >
        <span
          className={clsx("font-semibold hyphens-auto break-words", {
            "font-normal": variant === "spectrum",
          })}
        >
          {label}
        </span>
        {isRequiredAsteriskShown && <span className="text-danger-textMuted ml-1">*</span>}
        <RegrelloTooltippedInfoIcon
          className="mt-0.5 ml-1"
          iconName={infoTooltipIconName}
          tooltipText={infoTooltipText}
          variant={infoTooltipVariant}
        />
        {isDeleted && (
          <RegrelloTypography className="inline-flex items-center ml-1" muted={true}>
            {`(${t`Deleted`})`}
            <RegrelloTooltippedInfoIcon
              iconName="info-outline"
              tooltipText={t`This field has been deleted from your workspace. We recommend using a different field instead.`}
              variant="popover"
            />
          </RegrelloTypography>
        )}
      </RegrelloTypography>
    </label>
  );
});
