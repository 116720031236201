import { t } from "@lingui/macro";
/* eslint-disable class-methods-use-this */
import { EMPTY_ARRAY, noop } from "@regrello/core-utils";
import {
  FormConstraintConditionOperator,
  PropertyDataType,
  PropertyTypeFields,
  RegrelloObjectInstanceFields,
  RegrelloObjectType,
  SpectrumFieldValidationTypeFields,
  SpectrumFieldVersionFields,
  SpectrumValueConstraintFields,
  UserType,
} from "@regrello/graphql-api";
import { ReactNode } from "react";
import { FieldArrayWithId, UseFormReturn } from "react-hook-form";

import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";
import { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloFormFieldRegrelloObject } from "../formFields/RegrelloFormFieldRegrelloObject";

type RegrelloObjectPluginFrontendValue = RegrelloObjectInstanceFields[] | undefined;

export class SpectrumObjectFieldPluginDecorator extends SpectrumFieldPluginDecorator<RegrelloObjectPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<RegrelloObjectPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.regrelloObject";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.RAW;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.REGRELLO_OBJECT_INSTANCE_ID);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find((validationType) => validationType.validationType === SpectrumFieldValidationType.RAW);
  }

  public findValueConstraintsFromLoadedValueConstraints(_valueConstraints: SpectrumValueConstraintFields[]) {
    return EMPTY_ARRAY;
  }

  public isDataFormatToggleVisible() {
    return false;
  }

  public isValueConstraintEnabled() {
    return false;
  }

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.IS_ZERO]: {
        label: t`Is empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_NOT_ZERO]: {
        label: t`Is not empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.EQ]: {
        label: t`Is equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.NOT]: {
        label: t`Is not equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_CONTAINS]: {
        label: t`Contains`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_NOT_CONTAINS]: {
        label: t`Does not contain`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_STARTS_WITH]: {
        label: t`Starts with`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_ENDS_WITH]: {
        label: t`Ends with`,
        inputCount: 1,
        isMultiselect: false,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onChange" as const;
  }

  public renderPreviewFormField() {
    return (
      <RegrelloFormFieldRegrelloObject
        disabled={true}
        onChange={noop}
        regrelloObject={{
          id: 0,
          name: "",
          type: RegrelloObjectType.SYNC_OBJECT,
          createdAt: "",
          updatedAt: "",
          dataUpdatedAt: undefined,
          deletedAt: undefined,
          syncConfig: undefined,
          syncEvents: [],
          createdBy: {
            __typename: "User",
            id: 0,
            name: undefined,
            email: "",
            userType: UserType.DEFAULT,
            isMuted: false,
            deletedAt: undefined,
            accessRole: undefined,
            party: {
              id: 0,
            },
          },
          properties: [],
        }}
      />
    );
  }

  public renderValueConstraints(_props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints", "id">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
    focusField: `valueConstraints.${number}.args.${number}`;
  }): ReactNode {
    return null;
  }
}
