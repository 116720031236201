import { t } from "@lingui/macro";
import { EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { ConditionConnective, FieldInstanceFields } from "@regrello/graphql-api";
import { RegrelloButton } from "@regrello/ui-core";
import { isValid } from "date-fns";
import React from "react";
import { FieldArrayWithId, UseFormReturn, useFormState, useWatch } from "react-hook-form";

import {
  RegrelloScheduleStartAfterDependency,
  RegrelloScheduleTimeFormSectionBase,
  ScheduleTimeKeys,
  ScheduleTimeValues,
} from "./RegrelloScheduleTimeFormSectionBase";
import {
  START_CONDITION_BETWEEN_LABEL_WIDTH,
  START_CONDITION_CONNECTIVES,
  START_CONDITION_LABEL_WIDTH,
} from "./scheduleTimeConstants";
import { ValidationRules } from "../../../../../constants/globalConstants";
import { isOnSplitChild, isOnSplitterTask } from "../../../../../utils/customFields/splitFieldUtils";
import { CustomFieldPluginRegistrar } from "../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { ConditionOperatorConfig } from "../../../../molecules/customFields/plugins/types/ConditionOperator";
import { CustomFieldPlugin } from "../../../../molecules/customFields/plugins/types/CustomFieldPlugin";
import { RegrelloFormFieldLayout } from "../../../../molecules/formFields/_internal/RegrelloFormFieldLayout";
import {
  RegrelloControlledFormFieldCustomFieldInstanceSelect,
  RegrelloControlledFormFieldSelect,
} from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";

export interface RegrelloConfigureConditionsFormSectionProps {
  /**
   * The field plugins allowed in the custom field instance select; i.e., the only field instances
   * that can be selected are those that can be processed by one of the field plugins in this array.
   */
  allowedFieldPlugins: Array<CustomFieldPlugin<unknown>>;

  /**
   * The form for configuring the schedule time of the stage or action item. Should have `mode`
   * set to 'all' for form validation to work properly.
   */
  form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>;

  /**
   * Whether the entire schedule time section is disabled.
   */
  isDisabled: boolean;

  /**
   * Callback invoked when the `Add Condition` button is clicked.
   */
  onConditionAdd: () => void;

  /**
   * Callback invoked when a condition is deleted.
   */
  onConditionDelete: (index?: number | number[] | undefined) => void;

  /**
   * Callback invoked when a condition field instance changes.
   */
  onConditionFieldInstanceChange: (newFieldInstance: FieldInstanceFields | null, index: number) => void;

  /**
   * Callback invoked when a condition field property changes.
   */
  onConditionFieldPropertyChange?: (
    newFieldInstance: FieldInstanceFields | null,
    index: number,
    newPropertyId: number,
  ) => void;

  /**
   * Callback invoked when a condition operator changes.
   */
  onConditionOperatorChange: (
    currentFieldInstance: FieldInstanceFields | null,
    fieldInstancePropertyId: number | null,
    newOperator: ConditionOperatorConfig | null,
    oldValue1: unknown,
    index: number,
  ) => void;

  /**
   * Callback invoked when the condition start after stage dependency changes.
   */
  onConditionStageDependencyChange?: (name: string, newValue: RegrelloScheduleStartAfterDependency | null) => void;

  /**
   * The options to render for the user to select as a start after dependency.
   */
  startAfterDependencies?: RegrelloScheduleStartAfterDependency[];

  /**
   * The start condition schedule time option selected by the user. Only 2 options should render
   * this component, hence the stricter union type. If not defined, it's assumed this component is
   * being rendered as a standalone form section.
   */
  startConditionOption?: ScheduleTimeValues.START_CONDITION_STAGE | ScheduleTimeValues.START_CONDITION_WORKFLOW;

  /**
   * The `fieldArray` rows to use when rendering form fields for configuring conditions.
   */
  conditionRows: Array<FieldArrayWithId<RegrelloScheduleTimeFormSectionBase.Fields, ScheduleTimeKeys.CONDITIONS, "id">>;

  /**
   * Current workflow or workflow template context for the stage. Used to determine which field
   * instances to show users when selecting fields for start conditions.
   */
  workflowContext:
    | {
        type: "workflowTemplate";
        workflowTemplateId: number;
        workflowTemplateIsCreateViaEmailEnabled: boolean;
      }
    | {
        type: "workflow";
        workflowId: number;
      };
}

/** A form section for configuring conditions involving field instances. */
export const RegrelloConfigureConditionsFormSection = React.memo<RegrelloConfigureConditionsFormSectionProps>(
  function RegrelloConfigureConditionsFormSectionFn({
    allowedFieldPlugins,
    form,
    isDisabled,
    onConditionAdd,
    onConditionDelete,
    onConditionFieldInstanceChange,
    onConditionFieldPropertyChange,
    onConditionOperatorChange,
    onConditionStageDependencyChange,
    startAfterDependencies,
    startConditionOption,
    conditionRows,
    workflowContext,
  }: RegrelloConfigureConditionsFormSectionProps) {
    const startConditionStageDependency = useWatch({
      control: form.control,
      name: ScheduleTimeKeys.START_CONDITION_DEPENDENCY,
    });
    const startConditions = useWatch({
      control: form.control,
      name: ScheduleTimeKeys.CONDITIONS,
    });
    const connective = useWatch({
      control: form.control,
      name: ScheduleTimeKeys.CONNECTIVE,
    });

    const { isValid: isStartDateFormValid } = useFormState({ control: form.control });

    // (wsheeha): Split fields can't be used for start conditions.
    const filterCondition = (fieldInstance: FieldInstanceFields) =>
      !isOnSplitterTask(fieldInstance) && !isOnSplitChild(fieldInstance);

    return (
      <>
        {startConditionOption === ScheduleTimeValues.START_CONDITION_STAGE && (
          <RegrelloFormFieldLayout
            className="mb-0"
            isLabelUppercase={true}
            label={t`After`}
            labelAlignment="right"
            labelWidth={START_CONDITION_LABEL_WIDTH}
          >
            <RegrelloControlledFormFieldSelect
              className="w-1/2"
              controllerProps={{
                control: form.control,
                name: ScheduleTimeKeys.START_CONDITION_DEPENDENCY,
                rules: ValidationRules.REQUIRED,
              }}
              dataTestId={DataTestIds.START_DEPENDENCY_START_AFTER_STAGE_SELECT}
              disabled={isDisabled}
              getOptionLabel={(option) => option.name}
              isRequiredAsteriskShown={true}
              onValueChange={onConditionStageDependencyChange}
              options={startAfterDependencies ?? EMPTY_ARRAY}
              placeholder={t`Select stage`}
            />
          </RegrelloFormFieldLayout>
        )}
        {conditionRows.map(({ id }, index) => {
          const fieldInstance = form.watch(`conditions.${index}.fieldInstance`) as FieldInstanceFields | null;
          const fieldInstancePropertyId = form.watch(`conditions.${index}.fieldInstancePropertyId`) as number;

          const conditionOperator = form.watch(`conditions.${index}.operator`) as ConditionOperatorConfig | null;

          const value1 = form.watch(`conditions.${index}.value1`) as unknown;
          const value2 = form.watch(`conditions.${index}.value2`) as unknown;

          const fieldInstanceProperties =
            fieldInstance &&
            CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).getFieldProperties?.(
              fieldInstance.field,
            );

          return (
            <React.Fragment key={id}>
              <RegrelloFormFieldLayout
                className="mb-0"
                isLabelUppercase={true}
                label={
                  index === 1
                    ? EMPTY_STRING
                    : index === 0
                      ? t`If`
                      : connective === ConditionConnective.AND
                        ? t`And if`
                        : t`Or if`
                }
                labelAlignment="right"
                labelWidth={index === 1 ? 0 : START_CONDITION_LABEL_WIDTH}
              >
                <div className="flex gap-2 justify-start">
                  {index === 1 && (
                    <RegrelloControlledFormFieldSelect
                      className="min-w-22.5 uppercase"
                      controllerProps={{
                        control: form.control,
                        name: ScheduleTimeKeys.CONNECTIVE,
                        rules: ValidationRules.REQUIRED,
                      }}
                      dataTestId={DataTestIds.START_DEPENDENCY_CONDITION_CONNECTIVE_SELECT}
                      disabled={isDisabled}
                      getOptionLabel={(option) => (option === ConditionConnective.AND ? t`And if` : t`Or if`)}
                      isFilterable={false}
                      options={START_CONDITION_CONNECTIVES}
                    />
                  )}
                  <RegrelloControlledFormFieldCustomFieldInstanceSelect
                    allowedFieldPlugins={allowedFieldPlugins}
                    className="w-1/3"
                    controllerProps={{
                      control: form.control,
                      name: `conditions.${index}.fieldInstance`,
                      rules: ValidationRules.REQUIRED,
                    }}
                    dataTestId={DataTestIds.START_DEPENDENCY_START_CONDITION_FIELD_SELECT}
                    disabled={
                      isDisabled ||
                      (startConditionStageDependency == null &&
                        startConditionOption === ScheduleTimeValues.START_CONDITION_STAGE)
                    }
                    filterCondition={filterCondition}
                    isAllowEmptyOptionalFieldInstances={true}
                    isInactiveFieldInstancesHidden={true}
                    isRetainOptionalFieldInstanceInputType={true}
                    isSourceHelperTextVisible={true}
                    onValueChange={(_, newValue) => onConditionFieldInstanceChange(newValue, index)}
                    optionsConfig={{
                      type: "asyncLoaded",
                      dependingOnActionItemTemplateId: undefined,
                      workflowContext:
                        workflowContext.type === "workflow"
                          ? {
                              type: "workflow",
                              workflowId: workflowContext.workflowId,
                              workflowStageId: undefined,
                              dependingOnWorkflowStageId: startConditionStageDependency?.id,
                            }
                          : {
                              type: "workflowTemplate",
                              workflowTemplateId: workflowContext.workflowTemplateId,
                              workflowStageTemplateId: undefined,
                              workflowTemplateIsCreateViaEmailEnabled:
                                workflowContext.workflowTemplateIsCreateViaEmailEnabled,
                              dependingOnWorkflowStageTemplateId: startConditionStageDependency?.id,
                            },
                    }}
                    placeholder={t`Select field`}
                  />
                  {fieldInstance != null && fieldInstanceProperties != null && fieldInstanceProperties.length > 0 ? (
                    <RegrelloControlledFormFieldSelect
                      className="w-1/3"
                      controllerProps={{
                        control: form.control,
                        name: `conditions.${index}.fieldInstancePropertyId`,
                        rules: ValidationRules.REQUIRED,
                      }}
                      dataTestId={DataTestIds.START_DEPENDENCY_FIELD_PROPERTY}
                      getOptionLabel={(option) =>
                        fieldInstanceProperties.find((prop) => prop.id === option)?.name || String(option)
                      }
                      isFilterable={false}
                      onValueChange={(_, newValue) => {
                        if (newValue) {
                          onConditionFieldPropertyChange?.(fieldInstance, index, newValue);
                        }
                      }}
                      options={
                        fieldInstanceProperties != null ? fieldInstanceProperties.map((prop) => prop.id) : EMPTY_ARRAY
                      }
                      placeholder={t`Select property`}
                    />
                  ) : null}
                  <RegrelloControlledFormFieldSelect
                    className="w-1/3"
                    controllerProps={{
                      control: form.control,
                      name: `conditions.${index}.operator`,
                      rules: ValidationRules.REQUIRED,
                    }}
                    dataTestId={DataTestIds.START_DEPENDENCY_OPERATOR_SELECT}
                    disabled={
                      isDisabled ||
                      fieldInstance == null ||
                      (fieldInstanceProperties != null && fieldInstancePropertyId == null)
                    }
                    getOptionLabel={(option) => option.displayValue}
                    isFilterable={false}
                    onValueChange={(_, newValue) =>
                      onConditionOperatorChange(fieldInstance, fieldInstancePropertyId, newValue, value1, index)
                    }
                    options={
                      fieldInstance != null
                        ? CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).getConditionOperators()
                        : EMPTY_ARRAY
                    }
                    placeholder={t`Select condition`}
                  />
                  {conditionOperator != null &&
                    fieldInstance != null &&
                    conditionOperator.numFields > 0 &&
                    CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).renderFormField(
                      fieldInstance.field,
                      {
                        className: conditionOperator.numFields > 1 ? "grow mr-0" : "grow overflow-hidden",
                        controllerProps: {
                          control: form.control,
                          name: `conditions.${index}.value1`,
                          rules: {
                            ...ValidationRules.REQUIRED,
                            ...(conditionOperator.numFields > 1
                              ? isValid(value2)
                                ? ValidationRules.BEFORE_DATE(value2 as Date)
                                : isValidNonEmptyNumber(value2)
                                  ? ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number(value2))
                                  : {}
                              : {}),
                          },
                        },
                        disabled: isDisabled,
                        operator: conditionOperator.operator,
                        fieldInstance,
                      },
                    )}
                  {conditionOperator != null && fieldInstance != null && conditionOperator.numFields > 1 && (
                    <RegrelloFormFieldLayout
                      className="grow m-0 p-0"
                      isLabelUppercase={true}
                      label={t`And`}
                      labelAlignment="center"
                      labelWidth={START_CONDITION_BETWEEN_LABEL_WIDTH}
                    >
                      {CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).renderFormField(
                        fieldInstance.field,
                        {
                          controllerProps: {
                            control: form.control,
                            name: `conditions.${index}.value2`,
                            rules: {
                              ...ValidationRules.REQUIRED,
                              ...(isValid(value1)
                                ? ValidationRules.AFTER_DATE(value1 as Date)
                                : isValidNonEmptyNumber(value1)
                                  ? ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number(value1))
                                  : {}),
                            },
                          },
                          disabled: isDisabled,
                          operator: conditionOperator.operator,
                          fieldInstance,
                        },
                      )}
                    </RegrelloFormFieldLayout>
                  )}
                  <RegrelloButton
                    className="ml-auto"
                    disabled={isDisabled || startConditions?.length <= 1}
                    iconOnly={true}
                    onClick={() => onConditionDelete(index)}
                    startIcon="delete"
                    variant="ghost"
                  />
                </div>
              </RegrelloFormFieldLayout>
            </React.Fragment>
          );
        })}
        <RegrelloButton
          // (zstanik): Add some arbitrary margin to make the add button line up with the field
          // instance selects.
          className="ml-24.5"
          dataTestId={DataTestIds.START_DEPENDENCY_ADD_START_CONDITION_BUTTON}
          disabled={isDisabled || !isStartDateFormValid}
          intent="primary"
          onClick={onConditionAdd}
          startIcon="add"
          variant="ghost"
        >
          {t`Add condition`}
        </RegrelloButton>
      </>
    );
  },
);

function isValidNonEmptyNumber(value: unknown): boolean {
  return value != null && value !== "" && !Number.isNaN(Number(value));
}
