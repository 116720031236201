import { t } from "@lingui/macro";
import { clsx, EMPTY_STRING, KeyNames, mergeRefs, queueMacrotask } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  RegrelloButton,
  RegrelloButtonProps,
  RegrelloCommandEmpty,
  RegrelloCommandGroup,
  RegrelloCommandInput,
  RegrelloCommandItem,
  RegrelloCommandList,
  RegrelloCommandLoading,
  RegrelloCommandRoot,
  RegrelloField,
  RegrelloIcon,
  RegrelloPopover,
  RegrelloPopoverProps,
  RegrelloSize,
  RegrelloSpinner,
} from "@regrello/ui-core";
import React, { Fragment, ReactNode, useCallback, useEffect, useId, useMemo, useRef, useState } from "react";

import { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldEmptyValueNone } from "./_internal/RegrelloFormFieldEmptyValueNone";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";
import { getSelfContainedFormInternal } from "./_internal/selfContainedForm/getSelfContainedFormInternal";

export type RegrelloSelectChangeReason = "create-option" | "select-option" | "remove-option" | "clear" | "blur";

const DEFAULT_GROUP = "__DEFAULT_GROUP__";
type GroupedOptions<T> = Record<string, { heading: React.ReactNode; options: T[]; extraEndOptions: ReactNode[] }>;

export interface RegrelloFormFieldSelectPropsV2<T> extends RegrelloFormFieldBaseProps<T | null> {
  /**
   * Where along the specified {@link side} of the target to align the menu.
   *
   * @default "start"
   */
  align?: RegrelloPopoverProps["align"];

  /**
   * Should field be auto focused on mount.
   */
  autoFocus?: boolean;

  /**
   * Data test ID attached to search input. Handy to get the input state.
   */
  dataTestIdForMenuInput?: string;

  /**
   * Extra options attached to the bottom of the option list, that will be force mounted (not-filterable).
   */
  extraEndOptions?: ReactNode[];

  /**
   * Extra options attached to the bottom of the list for each group, that will be force mounted
   * (not-filterable).
   */
  extraEndOptionsByGroupKey?: Record<string, ReactNode[]>;

  /**
   * Callback to determine if option matches filter condition.
   */
  filterOption?: (option: T) => boolean;

  /**
   * Callback to determine if option is disabled.
   */
  getOptionDisabled?: (option: T) => boolean;

  /**
   * Callback to render option if option is not a plain string.
   */
  getOptionLabel: (option: T) => string;

  /**
   * Callback that should be used to return option value.
   * Should be used when `getOptionLabel` can return the same value for multiple options.
   */
  getOptionValue?: (option: T) => string;

  /**
   * Function returning a tooltip that should appear when hovering over the option.
   */
  getOptionTooltip?: (option: T) => React.ReactNode | undefined;

  /**
   * Callback that should return group name for a given option.
   */
  groupBy?: (option: T) => string;

  /**
   * Ref attached to the search input.
   */
  inputRef?: React.Ref<HTMLInputElement>;

  /**
   * Whether the input show show an "x" button.
   * @default false
   */
  isClearButtonEnabled?: boolean;

  /**
   * Whether to hide the form field error icon and message. Added (potentially temporarily depending
   * on Design System updates) for Conditional Branching to support the new error state for stage
   * start date forms.
   */
  isErrorMessageHidden?: boolean;

  /**
   * Whether to allow typing to filter options. If `true`, an input will be rendered in the menu.
   *
   * @default true
   */
  isFilterable?: boolean;

  /**
   * Whether to disable built-in fuzzy-match filtering.
   *
   * @default true
   */
  isServerFiltered?: boolean;

  /**
   * Should render loading placeholder instead of options list.
   */
  isLoading?: boolean;

  /**
   * Whether server side searching is enabled. In this case, the drop down won't include the input
   * box for frontend filtering.
   */
  isServerSearchEnabled?: boolean;

  /**
   * Whether to open a tooltip showing the full content of value on hover.
   * @default false
   */
  isTooltipEnabled?: boolean | ((option: T | null) => boolean);

  /**
   * Calback triggered on select button blur.
   */
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;

  /**
   * Callback invoked when selected option change.
   */
  onChange: (newValue: T | null, reason: RegrelloSelectChangeReason) => void;

  /**
   * Callback for defining extra actions to be performed before the value is cleared.
   */
  onClearClick?: () => void;

  /**
   * Callback invoked when the menu closes.
   */
  onClose?: () => void;

  /**
   * Callback invoked when the input value changes.
   */
  onInputValueChange?: (value: string) => void;

  /**
   * Callback invoked when the menu opens.
   */
  onOpen?: () => void;

  /**
   * Callback invoked when the menu scrolls. This is useful for using infinite scrolling to reduce
   * data requested.
   */
  onScroll?: ({ currentTarget }: { currentTarget: HTMLElement }) => Promise<void>;

  /**
   * Available options list.
   */
  options: T[];

  /**
   * Placeholder text displayed when nothing is selected.
   */
  placeholder?: string;

  /**
   * Callback invoked to render a heading for a given group name. If not defined or if the callback
   * returns `undefined` or `null`, the group name will be used by default instead.
   */
  renderGroupHeading?: (groupName: string) => React.ReactNode;

  /**
   * Callback that should return rendered option when more complex structure is needed.
   */
  renderOption?: (option: T) => ReactNode;

  /**
   * Callback used to render the selected option. It might be different than rendered element in the popover.
   */
  renderSelectedValue?: (option: T | null) => React.ReactNode;

  /** Ref to pass to the button/trigger element. */
  selectRef?: React.Ref<HTMLButtonElement>;

  /**
   * The size of the input field.
   * @default RegrelloSize.MEDIUM
   */
  size?: RegrelloSize;

  /**
   * Custom props to change the visual styles of the trigger button. Pass `endIcon={null}` to hide
   * the default chevron end icon.
   */
  triggerButtonProps?: Pick<RegrelloButtonProps, "dataTestId" | "intent" | "onClick" | "variant"> & {
    endIcon?: null | undefined;
  };

  /**
   * Currently selected option.
   */
  value: T | null;
}

const RegrelloFormFieldSelectInternal = function RegrelloFormFieldSelectFn<T>({
  align = "start",
  autoFocus,
  className,
  dataTestId,
  disabled,
  error,
  extraEndOptions = [],
  extraEndOptionsByGroupKey,
  filterOption: propsFilterOptions,
  getOptionDisabled,
  getOptionLabel,
  getOptionValue,
  getOptionTooltip,
  groupBy,
  helperText,
  infoTooltipText,
  infoTooltipIconName,
  infoTooltipVariant,
  inputRef,
  isClearButtonEnabled = false,
  isDefaultMarginsOmitted,
  isDeleted,
  isEmphasized,
  isErrorMessageHidden = false,
  isFilterable = true,
  isServerFiltered = false,
  isRequiredAsteriskShown,
  isServerSearchEnabled = false,
  label,
  labelPlacement,
  labelWidth,
  isLoading,
  name,
  onBlur,
  onChange,
  onClearClick,
  onClose,
  onInputValueChange,
  onOpen,
  onScroll,
  options,
  placeholder,
  renderGroupHeading,
  renderOption,
  renderSelectedValue: propsRenderSelectedValue,
  selectRef,
  selfContainedForm,
  size,
  triggerButtonProps,
  value,
  variant = "default",
  warning,
}: RegrelloFormFieldSelectPropsV2<T>) {
  const selectRefInternal = useRef<HTMLButtonElement | null>(null);
  const uuid = useId();

  const [inputValue, setInputValue] = useState("");

  const hasError = error != null;
  const hasWarning = isEmphasized || warning != null;

  const handleCommandValueChange = useCallback(
    (searchQuery: string) => {
      if (!isServerSearchEnabled) {
        setInputValue(searchQuery);
      }
      onInputValueChange?.(searchQuery);
    },
    [isServerSearchEnabled, onInputValueChange],
  );

  const handleClear = useCallback(() => {
    onClearClick?.();
    onChange(null, "clear");
  }, [onChange, onClearClick]);

  const renderSelectedValue = useCallback(() => {
    const renderedValue = propsRenderSelectedValue?.(value);

    if (renderedValue != null) {
      return renderedValue;
    }

    // Fallback to default renderer if propsRenderSelectedValue or `renderedValue` are `null`-ish.
    return value != null ? getOptionLabel(value) : <span className="text-textPlaceholder">{placeholder}</span>;
  }, [getOptionLabel, placeholder, propsRenderSelectedValue, value]);

  const internalRenderOption = useCallback(
    (option: T) => {
      if (renderOption != null) {
        return renderOption(option);
      }

      return getOptionLabel(option);
    },
    [getOptionLabel, renderOption],
  );

  const internalGetOptionValue = useCallback(
    (option: T) => {
      if (getOptionValue != null) {
        return getOptionValue(option);
      }
      return getOptionLabel(option);
    },
    [getOptionLabel, getOptionValue],
  );

  const internalOptions: GroupedOptions<T> = useMemo(() => {
    let filteredOptions = options;

    if (propsFilterOptions != null) {
      filteredOptions = options.filter(propsFilterOptions);
    }

    if (groupBy != null) {
      const def = filteredOptions.reduce((acc: GroupedOptions<T>, option) => {
        const group = groupBy(option) ?? DEFAULT_GROUP;
        if (acc[group] == null) {
          const heading = renderGroupHeading?.(group) ?? group;
          const endOptions = extraEndOptionsByGroupKey != null ? extraEndOptionsByGroupKey[group] : [];
          acc[group] = { heading, options: [], extraEndOptions: endOptions };
        }
        acc[group].options.push(option);
        return acc;
      }, {});

      // Also include any end options for groups that got completely filtered out.
      if (extraEndOptionsByGroupKey != null) {
        Object.keys(extraEndOptionsByGroupKey).forEach((group) => {
          if (def[group] == null) {
            const heading = renderGroupHeading?.(group) ?? group;
            def[group] = {
              heading,
              options: [], // No options, but we need to render the group
              extraEndOptions: extraEndOptionsByGroupKey[group],
            };
          }
        });
      }

      return def;
    }

    return { [DEFAULT_GROUP]: { heading: DEFAULT_GROUP, options: filteredOptions, extraEndOptions: [] } };
  }, [extraEndOptionsByGroupKey, groupBy, options, propsFilterOptions, renderGroupHeading]);

  const isEmpty = useMemo(() => {
    return (
      options.length === 0 &&
      extraEndOptions.length === 0 &&
      Object.values(internalOptions).every((group) => group.options.length === 0 && group.extraEndOptions.length === 0)
    );
  }, [extraEndOptions.length, internalOptions, options.length]);

  const renderGroupOption = useCallback(
    (option: T) => {
      // (dosipiuk): We need to strip the quotes and new lines from value, otherwise it results with invalid query selector
      const optionValue = internalGetOptionValue(option).replaceAll(/(\r\n|\r|\n|")/g, "");
      const isSelected = value != null ? optionValue === internalGetOptionValue(value).replaceAll('"', "") : false;

      return (
        <RegrelloCommandItem
          key={optionValue}
          className={clsx({
            "bg-primary-soft hover:bg-primary-softHovered active:bg-primary-softPressed aria-selected:bg-primary-softHovered":
              isSelected,
          })}
          data-testid={DataTestIds.FORM_FIELD_SELECT_OPTION}
          disabled={getOptionDisabled?.(option) === true ? true : undefined}
          onSelect={() => {
            onChange(option, "select-option");
            selectRefInternal.current?.click();
          }}
          selected={isSelected}
          text={internalRenderOption(option)}
          tooltip={getOptionTooltip?.(option)}
          value={optionValue}
        />
      );
    },
    [getOptionDisabled, getOptionTooltip, internalGetOptionValue, internalRenderOption, onChange, value],
  );

  const inputRefInternal = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    const maybeInputElement = inputRefInternal.current;

    const handleInputRefFocus = () => {
      // (clewis): When the form library auto-focuses the hidden <input> (e.g., when attempting to
      // submit an invalid form), move focus to the select-menu trigger.
      queueMacrotask(() => selectRefInternal.current?.focus());
    };

    maybeInputElement?.addEventListener("focus", handleInputRefFocus);

    return () => {
      maybeInputElement?.removeEventListener("focus", handleInputRefFocus);
    };
  }, []);

  const selectFieldElement = useMemo(() => {
    return (
      <>
        <RegrelloPopover
          align={align}
          content={
            <RegrelloCommandRoot
              data-testid={DataTestIds.FORM_FIELD_SELECT_OPTIONS_ROOT}
              shouldFilter={isServerFiltered ? false : isFilterable}
              style={{
                // (clewis): 10px is the default collisionPadding. We need to subtract it from the maxHeight to prevent
                // the items from overflowing their container.
                maxHeight: "calc(var(--radix-popover-content-available-height) - 10px)",
                // (dosipiuk): this arbitrary value of 300px is taken from previous implementation.
                maxWidth: "max(var(--radix-popover-trigger-width), 300px)",
              }}
            >
              <RegrelloCommandInput
                ref={inputRef}
                data-testid={DataTestIds.FORM_FIELD_SELECT_MENU_INPUT}
                hideFilter={!isFilterable}
                onValueChange={handleCommandValueChange}
                placeholder={t`Search`}
                value={inputValue}
              />

              <RegrelloCommandList className="max-h-77.5" onScroll={onScroll}>
                {isLoading ? (
                  <RegrelloCommandLoading>
                    <div
                      className="flex justify-center items-center gap-2"
                      data-testid={DataTestIds.FORM_FIELD_SELECT_LOADING_OPTION}
                    >
                      <RegrelloSpinner size="small" />
                      <p>{t`Loading...`}</p>
                    </div>
                  </RegrelloCommandLoading>
                ) : (
                  <>
                    {/* Will automatically render iff there are no results. */}
                    {/*
                     * (clewis): We explicitly hide this using isEmpty because RegrelloCommandEmpty seems
                     * not to work correctly with our multi-group approach.
                     */}
                    {isEmpty && <RegrelloCommandEmpty>{t`No results`}</RegrelloCommandEmpty>}

                    {/* Options */}
                    {Object.entries(internalOptions).map(([key, groupOptions]) => {
                      if (key === DEFAULT_GROUP) {
                        return groupOptions.options.map(renderGroupOption);
                      }
                      return (
                        <Fragment key={key}>
                          <RegrelloCommandGroup
                            className="text-textMuted [&_[cmdk-group-heading]]:font-normal"
                            heading={groupOptions.heading}
                          >
                            {groupOptions.options.map(renderGroupOption)}
                          </RegrelloCommandGroup>
                          {groupOptions.extraEndOptions}
                        </Fragment>
                      );
                    })}

                    {/* End options */}
                    {extraEndOptions}
                  </>
                )}
              </RegrelloCommandList>
            </RegrelloCommandRoot>
          }
          // Disabled animations due to whacky interop with material-ui focus traps.
          // Remove this after material-ui dialog replacement with radix equivalent.
          contentProps={{
            className: "p-0 w-auto",
            disableAnimations: true,
            style: { minWidth: "var(--radix-popover-trigger-width)" },
          }}
          onOpenChange={(nextIsOpen) => {
            if (nextIsOpen) {
              onOpen?.();
            } else {
              handleCommandValueChange("");
              onClose?.();
            }
          }}
        >
          <RegrelloButton
            ref={mergeRefs(selectRef, selectRefInternal)}
            autoFocus={autoFocus}
            className={clsx("group", {
              "bg-background": triggerButtonProps == null,
              "shadow-warning-solid": hasWarning,
              "shadow-danger-solid": hasError,
            })}
            contentClassName={clsx("flex flex-1 justify-between items-center gap-1", {
              "overflow-visible text-textDefault font-normal": triggerButtonProps == null,
            })}
            disabled={disabled}
            fullWidth={true}
            id={uuid}
            justification="stretch"
            onBlur={onBlur}
            onKeyDown={(event) => {
              if (event.keyCode >= 48 && event.keyCode <= 90) {
                event.currentTarget.click();
              }
              if (event.key === KeyNames.BACKSPACE || event.key === KeyNames.DELETE) {
                handleClear();
              }
            }}
            size={size}
            variant="outline"
            {...(triggerButtonProps ?? {})}
            endIcon={
              triggerButtonProps?.endIcon === null ? undefined : (
                <RegrelloIcon className="transition group-data-[state=open]:rotate-180" iconName="expand-more" />
              )
            }
          >
            <div className="truncate">{renderSelectedValue()}</div>
            {isClearButtonEnabled ? (
              <RegrelloButton
                className={clsx("w-5 h-5", { invisible: value == null })}
                iconOnly={true}
                onClick={(event) => {
                  event.preventDefault();
                  handleClear();
                  selectRefInternal.current?.focus();
                }}
                size="x-small"
                startIcon="close"
                variant="ghost"
              />
            ) : null}
          </RegrelloButton>
        </RegrelloPopover>
      </>
    );
  }, [
    align,
    isServerFiltered,
    isFilterable,
    inputRef,
    handleCommandValueChange,
    inputValue,
    onScroll,
    isLoading,
    isEmpty,
    internalOptions,
    extraEndOptions,
    selectRef,
    autoFocus,
    triggerButtonProps,
    hasWarning,
    hasError,
    disabled,
    uuid,
    onBlur,
    size,
    renderSelectedValue,
    isClearButtonEnabled,
    value,
    renderGroupOption,
    onOpen,
    onClose,
    handleClear,
  ]);

  const memoizedRenderSelectFieldElement = useCallback(() => {
    return selectFieldElement;
  }, [selectFieldElement]);

  if (variant === "spectrum" && selfContainedForm == null) {
    return (
      <RegrelloField
        dataTestId={dataTestId}
        deleted={isDeleted}
        description={infoTooltipText}
        errorMessage={error}
        helperText={typeof helperText === "string" ? helperText : undefined}
        label={typeof label === "string" ? label : EMPTY_STRING}
        name={name ?? EMPTY_STRING}
        required={isRequiredAsteriskShown}
      >
        {memoizedRenderSelectFieldElement}
      </RegrelloField>
    );
  }

  return (
    <RegrelloFormFieldLayout
      className={className}
      dataTestId={dataTestId}
      error={isErrorMessageHidden ? undefined : error}
      helperText={helperText}
      htmlFor={uuid}
      infoTooltipIconName={infoTooltipIconName}
      infoTooltipText={infoTooltipText}
      infoTooltipVariant={infoTooltipVariant}
      isDefaultMarginsOmitted={isDefaultMarginsOmitted}
      isDeleted={isDeleted}
      isRequiredAsteriskShown={isRequiredAsteriskShown}
      label={label}
      labelPlacement={labelPlacement}
      labelWidth={labelWidth}
      selfContainedForm={getSelfContainedFormInternal(selfContainedForm, (savedValue) =>
        savedValue === null ? <RegrelloFormFieldEmptyValueNone /> : getOptionLabel(savedValue),
      )}
      variant={variant}
      warning={warning}
    >
      {selectFieldElement}
    </RegrelloFormFieldLayout>
  );
};

/**
 * A form field that allows selection of a single value from a menu, with optional support for
 * filtering within the menu.
 */
export const RegrelloFormFieldSelectV2 = React.memo(
  RegrelloFormFieldSelectInternal,
) as typeof RegrelloFormFieldSelectInternal;
