import { EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloField, RegrelloListPhrase, RegrelloMultiSelect, RegrelloMultiSelectProps } from "@regrello/ui-core";
import React, { useCallback, useId, useMemo } from "react";

import { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldEmptyValueNone } from "./_internal/RegrelloFormFieldEmptyValueNone";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";
import { getSelfContainedFormInternal } from "./_internal/selfContainedForm/getSelfContainedFormInternal";

export interface RegrelloFormFieldMultiSelectProps<T>
  extends RegrelloFormFieldBaseProps<T[]>,
    Omit<RegrelloMultiSelectProps<T>, "fullWidth" | "onClear" | "onItemDeselect" | "onItemSelect" | "selectedItems"> {
  /**
   * Callback that returns a string. Must provide if `renderDisplayValueForSelfContainedForm` is
   * not provided.
   */
  getItemLabelForSelfContainedForm?: (item: T) => string;

  /** Callback invoked when the input loses focus. */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;

  /** Callback that will be invoked when the selected items change. */
  onChange: (newSelectedItems: T[]) => void;

  /**
   * Callback to customize how the provided selected items will be rendered in a
   * `selfContainedForm` when not being edited.
   */
  renderDisplayValueForSelfContainedForm?: (selectedItems: T[]) => React.ReactNode;

  /** The currently selected items. */
  value: T[];
}

function isItemsShallowEqual<T>(itemA: T, itemB: T) {
  return itemA === itemB;
}

const RegrelloFormFieldMultiSelectInternal = function RegrelloFormFieldMultiSelectFn<T>({
  autoFocus,
  closeOnSelect,
  createItemOptions,
  disabled,
  disableSearchOnFocus,
  getItemDisabled,
  getItemGroup,
  getItemLabelForSelfContainedForm,
  getSelectedItemProps,
  groupSortComparator,
  infoTooltipText,
  infoTooltipIconName,
  infoTooltipVariant,
  inputRef,
  isDeleted,
  isEmphasized,
  isItemsEqual = isItemsShallowEqual,
  itemPredicate,
  items,
  loading,
  name,
  onBlur,
  onChange,
  onOpenChange,
  onScroll,
  onSearch,
  placeholder,
  popoverCustomBottomContent,
  popoverCustomTopContent,
  renderDisplayValueForSelfContainedForm,
  renderItem,
  value: selectedItems = EMPTY_ARRAY,
  selfContainedForm,
  size = "large",
  startIcon,
  ...layoutProps
}: RegrelloFormFieldMultiSelectProps<T>) {
  const id = useId();
  const hasError = layoutProps.error != null;

  const isSelectionEmpty = selectedItems == null || selectedItems.length === 0;

  const handleClear = useCallback(() => {
    // Clear all items except those that are disabled.
    onChange(
      selectedItems.filter((selectedItem, index) => getSelectedItemProps?.(selectedItem, index).disabled ?? false),
    );
  }, [getSelectedItemProps, onChange, selectedItems]);

  const getSelectedItemPropsInternal = useCallback(
    (item: T, index: number) => {
      return {
        // Include a default dataTestId that E2E tests will look for.
        dataTestId: DataTestIds.FORM_FIELD_MULTISELECT_DISPLAY_TAG,
        ...getSelectedItemProps(item, index),
      };
    },
    [getSelectedItemProps],
  );

  const renderItemInternal = useCallback(
    (item: T) => {
      return {
        // Include a default dataTestId that E2E tests will look for.
        dataTestId: DataTestIds.FORM_FIELD_SELECT_OPTION,
        ...renderItem(item),
      };
    },
    [renderItem],
  );

  const multiSelectElement = useMemo(() => {
    return (
      <RegrelloMultiSelect
        autoFocus={autoFocus || selfContainedForm != null}
        closeOnSelect={closeOnSelect}
        createItemOptions={createItemOptions}
        disabled={disabled}
        disableSearchOnFocus={disableSearchOnFocus}
        fullWidth={true}
        getItemDisabled={getItemDisabled}
        getItemGroup={getItemGroup}
        getSelectedItemProps={getSelectedItemPropsInternal}
        groupSortComparator={groupSortComparator}
        id={id}
        inputRef={inputRef}
        intent={hasError ? "danger" : isEmphasized ? "warning" : undefined}
        isItemsEqual={isItemsEqual}
        itemPredicate={itemPredicate}
        items={items}
        loading={loading}
        menuDataTestId={DataTestIds.FORM_FIELD_MULTISELECT_DROP_DOWN}
        name={name}
        onBlur={onBlur}
        onClear={handleClear}
        onItemDeselect={(item, _index): void => {
          onChange(selectedItems.filter((i) => !isItemsEqual(i, item)));
        }}
        onItemSelect={(item, _index): void => {
          onChange([...selectedItems, item]);
        }}
        onOpenChange={onOpenChange}
        onScroll={onScroll}
        onSearch={onSearch}
        placeholder={placeholder}
        popoverCustomBottomContent={popoverCustomBottomContent}
        popoverCustomTopContent={popoverCustomTopContent}
        renderItem={renderItemInternal}
        selectedItems={selectedItems}
        size={size}
        startIcon={startIcon}
      />
    );
  }, [
    autoFocus,
    closeOnSelect,
    createItemOptions,
    disableSearchOnFocus,
    disabled,
    getItemDisabled,
    getItemGroup,
    getSelectedItemPropsInternal,
    groupSortComparator,
    handleClear,
    hasError,
    id,
    inputRef,
    isEmphasized,
    isItemsEqual,
    itemPredicate,
    items,
    loading,
    name,
    onBlur,
    onChange,
    onOpenChange,
    onScroll,
    onSearch,
    placeholder,
    popoverCustomBottomContent,
    popoverCustomTopContent,
    renderItemInternal,
    selectedItems,
    selfContainedForm,
    size,
    startIcon,
  ]);

  if (layoutProps.variant === "spectrum" && selfContainedForm == null) {
    return (
      <RegrelloField
        dataTestId={layoutProps.dataTestId}
        deleted={isDeleted}
        description={infoTooltipText}
        errorMessage={layoutProps.error}
        helperText={typeof layoutProps.helperText === "string" ? layoutProps.helperText : undefined}
        htmlFor={id}
        label={typeof layoutProps.label === "string" ? layoutProps.label : EMPTY_STRING}
        name={name ?? EMPTY_STRING}
        required={layoutProps.isRequiredAsteriskShown}
      >
        {() => {
          return multiSelectElement;
        }}
      </RegrelloField>
    );
  }

  return (
    <RegrelloFormFieldLayout
      {...layoutProps}
      htmlFor={id}
      infoTooltipIconName={infoTooltipIconName}
      infoTooltipText={infoTooltipText}
      infoTooltipVariant={infoTooltipVariant}
      isDeleted={isDeleted}
      selfContainedForm={getSelfContainedFormInternal(selfContainedForm, (savedValue) =>
        isSelectionEmpty ? (
          <RegrelloFormFieldEmptyValueNone />
        ) : (
          (renderDisplayValueForSelfContainedForm?.(savedValue) ?? (
            <RegrelloListPhrase
              items={selectedItems}
              renderItem={getItemLabelForSelfContainedForm ?? ((item: T) => String(item))}
            />
          ))
        ),
      )}
    >
      {multiSelectElement}
    </RegrelloFormFieldLayout>
  );
};

export const RegrelloFormFieldMultiSelect = React.memo(
  RegrelloFormFieldMultiSelectInternal,
) as typeof RegrelloFormFieldMultiSelectInternal;
