import { t } from "@lingui/macro";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FieldInstanceFields } from "@regrello/graphql-api";
import { RegrelloDialogV2, RegrelloTypography } from "@regrello/ui-core";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

import { RegrelloCustomFieldPluginIcon } from "../../../../../molecules/customFields/components/RegrelloCustomFieldPluginIcon";
import { CustomFieldPluginRegistrar } from "../../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import {
  RegrelloControlledFormFieldCheckbox,
  RegrelloControlledFormFieldSwitch,
} from "../../../../../molecules/formFields/controlled/regrelloControlledFormFields";

interface RegrelloObjectProjectionSettingsProps {
  fieldInstance: FieldInstanceFields;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (isMultiValues: boolean, projection: number[]) => void;
  showMultiValuedSelector?: boolean;
}

function getDefaultProjection(fieldInstance?: FieldInstanceFields) {
  if (fieldInstance == null) {
    return {};
  }

  const propertyIds = fieldInstance.field.regrelloObject?.properties.map((property) => property.id) || [];
  const projectionPropertyIds = fieldInstance?.projection?.selectedRegrelloObjectPropertyIds || [];

  const projectionIds = projectionPropertyIds.length > 0 ? projectionPropertyIds : propertyIds;
  return projectionIds.reduce((acc: Record<string, boolean>, propertyId) => {
    acc[propertyId] = true;
    return acc;
  }, {});
}

export function RegrelloObjectProjectionSettings({
  fieldInstance,
  isOpen,
  onClose,
  onSubmit,
  showMultiValuedSelector = false,
}: RegrelloObjectProjectionSettingsProps) {
  const form = useForm({
    defaultValues: {
      isMultiValued: fieldInstance.isMultiValued || false,
      projection: getDefaultProjection(fieldInstance),
    },
  });

  const properties = fieldInstance.field?.regrelloObject?.properties || [];

  const customFieldPlugin = CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance);
  const primaryFields = properties.filter((property) => property.isPrimaryKey);
  const secondaryFields = properties.filter((property) => !property.isPrimaryKey);

  const onCloseWithReset = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const onSubmitInternal = useCallback(() => {
    const values = form.getValues();
    const projection = Object.entries(values.projection)
      .map(([id, enabled]) => {
        return enabled ? Number(id) : undefined;
      })
      .filter(Boolean) as number[];
    onSubmit(values.isMultiValued, projection);
    onClose();
  }, [form, onClose, onSubmit]);

  return (
    <RegrelloDialogV2
      actions={[
        { text: t`Cancel`, buttonProps: { onClick: onCloseWithReset, variant: "outline" } },
        {
          text: t`Apply`,
          buttonProps: {
            intent: "primary",
            variant: "solid",
            onClick: onSubmitInternal,
          },
        },
      ]}
      contentClassName="max-w-100"
      disableDefaultPadding={true}
      onClose={onCloseWithReset}
      open={isOpen}
      title={t`Select fields`}
    >
      <div>
        {showMultiValuedSelector ? (
          <RegrelloControlledFormFieldSwitch
            className="pb-2 px-4 bg-backgroundSoft border-b"
            controllerProps={{
              name: "isMultiValued",
              control: form.control,
            }}
            dataTestId={DataTestIds.SYNCED_OBJECTS_MULTI_SELECT_SWITCH}
            secondaryLabel="Allow multiple selections"
          />
        ) : null}
        <div className="p-4">
          <h4>Primary Fields</h4>
          {primaryFields?.map((field) => (
            <RegrelloControlledFormFieldCheckbox
              key={field.id}
              controllerProps={{
                name: `projection.${field.id}`,
                control: form.control,
              }}
              dataTestId={DataTestIds.SYNCED_OBJECTS_COLUMN_VISIBILITY_SWITCH}
              disabled={true}
              label={
                <div className="flex items-center gap-1 w-fit p-1 bg-backgroundSoft">
                  <RegrelloCustomFieldPluginIcon plugin={customFieldPlugin} /> {field.displayName}
                </div>
              }
            />
          ))}
          <h4>Other Fields</h4>
          <RegrelloTypography className="mb-2" muted={true} variant="body-xs">
            {t`Unselected fields can still be used in the blueprint's naming convention.`}
          </RegrelloTypography>
          {secondaryFields?.map((field) => (
            <RegrelloControlledFormFieldCheckbox
              key={field.id}
              controllerProps={{
                name: `projection.${field.id}`,
                control: form.control,
              }}
              dataTestId={DataTestIds.SYNCED_OBJECTS_COLUMN_VISIBILITY_SWITCH}
              label={
                <div className="flex items-center gap-1 w-fit p-1 bg-backgroundSoft">
                  <RegrelloCustomFieldPluginIcon plugin={customFieldPlugin} /> {field.displayName}
                </div>
              }
            />
          ))}
        </div>
      </div>
    </RegrelloDialogV2>
  );
}
