import { t } from "@lingui/macro";
import { Optional } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  AdminRolesQueryDocument,
  RoleFields,
  RolesQueryDocument,
  SpectrumFieldVersionFields,
  useCreateRoleMutation,
} from "@regrello/graphql-api";
import React, { useCallback, useState } from "react";

import { RegrelloRoleForm } from "./RegrelloRoleForm";
import { SubmitHandler } from "../../../../../types/form";
import { getGraphQlErrorCode, RegrelloGraphQlErrorCode } from "../../../../../utils/errorUtils";
import { useErrorHandler } from "../../../../../utils/hooks/useErrorHandler";
import { RegrelloFormDialogName } from "../../../../../utils/sentryScopeUtils";
import { useUser } from "../../../../app/authentication/userContextUtils";
import { RegrelloFormDialog, RegrelloFormDialogProps } from "../../../../atoms/dialog/RegrelloFormDialog";
import { useToastMessageQueue } from "../../../../molecules/toast/useToastMessageQueue";

const Keys = RegrelloRoleForm.Keys;

export type RegrelloAddRoleDialogProps = {
  /** Callback invoked when a role has been created on the backend. */
  onRoleAdded?: (
    role: {
      spectrumFieldVersion?: SpectrumFieldVersionFields | null;
    } & RoleFields,
  ) => void;
} & Optional<
  Omit<
    RegrelloFormDialogProps<RegrelloRoleForm.Fields>,
    | "dataTestId"
    | "error"
    | "children"
    | "onSubmit"
    | "scopeNameForSentry"
    | "showRequiredInstruction"
    | "submitButtonText"
    | "title"
  >,
  "defaultValues"
>;

/**
 * Dialog to create a new role.
 */
export const RegrelloAddRoleDialog = React.memo<RegrelloAddRoleDialogProps>(function RegrelloAddRoleDialogFn(props) {
  const { handleError } = useErrorHandler();
  const { currentUser } = useUser();
  const { showToast } = useToastMessageQueue();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [createRoleAsync] = useCreateRoleMutation({
    refetchQueries: [RolesQueryDocument],
    onError: (error) => {
      const code = getGraphQlErrorCode(error);

      if (code === RegrelloGraphQlErrorCode.OBJECT_NAME_MUST_NOT_BE_EMPTY) {
        setErrorMessage(t`Name cannot be empty`);
      } else {
        // (clewis): Clear any errors from before.
        setErrorMessage(undefined);
        handleError(error, {
          toastMessage: t`Failed to create role. Please try again, or contact a Regrello admin if you continue to see this error.`,
        });
      }
    },
  });

  const handleSubmitInternal: SubmitHandler<RegrelloRoleForm.Fields> = useCallback(
    async (data) => {
      const result = await createRoleAsync({
        variables: {
          input: {
            name: data[Keys.NAME],
            canInviteUsersToWorkspace: data[Keys.ALLOW_INVITING_PEOPLE_TO_THE_WORKSPACE],
          },
        },
        refetchQueries: [AdminRolesQueryDocument, RolesQueryDocument],
      });

      if (result.errors != null) {
        return false;
      }

      if (result.data?.createRole == null) {
        console.warn("Created a role successfully, but no data was returned in the response.");
        return false;
      }

      // TODO (anthony): i18n
      showToast({
        content: "Successfully created role",
        intent: "success",
      });

      // (clewis): Close after adding so the previous view is already settled when we return to it.
      props.onRoleAdded?.(result.data.createRole.role);
      props.onClose();
      return true;
    },
    [createRoleAsync, showToast, props],
  );

  return props.isOpen ? (
    <RegrelloFormDialog<RegrelloRoleForm.Fields>
      {...props}
      dataTestId={DataTestIds.ADD_ROLE_DIALOG}
      defaultValues={props.defaultValues ?? {}}
      error={errorMessage}
      isOpen={true}
      onSubmit={handleSubmitInternal}
      scopeNameForSentry={RegrelloFormDialogName.ADD_ROLE}
      showRequiredInstruction={true}
      submitButtonText={t`Add`}
      title={t`Create role`}
    >
      {(form) => (
        <RegrelloRoleForm.Component
          form={form}
          isAllowInvitingPeopleToTheWorkspaceFieldDisabled={!currentUser.permissions.canCreateRolesWithInvite}
        />
      )}
    </RegrelloFormDialog>
  ) : (
    <></>
  );
});
