import { t } from "@lingui/macro";
import { clsx } from "@regrello/core-utils";
import {
  RegrelloControlChildContent,
  RegrelloControlWithLabel,
  RegrelloIcon,
  RegrelloRadioGroup,
  RegrelloRadioGroupItem,
  RegrelloSize,
  RegrelloTooltipV4,
  RegrelloTypography,
} from "@regrello/ui-core";
import { isSameDay, startOfDay } from "date-fns";
import React, { useCallback, useEffect, useMemo } from "react";
import { SetValueConfig, UseFormReturn, useWatch } from "react-hook-form";

import { UserFormFields } from "./RegrelloUserForm";
import { ValidationRules } from "../../../../../constants/globalConstants";
import { RegrelloFormValidationReason } from "../../../../../utils/formUtils";
import { PartyTypeUnion } from "../../../../../utils/parties/PartyTypeUnion";
import { useUser } from "../../../../app/authentication/userContextUtils";
import { RegrelloFormFieldLabelPlacement } from "../../../../molecules/formFields/_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldLayout } from "../../../../molecules/formFields/_internal/RegrelloFormFieldLayout";
import {
  RegrelloControlledFormFieldCheckbox,
  RegrelloControlledFormFieldDate,
  RegrelloControlledFormFieldPartySelect,
} from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";

export interface OutOfOfficeEventFormFields {
  startAt: Date | null;
  endAt: Date | null;
  delegationSettings: OutOfOfficeEventDelegationFormFields | undefined;
}

export interface OutOfOfficeEventDelegationFormFields {
  nonApprovalTaskDelegatesParties: PartyTypeUnion[];
  approvalTaskDelegatesParties: PartyTypeUnion[];
  shouldCcOnDelegatedTasks: boolean;
}

export interface RegrelloOutOfOfficeEventFormProps {
  form: UseFormReturn<UserFormFields>;
  setTriedToSubmitWithoutDelegates: React.Dispatch<React.SetStateAction<boolean>>;
  triedToSubmitWithoutDelegates: boolean;
}

const DelegationSettingValues = {
  KEEP_TASKS_ASSIGNED_TO_ME: "keep-tasks-assigned-to-me",
  DELEGATE_ASSIGNED_TASKS: "delegate-assigned-tasks",
};

/**
 * Form for setting user out of office settings
 */
export const RegrelloOutOfOfficeEventForm = React.memo(function RegrelloOutOfOfficeEventFormFn({
  form,
  setTriedToSubmitWithoutDelegates,
  triedToSubmitWithoutDelegates,
}: RegrelloOutOfOfficeEventFormProps) {
  const { currentUser } = useUser();

  const delegationSettings = useWatch({ control: form.control, name: "outOfOffice.delegationSettings" });
  const startAt = useWatch({ control: form.control, name: "outOfOffice.startAt" });
  const endAt = useWatch({ control: form.control, name: "outOfOffice.endAt" });

  const isStartAtBeforeEndAt = dateBeforeOrEqual(startAt, endAt);

  useEffect(() => {
    if (isStartAtBeforeEndAt) {
      // Triggers validation error when dates are wrong
    }

    void form.trigger("outOfOffice.endAt");
  }, [isStartAtBeforeEndAt, form]);

  const areDelegateFieldsInInvalidState = useMemo(() => {
    return (
      delegationSettings != null &&
      delegationSettings.approvalTaskDelegatesParties.length === 0 &&
      delegationSettings.nonApprovalTaskDelegatesParties.length === 0
    );
  }, [delegationSettings]);

  const shouldShowDelegateFieldError = areDelegateFieldsInInvalidState && triedToSubmitWithoutDelegates;

  // (akager): Avoid errors of the form 'Type instantiation is excessively deep and possibly
  // infinite' by explicitly stating the keys instead of reling on the default Path type.
  // hard coded key since creating type for nested key is non trivial, and potentially cpu intensive
  const formSetDelegationValue = form.setValue as (
    name: "outOfOffice.delegationSettings",
    value: OutOfOfficeEventDelegationFormFields | undefined,
    options?: SetValueConfig,
  ) => void;

  const getIsDateDisabled = useCallback(
    (date?: Date | null) => {
      if (date == null) {
        return false;
      }
      if (startAt != null && isSameDay(date, startAt)) {
        return false;
      }
      if (date < startOfDay(new Date())) {
        return true;
      }
      return false;
    },
    [startAt],
  );

  const currentUserPartyIdSet = useMemo(() => new Set([currentUser.partyId]), [currentUser.partyId]);

  return (
    <div className={clsx("bg-backgroundSoft px-4 pb-4 pt-1")}>
      <header className="flex items-center gap-1 mt-4 mb-2">
        <RegrelloTypography variant="h6">{t`When I'll be out`}</RegrelloTypography>
        <RegrelloTooltipV4
          align="start"
          content={
            <div className="flex flex-col gap-3">
              <span>{t`When you'll be out of office`}</span>
              <ul className="ps-6 list-disc">
                <li>{t`Setting yourself to ‘Away’ without picking dates immediately turns on OOO. You must turn it off manually.`}</li>
                <li>{t`Setting a start date only will turn it on on that day. You must turn it off manually.`}</li>
                <li>{t`Setting start and end dates will show you as OOO only for those days and turn off automatically.`}</li>
                <li>{t`Setting an end date only will turn on OOO immediately and turn it off automatically.`}</li>
              </ul>
            </div>
          }
          side="top"
          variant="popover"
        >
          <div>
            <RegrelloIcon iconName="help-outline" intent="neutral" size={RegrelloSize.X_SMALL} />
          </div>
        </RegrelloTooltipV4>
      </header>

      <div className="flex">
        <RegrelloControlledFormFieldDate
          className="grow mr-6"
          controllerProps={{
            control: form.control,
            name: "outOfOffice.startAt",
            rules: {
              ...ValidationRules.NOT_REQUIRED,
            },
          }}
          getIsDateDisabled={getIsDateDisabled}
          label={createWeightedLabel(t`Start date`)}
          labelPlacement={RegrelloFormFieldLabelPlacement.TOP}
        />
        <RegrelloControlledFormFieldDate
          className="grow"
          controllerProps={{
            control: form.control,
            name: "outOfOffice.endAt",
            rules: {
              ...ValidationRules.NOT_REQUIRED,
              validate: {
                [RegrelloFormValidationReason.PATTERN]: (endAtValue) =>
                  dateBeforeOrEqual(startAt, endAtValue) ? undefined : t`End date is before start date`,
              },
            },
          }}
          label={createWeightedLabel(t`End date`)}
          labelPlacement={RegrelloFormFieldLabelPlacement.TOP}
          minDate={new Date()}
        />
      </div>

      <header className="flex items-center gap-1 mt-4 mb-2">
        <RegrelloTypography variant="h6">{t`While I'm away`}</RegrelloTypography>
        <RegrelloTooltipV4
          align="start"
          content={
            <div className="flex flex-col gap-3">
              <span>{t`Choose to keep your assigned tasks or delegate them to people and/or teams while you’re away.`}</span>
            </div>
          }
          side="top"
          variant="popover"
        >
          <div>
            <RegrelloIcon iconName="help-outline" intent="neutral" size={RegrelloSize.X_SMALL} />
          </div>
        </RegrelloTooltipV4>
      </header>
      <RegrelloFormFieldLayout>
        <RegrelloRadioGroup
          onChange={(nextValue) => {
            if (nextValue === DelegationSettingValues.KEEP_TASKS_ASSIGNED_TO_ME) {
              setTriedToSubmitWithoutDelegates(false);
              formSetDelegationValue("outOfOffice.delegationSettings", undefined);
            } else if (nextValue === DelegationSettingValues.DELEGATE_ASSIGNED_TASKS) {
              formSetDelegationValue("outOfOffice.delegationSettings", {
                nonApprovalTaskDelegatesParties: [],
                approvalTaskDelegatesParties: [],
                shouldCcOnDelegatedTasks: false,
              });
            }
          }}
          value={
            delegationSettings != null
              ? DelegationSettingValues.DELEGATE_ASSIGNED_TASKS
              : DelegationSettingValues.KEEP_TASKS_ASSIGNED_TO_ME
          }
        >
          <RegrelloControlWithLabel
            control={<RegrelloRadioGroupItem value={DelegationSettingValues.KEEP_TASKS_ASSIGNED_TO_ME} />}
            label={t`Keep tasks assigned to me`}
          />
          <RegrelloControlWithLabel
            control={<RegrelloRadioGroupItem value={DelegationSettingValues.DELEGATE_ASSIGNED_TASKS} />}
            label={t`Delegate assigned tasks`}
          />
        </RegrelloRadioGroup>
      </RegrelloFormFieldLayout>
      {delegationSettings != null && (
        <div>
          <RegrelloControlChildContent>
            <RegrelloControlledFormFieldPartySelect
              controllerProps={{
                control: form.control,
                name: "outOfOffice.delegationSettings.nonApprovalTaskDelegatesParties",
              }}
              hasErrorOverride={shouldShowDelegateFieldError}
              hiddenTabs={{ teams: false, users: false }}
              label={t`Tasks`}
              labelPlacement={RegrelloFormFieldLabelPlacement.TOP}
              partyIdsToExclude={currentUserPartyIdSet}
              placeholder={t`Choose people and/or teams`}
            />
            <RegrelloControlledFormFieldPartySelect
              controllerProps={{
                control: form.control,
                name: "outOfOffice.delegationSettings.approvalTaskDelegatesParties",
              }}
              hasErrorOverride={shouldShowDelegateFieldError}
              hiddenTabs={{ teams: false, users: false }}
              label={t`Approvals`}
              labelPlacement={RegrelloFormFieldLabelPlacement.TOP}
              partyIdsToExclude={currentUserPartyIdSet}
              placeholder={t`Choose people and/or teams`}
            />
            {shouldShowDelegateFieldError && (
              <div className="flex items-center gap-1 mt-4 text-danger-textMuted">
                <RegrelloIcon iconName="alert" size="x-small" />
                <RegrelloTypography intent="danger" variant="body-xs">
                  {t`You must assign at least one delegate for Tasks or Approvals`}
                </RegrelloTypography>
              </div>
            )}
          </RegrelloControlChildContent>
          <header className="flex items-center gap-1 mt-4 mb-2">
            <RegrelloTypography variant="h6">{t`Task updates`}</RegrelloTypography>
            <RegrelloTooltipV4
              align="start"
              content={
                <div className="flex flex-col gap-3">
                  <span>{t`Choose to remain Cc’d on your delegated tasks while you’re away.`}</span>
                </div>
              }
              side="top"
              variant="popover"
            >
              <div>
                <RegrelloIcon iconName="help-outline" intent="neutral" size={RegrelloSize.X_SMALL} />
              </div>
            </RegrelloTooltipV4>
          </header>
          <RegrelloControlledFormFieldCheckbox
            controllerProps={{
              control: form.control,
              name: "outOfOffice.delegationSettings.shouldCcOnDelegatedTasks",
            }}
            label={t`Keep me Cc'd on delegated tasks`}
          />
        </div>
      )}
    </div>
  );
});

const dateBeforeOrEqual = (date1: Date | null, date2: Date | null) => {
  return date1 == null || date2 == null || isSameDay(date1, date2) || date1 <= date2;
};

const createWeightedLabel = (text: string) => <RegrelloTypography weight="normal">{text}</RegrelloTypography>;
