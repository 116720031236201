import { EMPTY_ARRAY, EMPTY_STRING, sortIgnoreCaseWithExactMatchFirst } from "@regrello/core-utils";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  FieldFields,
  FieldType,
  PropertyDataType,
  RoleFields,
  SpectrumFieldVersionFields,
  useFieldsQueryLazyQuery,
} from "@regrello/graphql-api";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldSelectOption } from "./_internal/selectOptions/RegrelloFormFieldSelectOption";
import { RegrelloFormFieldSelectOptionRegrelloObject } from "./_internal/selectOptions/RegrelloFormFieldSelectOptionRegrelloObject";
import { RegrelloSelectV2AddOption } from "./_internal/selectOptions/RegrelloSelectV2AddOption";
import { RegrelloSelectV2ErrorOption } from "./_internal/selectOptions/RegrelloSelectV2ErrorOption";
import {
  RegrelloFormFieldSelectPropsV2,
  RegrelloFormFieldSelectV2,
  RegrelloSelectChangeReason,
} from "./RegrelloFormFieldSelectV2";
import { WORKFLOW_OWNER_FIELD_NAME } from "../../../constants/globalConstants";
import { FeatureFlagService } from "../../../services/FeatureFlagService";
import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { RegrelloAddRoleDialog } from "../../views/modals/formDialogs/roles/RegrelloAddRoleDialog";
import { ConfigureSpectrumFieldDialog } from "../../views/modals/formDialogs/spectrumFields/ConfigureSpectrumFieldDialog";
import { RegrelloCustomFieldPluginIcon } from "../customFields/components/RegrelloCustomFieldPluginIcon";
import { CustomFieldPluginRegistrar } from "../customFields/plugins/registry/customFieldPluginRegistrar";
import { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { SpectrumFieldPluginDecorator } from "../spectrumFields/types/SpectrumFieldPluginDecorator";

import { AddFieldOptionToRegrello, AddRole, AddRoleOptionToRegrello, Fields, Roles } from "@/strings";

export interface RegrelloFormFieldCustomFieldSelectProps
  extends RegrelloFormFieldBaseProps<FieldFields | null>,
    Pick<
      RegrelloFormFieldSelectPropsV2<FieldFields>,
      "inputRef" | "isClearButtonEnabled" | "onChange" | "onClearClick" | "onClose" | "placeholder" | "size" | "value"
    > {
  /**
   * Whether to allow creating new tags and tag types.
   * @default false
   */
  allowCreateFields?: boolean;

  /**
   * Whether to allow creating new roles.
   * @default false
   */
  allowCreateRoles?: boolean;

  /**
   * If defined, the multiplicity switch, if the field type supports it, will be disabled with this
   * text rendered below.
   *
   * TODO (anthony): Consolidate this into a unified set of props, driven by field plugin.
   */
  allowMultipleSwitchDisabledHelperText?: string;

  /**
   * Whether to allow selecting the workflow owner system field
   * @default false
   */
  allowSelectWorkflowOwner?: boolean;

  /**
   * An allow list for filtering which field plugins this field should allow the user to select.
   */
  allowedFieldPlugins?: Array<CustomFieldPlugin<unknown>>;

  allowedSpectrumFieldPlugins?: Array<SpectrumFieldPluginDecorator<unknown>>;

  /**
   * Options for the allowed values when creating new fields that support them. Useful for passing
   * things like the restricted allowed values when the caller needs to ensure a complete match to
   * the newly created field, or populate defaults from the caller.
   */
  createAllowedValuesOptions?: {
    /** An array of allowed values to populate into the form by default. */
    defaultAllowedValues?: Array<{ value: string }>;

    /** Whether to disable the modifying of the allowed values form controls. */
    disableAllowedValuesEditing?: boolean;
  };

  /** Initial value for the multiplicity switch, if the field type supports the option. */
  defaultAllowMultiple?: boolean;

  /**
   * A list of options that should not be displayed in the suggestions menu. Useful for preventing
   * already-selected fields from being selected again, for example.
   */
  omittedOptions?: FieldFields[];

  selectRef?: React.Ref<HTMLButtonElement>;
}

/** Renders a single-select input in which the user can select from all Fields in the system. */
export const RegrelloFormFieldCustomFieldSelect = React.memo<RegrelloFormFieldCustomFieldSelectProps>(
  function RegrelloFormFieldCustomFieldSelectFn({
    allowCreateFields,
    allowCreateRoles,
    allowedFieldPlugins,
    allowedSpectrumFieldPlugins,
    allowSelectWorkflowOwner,
    className,
    omittedOptions,
    onChange, // (clewis): Pull this out because we need to override it.
    onClose,
    selectRef,
    size,
    ...multiselectProps
  }) {
    const isPermissionsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01);

    // (clewis): As a UX nicety, we want to pre-populate the typed value in the add dialog. This is
    // tricky because the inputValue is cleared as soon as we select an option, so we have to track
    // the dialog's defaultValue separately.
    const [defaultValueForCreateDialog, setDefaultValueForCreateDialog] = useState<string>("");
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
    const [isCreateRoleDialogOpen, setIsCreateRoleDialogOpen] = useState<boolean>(false);
    const [loadedOptionsV2, setLoadedOptions] = useState<FieldFields[]>(EMPTY_ARRAY);

    const { handleError } = useErrorHandler();

    const [getFieldsAsync, fieldsQueryResult] = useFieldsQueryLazyQuery({
      onError: (error) => handleError(error),
      variables: {
        fieldTypes: [FieldType.DEFAULT, FieldType.SYSTEM],
      },
      fetchPolicy: "no-cache",
    });

    // (hchen): Build a set for faster lookup, given that we'll need to filter the options
    // every time the suggestions menu opens.
    const omittedOptionIds = useMemo(
      () => new Set((omittedOptions ?? EMPTY_ARRAY).map((option) => option.id)),
      [omittedOptions],
    );

    // Update and sort the locally stored fields when the query finishes loading.
    useEffect(() => {
      if (fieldsQueryResult.loading) {
        return;
      }

      if (fieldsQueryResult.error != null || fieldsQueryResult.data?.fields == null) {
        setLoadedOptions([]);
        return;
      }

      // (clewis): Need to spread before sorting, because the loaded array is readonly. Also need to
      // include the "Add" option in order for it to emit successfully via onChange.
      setLoadedOptions(sortOptions(fieldsQueryResult.data.fields));
    }, [fieldsQueryResult]);

    const filterOption = useCallback(
      (field: FieldFields) => {
        if (
          allowedFieldPlugins != null &&
          allowedFieldPlugins.length > 0 &&
          !allowedFieldPlugins.some((plugin) => plugin.canProcessField(field))
        ) {
          return false;
        }

        if (field.fieldType === FieldType.SYSTEM) {
          if (
            !allowSelectWorkflowOwner ||
            // (akager) Hack: We rely on the field name to determine if it's the workflow owner field.
            field.name !== WORKFLOW_OWNER_FIELD_NAME
          ) {
            return false;
          }
        }

        return !omittedOptionIds.has(field.id);
      },
      [allowSelectWorkflowOwner, allowedFieldPlugins, omittedOptionIds],
    );

    const handleAutocompleteOpen = useCallback(() => {
      // Reload the fields when the autocomplete opens.
      void getFieldsAsync();
    }, [getFieldsAsync]);

    const handleChange = useCallback(
      (nextValue: FieldFields | null, reason: RegrelloSelectChangeReason) => {
        if (nextValue == null) {
          onChange(null, reason);
          return;
        }

        onChange(nextValue, reason);
      },
      [onChange],
    );

    const handleCreateDialogClose = useCallback(() => setIsCreateDialogOpen(false), []);
    const handleCreateRoleDialogClose = useCallback(() => setIsCreateRoleDialogOpen(false), []);

    const handleRoleCreated = useCallback(
      (
        newRole: {
          spectrumFieldVersion?: SpectrumFieldVersionFields | null;
        } & RoleFields,
      ) => {
        const field = newRole.spectrumFieldVersion?.field;
        if (field != null) {
          setLoadedOptions(sortOptions([...loadedOptionsV2, field]));
          onChange(field, "create-option");
        }
      },
      [loadedOptionsV2, onChange],
    );

    const handleSpectrumFieldCreated = useCallback(
      (newField: SpectrumFieldVersionFields) => {
        if (newField.field != null) {
          setLoadedOptions(sortOptions([...loadedOptionsV2, newField.field]));
          onChange(newField.field, "create-option");
        }
      },
      [loadedOptionsV2, onChange],
    );

    const renderOption = useCallback((option: FieldFields | null) => {
      if (option == null) {
        return;
      }

      if (option.propertyType.dataType === PropertyDataType.REGRELLO_OBJECT_INSTANCE_ID) {
        if (option.regrelloObject == null) {
          return null;
        }

        return <RegrelloFormFieldSelectOptionRegrelloObject regrelloObject={option.regrelloObject} />;
      }

      return (
        <RegrelloFormFieldSelectOption
          mainSnippets={[{ text: option.name, highlight: false }]}
          startAdornment={
            <div className="mr-1">
              <RegrelloCustomFieldPluginIcon
                field={option}
                plugin={CustomFieldPluginRegistrar.getPluginForField(option)}
              />
            </div>
          }
        />
      );
    }, []);

    const isTooltipEnabled = useCallback((option: FieldFields | null) => {
      // Disable tooltips for `RegrelloObject` since it has its own tooltip.
      if (option?.propertyType.dataType === PropertyDataType.REGRELLO_OBJECT_INSTANCE_ID) {
        return false;
      }

      return true;
    }, []);

    const groupByRoleOrField = useCallback((option: FieldFields) => {
      const isRoleField = option.fieldRestriction?.filterByRole != null;
      if (isRoleField) {
        return Roles;
      }
      return Fields;
    }, []);

    const extraAddFieldEndOptions = [
      fieldsQueryResult.error != null ? (
        <RegrelloSelectV2ErrorOption key="RegrelloSelectV2ErrorOption" />
      ) : (
        <RegrelloSelectV2AddOption
          key="RegrelloSelectV2AddOption"
          allowCreateOptions={allowCreateFields}
          iconName="add"
          messageWithInput={AddFieldOptionToRegrello}
          onSelect={(inputValue) => {
            setDefaultValueForCreateDialog(inputValue);
            setIsCreateDialogOpen(true);
          }}
        />
      ),
    ];

    const extraEndOptionsByGroupKey = {
      Roles: [
        <RegrelloSelectV2AddOption
          key="option-add-role"
          allowCreateOptions={allowCreateRoles}
          iconName="add"
          message={AddRole}
          messageWithInput={AddRoleOptionToRegrello}
          onSelect={(inputValue) => {
            setDefaultValueForCreateDialog(inputValue);
            setIsCreateRoleDialogOpen(true);
          }}
          value="__CREATE_NEW_ROLE__"
        />,
      ],
      Fields: extraAddFieldEndOptions,
    };

    return (
      <>
        <RegrelloFormFieldSelectV2
          className={className}
          extraEndOptions={isPermissionsV2Enabled ? undefined : extraAddFieldEndOptions}
          extraEndOptionsByGroupKey={isPermissionsV2Enabled ? extraEndOptionsByGroupKey : undefined}
          filterOption={filterOption}
          getOptionLabel={getFieldLabel}
          groupBy={isPermissionsV2Enabled ? groupByRoleOrField : undefined}
          isLoading={(!fieldsQueryResult.called || fieldsQueryResult.loading) && fieldsQueryResult.data == null}
          isTooltipEnabled={isTooltipEnabled}
          onChange={handleChange}
          onClose={onClose}
          onOpen={handleAutocompleteOpen}
          options={loadedOptionsV2}
          renderOption={renderOption}
          renderSelectedValue={renderOption}
          selectRef={selectRef}
          size={size}
          {...multiselectProps}
        />

        {isCreateDialogOpen ? (
          <ConfigureSpectrumFieldDialog
            allowedFieldPlugins={allowedSpectrumFieldPlugins}
            defaultValues={{
              name: defaultValueForCreateDialog,
              allowedValues: [],
              helpText: "",
              isValueConstraintsEnabled: false,
              pluginUri: "",
              valueConstraints: [],
            }}
            isOpen={true}
            onAfterCreation={handleSpectrumFieldCreated}
            onClose={handleCreateDialogClose}
          />
        ) : null}
        {isCreateRoleDialogOpen ? (
          <RegrelloAddRoleDialog
            defaultValues={{
              name: defaultValueForCreateDialog,
              allowInvitingPeopleToTheWorkspace: false,
            }}
            isOpen={true}
            onClose={handleCreateRoleDialogClose}
            onRoleAdded={handleRoleCreated}
          />
        ) : null}
      </>
    );
  },
);

function getFieldLabel(field: FieldFields): string {
  return field.name;
}

function sortOptions(options: FieldFields[]): FieldFields[] {
  return sortIgnoreCaseWithExactMatchFirst([...options], (option) => option.name, EMPTY_STRING);
}
