import { t } from "@lingui/macro";
import React from "react";

import { RegrelloChip, RegrelloChipProps } from "./RegrelloChip";

export type RegrelloBetaChipProps = Omit<RegrelloChipProps, "intent" | "icon">;

/**
 * A thin wrapper over `RegrelloChip` that renders a standard beta chip. Intended to be used to
 * indicate features that are in a beta state.
 */
export const RegrelloBetaChip = React.memo<RegrelloBetaChipProps>(function RegrelloBetaChipFn(
  chipProps: RegrelloBetaChipProps,
) {
  return (
    <RegrelloChip {...chipProps} icon={{ type: "iconName", iconName: "magic" }} intent="secondary">
      {t`Beta`}
    </RegrelloChip>
  );
});
