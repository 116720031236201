import { t } from "@lingui/macro";
import { clsx, WithDataTestId } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloButton } from "@regrello/ui-core";
import React, { useCallback, useEffect, useRef } from "react";

import { RegrelloFormLabel } from "../RegrelloFormLabel";

export interface RegrelloFormFieldSelfContainedFormInlineProps extends WithDataTestId {
  error: string | undefined;
  fieldElement: JSX.Element;

  /**
   * Whether the label should be followed by a red asterisk.
   */
  isRequired?: boolean;

  /**
   * The display label. Optional because not all fields will have one rendered in the same way
   * (e.g. checkboxes).
   */
  label?: React.ReactChild;

  /** The width to allot to the form field's label. */
  labelWidth?: number | string;

  onClose: () => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;

  /** Whether the form field is rendered by a spectrum form. */
  variant?: "default" | "spectrum";
}

/**
 * A popover that displays the provided form field element (passed via `children`) in its own form.
 * Also provides keyboard shortcuts for and manages focus after submit/cancel. Intended for internal
 * use strictly by `RegrelloFormFieldLayout`, not for general consumption.
 */
export const RegrelloFormFieldSelfContainedFormInline = React.memo<RegrelloFormFieldSelfContainedFormInlineProps>(
  function RegrelloFormFieldSelfContainedFormInlineFn({
    dataTestId,
    error,
    fieldElement,
    isRequired,
    label,
    labelWidth,
    onClose,
    onSubmit,
    variant = "default",
  }) {
    const rootDivRef = useRef<HTMLDivElement | null>(null);
    const submitButtonRef = useRef<HTMLButtonElement | null>(null);

    const submit = useCallback(() => {
      if (error != null) {
        onClose();
      } else {
        submitButtonRef.current?.click();
      }
    }, [onClose, error]);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === "Enter" && event.metaKey) {
          submit();
        } else if (event.key === "Escape") {
          onClose();
        }
      },
      [submit, onClose],
    );

    // (clewis): Respond to keyboard shortcuts anywhere on the page, since focus may leave the popover
    // (e.g. via keyboard Tab'ing).
    useEffect(() => {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleKeyDown]);

    const handleFormSubmit = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        // (clewis): Prevent the native-form submission from refreshing the page.
        event.preventDefault();
        onSubmit(event);
        onClose();
      },
      [onClose, onSubmit],
    );

    return (
      <form data-testid={dataTestId} onSubmit={handleFormSubmit}>
        <div
          ref={rootDivRef}
          className={clsx("py-3 pl-6 pr-4 border-t border-b bg-backgroundSoft", {
            "pl-4 pr-6 bg-transparent border-0": variant === "spectrum",
          })}
        >
          <div className="flex">
            {label != null && (
              <div className="flex mr-1.5">
                <RegrelloFormLabel
                  isRequiredAsteriskShown={isRequired}
                  label={label}
                  labelWidth={labelWidth}
                  variant={variant}
                />
              </div>
            )}
            {fieldElement}
          </div>

          <div className="flex items-center mt-2 justify-end">
            <div>
              <RegrelloButton
                className="ml-1"
                dataTestId={DataTestIds.FORM_FIELD_SELF_CONTAINED_FORM_CANCEL_BUTTON}
                onClick={onClose}
                variant="outline"
              >
                {t`Cancel`}
              </RegrelloButton>
              <RegrelloButton
                ref={submitButtonRef}
                className="ml-1"
                dataTestId={DataTestIds.FORM_FIELD_SELF_CONTAINED_FORM_SAVE_BUTTON}
                disabled={error != null}
                intent="primary"
                type="submit"
              >
                {t`Save`}
              </RegrelloButton>
            </div>
          </div>
        </div>
      </form>
    );
  },
);
