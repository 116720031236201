import { arrayConcatDefined, EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import {
  ControllerBehaviorModifierType,
  FieldInstanceFields,
  useFieldInstancesAvailableAsSourceValuesInWorkflowQueryLazyQuery,
  useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryLazyQuery,
} from "@regrello/graphql-api";
import { useCallback, useMemo, useState } from "react";

import { WorkflowContext } from "../../../../../types";
import { isUserFieldInstance } from "../../../../../utils/customFields/customFieldTypeUtils";
import { getFieldInstanceId } from "../../../../../utils/customFields/getFieldInstanceId";
import { isOnSplitChild, isOnSplitterTask } from "../../../../../utils/customFields/splitFieldUtils";
import { AsyncLoaded } from "../../../../../utils/typescript/AsyncLoaded";
import { getFieldInstanceFieldsFromAcyclicFieldInstanceFields } from "../../../customFields/plugins/utils/getFieldInstanceFieldsFromAcyclicFieldInstanceFields";

import { Manager, ManagerSuffix } from "@/strings";

export namespace useFieldInstanceSearchResults {
  export interface Args {
    /** The IDs of the field instances that will be filtered out from the selectable list. */
    fieldInstanceIdsToExclude?: Set<number>;

    /**
     * The current workflow context, defining which field instances are available to select from.
     */
    workflowContext?:
      | {
          type: WorkflowContext.WORKFLOW_TEMPLATE;
          workflowTemplateId: number;
          workflowStageTemplateId: number;
          dependingOnActionItemTemplateId?: number;
        }
      | {
          type: WorkflowContext.WORKFLOW_NOT_STARTED | WorkflowContext.WORKFLOW_STARTED;
          workflowId: number;
          workflowStageId: number;
          dependingOnActionItemTemplateId?: number;
        };

    /**
     * Whether to show SCIM managers for party fields in the role selector.
     */
    showScimManagers?: boolean;
  }

  export interface Return {
    /** Callback that loads results matching the given query string. */
    loadResults: (query: string) => void;

    /** The loaded search results. */
    results: AsyncLoaded<FieldInstanceFields[]>;
  }
}

/**
 * Hook that loads workflow- or template-specific field instances that are available to select in
 * the `PartySelect` component.
 */
export function useFieldInstanceSearchResults({
  fieldInstanceIdsToExclude,
  workflowContext,
  showScimManagers,
}: useFieldInstanceSearchResults.Args): useFieldInstanceSearchResults.Return {
  const [query, setQuery] = useState<string>(EMPTY_STRING);
  const [queryManager, setQueryManager] = useState<boolean>(false);

  const isInTemplate = workflowContext?.type === WorkflowContext.WORKFLOW_TEMPLATE;
  const isInWorkflow =
    workflowContext?.type === WorkflowContext.WORKFLOW_NOT_STARTED ||
    workflowContext?.type === WorkflowContext.WORKFLOW_STARTED;

  const [getWorkflowFieldInstancesAsync, workflowFieldInstancesResult] =
    useFieldInstancesAvailableAsSourceValuesInWorkflowQueryLazyQuery({
      fetchPolicy: "cache-and-network",
    });

  const [getWorkflowTemplateFieldInstancesAsync, workflowTemplateFieldInstancesResult] =
    useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryLazyQuery({
      fetchPolicy: "cache-and-network",
    });

  const loadResults = useCallback(
    (nextQuery: string) => {
      if (workflowContext == null) {
        return;
      }
      if (workflowContext.type === WorkflowContext.WORKFLOW_TEMPLATE) {
        void getWorkflowTemplateFieldInstancesAsync({
          variables: {
            workflowTemplateId: workflowContext.workflowTemplateId,
            currentWorkflowTemplateStageId: workflowContext.workflowStageTemplateId,
            dependingOnActionItemTemplateId: workflowContext?.dependingOnActionItemTemplateId,
          },
        });
      } else {
        void getWorkflowFieldInstancesAsync({
          variables: {
            workflowId: workflowContext.workflowId,
            currentWorkflowStageId: workflowContext.workflowStageId,
            dependingOnActionItemTemplateId: workflowContext.dependingOnActionItemTemplateId,
          },
        });
      }

      let adjustedQuery = nextQuery.trim().toLocaleLowerCase();

      // (elle): If the query ends with the string manager, query the prefix.
      if (adjustedQuery.length > 0 && adjustedQuery.toLowerCase().endsWith(Manager)) {
        // Remove the manager suffix
        adjustedQuery = adjustedQuery.slice(0, -Manager.length).trim();
        setQueryManager(true);
      } else {
        setQueryManager(false);
      }

      setQuery(adjustedQuery);
    },
    [getWorkflowFieldInstancesAsync, getWorkflowTemplateFieldInstancesAsync, workflowContext],
  );

  const filterResult = useCallback(
    (fieldInstance: FieldInstanceFields) => {
      return (
        !fieldInstanceIdsToExclude?.has(getFieldInstanceId(fieldInstance)) &&
        isUserFieldInstance(fieldInstance) &&
        !isOnSplitterTask(fieldInstance) &&
        !isOnSplitChild(fieldInstance) &&
        fieldInstance.field.name.toLocaleLowerCase().includes(query)
      );
    },
    [fieldInstanceIdsToExclude, query],
  );

  const resultComparator = useCallback(
    (a: FieldInstanceFields, b: FieldInstanceFields) => {
      const isEqualsA = a.field.name === query;
      const isEqualsB = b.field.name === query;

      // (clewis): Rank exact matches first.
      if (isEqualsA && !isEqualsB) {
        return -1;
      }
      if (!isEqualsA && isEqualsB) {
        return 1;
      }

      const isPrefixA = a.field.name.startsWith(query);
      const isPrefixB = b.field.name.startsWith(query);

      // (clewis): Then rank prefix matches.
      if (isPrefixA && !isPrefixB) {
        return -1;
      }
      if (!isPrefixA && isPrefixB) {
        return 1;
      }

      // (clewis): Then rank shorter matches, which we presume have greater overlap with the query.
      if (a.field.name.length < b.field.name.length) {
        return -1;
      }
      if (a.field.name.length > b.field.name.length) {
        return 1;
      }

      return 0;
    },
    [query],
  );

  const memoizedResults: AsyncLoaded<FieldInstanceFields[]> = useMemo(() => {
    const modifyFieldInstances = (fieldInstances: FieldInstanceFields[]) => {
      const managerFieldInstances = showScimManagers
        ? fieldInstances.map((fieldInstance) => ({
            ...fieldInstance,
            controllerBehaviorModifier: ControllerBehaviorModifierType.MANAGERS_OF,
            field: {
              ...fieldInstance.field,
              name: `${fieldInstance.field.name}${ManagerSuffix}`,
            },
            values: fieldInstance.values.map((fiv) => ({
              ...fiv,
            })),
          }))
        : [];

      if (queryManager) {
        // (elle): Add any field whose name has a manager substring to the results in case the user wants to find
        // manager named roles, not manager roles themselves.
        if (query.length === 0) {
          return [
            ...fieldInstances.filter((fieldInstance) => fieldInstance.field.name.toLowerCase().includes(Manager)),
            ...managerFieldInstances,
          ];
        }

        return managerFieldInstances;
      }

      return [...fieldInstances, ...managerFieldInstances];
    };

    if (isInTemplate) {
      return AsyncLoaded.fromGraphQlQueryResult(workflowTemplateFieldInstancesResult, (data) => {
        // (zstanik): `fieldInstancesAvailableAsSourceValuesInWorkflowTemplate` was updated to
        // return base field instances to fix a query loop caused by non-normalized field instance
        // values (which can't be normalized at the moment without a much larger lift) getting
        // replaced by slightly different data in a loop. These particular field instances don't
        // need to be full `FieldInstanceFields`, but convert them to stubbed `FieldInstanceFields`
        // first to make them compatible with child components below.
        const fieldInstances = arrayConcatDefined<FieldInstanceFields>(
          (
            data.fieldInstancesAvailableAsSourceValuesInWorkflowTemplate?.workflowTemplateFieldInstances ?? EMPTY_ARRAY
          ).map(getFieldInstanceFieldsFromAcyclicFieldInstanceFields),
          data.fieldInstancesAvailableAsSourceValuesInWorkflowTemplate?.actionItemTemplateFieldInstances,
        )
          .filter(filterResult)
          .sort(resultComparator);

        return modifyFieldInstances(fieldInstances);
      });
    } else if (isInWorkflow) {
      return AsyncLoaded.fromGraphQlQueryResult(workflowFieldInstancesResult, (data) => {
        const fieldInstances = arrayConcatDefined(
          data.fieldInstancesAvailableAsSourceValuesInWorkflow?.workflowFieldInstances,
          data.fieldInstancesAvailableAsSourceValuesInWorkflow?.actionItemFieldInstances,
          data.fieldInstancesAvailableAsSourceValuesInWorkflow?.actionItemTemplateFieldInstances,
        )
          .filter(filterResult)
          .sort(resultComparator);

        return modifyFieldInstances(fieldInstances);
      });
    } else {
      return AsyncLoaded.loaded(EMPTY_ARRAY);
    }
  }, [
    filterResult,
    isInTemplate,
    isInWorkflow,
    query.length,
    queryManager,
    resultComparator,
    showScimManagers,
    workflowFieldInstancesResult,
    workflowTemplateFieldInstancesResult,
  ]);

  return { loadResults, results: memoizedResults };
}
