import { t } from "@lingui/macro";
import { AccessRoleUserScope, TeamType, UserType } from "@regrello/graphql-api";
import { FlatParty, FlatPartyUserType } from "@regrello/ui-app-molecules";

import { PartyTypeUnion } from "./PartyTypeUnion";
import { getFieldInstanceName, getUserFullName, USER_FIELD_INSTANCE_PARTY_ID } from "./partyUtils";

export function toFlatParty(party: PartyTypeUnion): FlatParty {
  return PartyTypeUnion.visit(party, {
    user(user) {
      return {
        email: user.email,
        fullName: getUserFullName(user.name, user.email, user.userType),
        id: user.party.id,
        isInactive: user.deletedAt != null,
        isInternal: user.accessRole?.userScope === AccessRoleUserScope.INTERNAL,
        isMuted: user.isMuted,
        type: "user" as FlatPartyUserType,
        userType: user.userType,
        deletedAt: user.deletedAt || undefined,
      };
    },
    baseUser(baseUser) {
      return {
        email: baseUser.email,
        fullName: getUserFullName(baseUser.name, baseUser.email, baseUser.userType),
        id: baseUser.party.id,
        isInactive: baseUser.deletedAt != null,
        isInternal: baseUser.accessRole?.userScope === AccessRoleUserScope.INTERNAL,
        isMuted: baseUser.isMuted,
        type: "baseUser",
        userType: baseUser.userType,
        deletedAt: baseUser.deletedAt || undefined,
      };
    },
    userWithRoles(userWithRoles) {
      return {
        email: userWithRoles.email,
        fullName: getUserFullName(userWithRoles.name, userWithRoles.email, userWithRoles.userType),
        id: userWithRoles.party.id,
        isInactive: userWithRoles.deletedAt != null,
        isInternal: userWithRoles.accessRole?.userScope === AccessRoleUserScope.INTERNAL,
        isMuted: userWithRoles.isMuted,
        type: "userWithRoles",
        userType: userWithRoles.userType,
        deletedAt: userWithRoles.deletedAt || undefined,
      };
    },
    fieldInstance(fieldInstance) {
      return {
        email: "",
        fieldInstanceSubtype: fieldInstance.field.fieldRestriction?.filterByRole != null ? "role" : undefined,
        fullName: getFieldInstanceName(fieldInstance),
        id: fieldInstance.values[0]?.id || USER_FIELD_INSTANCE_PARTY_ID,
        isInactive: false,
        isInternal: false,
        isMuted: false,
        type: "fieldInstance",
        userType: UserType.DEFAULT,
        deletedAt: undefined,
      };
    },
    team(team) {
      return {
        email: team.email,
        fullName: team.name,
        id: team.party.id,
        isInactive: team.deletedAt != null,
        isInternal: team.type === TeamType.INTERNAL,
        isMuted: false,
        type: "team",
        userType: UserType.DEFAULT,
        deletedAt: team.deletedAt || undefined,
      };
    },
    role(role) {
      return {
        email: "",
        fullName: role.name,
        id: role.id,
        isInactive: false,
        isInternal: false,
        isMuted: false,
        type: "role",
        userType: UserType.DEFAULT,
        deletedAt: undefined,
      };
    },
    unknown() {
      return {
        email: "",
        fullName: t`Unknown`,
        id: 0,
        isInactive: false,
        isInternal: false,
        isMuted: false,
        type: "baseUser",
        userType: UserType.DEFAULT,
        deletedAt: undefined,
      };
    },
  });
}

export function toFlatPartyArray(parties: PartyTypeUnion[]): FlatParty[] {
  return parties.map((party) => toFlatParty(party));
}
