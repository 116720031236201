import { PureQueryOptions } from "@apollo/client";
import { t } from "@lingui/macro";
import { EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FieldFields, useCreateFieldMutation, usePropertyTypesQuery } from "@regrello/graphql-api";
import { RegrelloFullViewSpinner } from "@regrello/ui-core";
import React, { useCallback, useEffect, useState } from "react";

import { ConfigureFieldForm, ConfigureFieldFormFormFields } from "./_internal/ConfigureFieldForm";
import { SubmitHandler } from "../../../../../../types/form";
import { consoleWarnInDevelopmentModeOnly } from "../../../../../../utils/environmentUtils";
import { getGraphQlErrorCode, RegrelloGraphQlErrorCode } from "../../../../../../utils/errorUtils";
import { useErrorHandler } from "../../../../../../utils/hooks/useErrorHandler";
import { RegrelloFormDialogName } from "../../../../../../utils/sentryScopeUtils";
import { RegrelloFormDialog } from "../../../../../atoms/dialog/RegrelloFormDialog";
import { CustomFieldPlugin } from "../../../../../molecules/customFields/plugins/types/CustomFieldPlugin";

export interface CreateFieldDialogProps {
  /**
   * An allow list for filtering which field plugins this dialog should allow the user to create.
   */
  allowedFieldPlugins?: Array<CustomFieldPlugin<unknown>>;

  /**
   * If defined, the multiplicity switch, if the field type supports it, will be disabled with this
   * text rendered below.
   */
  allowMultipleSwitchDisabledHelperText?: string;

  /** Initial value for the multiplicity switch, if the field type supports the option. */
  defaultAllowMultiple?: boolean;

  /**
   * An initial value to show in the "allowed values" options.
   *
   * @default []
   */
  defaultAllowedValues?: Array<{ value: string }>;

  /**
   * An initial value to show in the "name" field. Intended to save the user some time.
   * @default ""
   */
  defaultValue?: string;

  /**
   * Whether to restrict the allowed values to the provided default values. This will disable the
   * controls on the allowed values portion of the field for the relevant select / multi-select
   * types.
   *
   * @default false
   */
  disableAllowedValuesEditing?: boolean;

  /** Whether the dialog should render as open. */
  isOpen: boolean;

  /** Callback invoked when the dialog wants to close. */
  onClose: () => void;

  /** Callback invoked when the provided field is successfully created. */
  onFieldCreated?: (newField: FieldFields) => void;

  /** An array of queries to refetch when the field has been created. */
  refetchQueries?: PureQueryOptions[];

  /**
   * Label for the submit button.
   * @default "Create"
   */
  submitButtonText?: string;
}

/**
 * A dialog that allows users to create custom fields globally in the workspace. (These fields must
 * be intantiated in a particular action item or workflow or template in order to be used.)
 */
export const CreateFieldDialog = React.memo<CreateFieldDialogProps>(function CreateFieldDialogFn({
  allowedFieldPlugins,
  allowMultipleSwitchDisabledHelperText,
  defaultAllowMultiple,
  defaultAllowedValues = EMPTY_ARRAY,
  defaultValue = EMPTY_STRING,
  disableAllowedValuesEditing = false,
  isOpen,
  onClose,
  onFieldCreated,
  refetchQueries,
  submitButtonText,
}) {
  const { handleError } = useErrorHandler();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  // Reset the error message when the dialog is closed.
  useEffect(() => {
    if (!isOpen) {
      setErrorMessage(undefined);
    }
  }, [isOpen]);

  const propertyTypesQueryResult = usePropertyTypesQuery({
    onError: (error) => handleError(error),
  });

  const [createFieldAsync] = useCreateFieldMutation({
    onError: (error) => {
      const code = getGraphQlErrorCode(error);
      if (code === RegrelloGraphQlErrorCode.DUPLICATE_FIELDS_NOT_ALLOWED) {
        setErrorMessage(t`A field with that name already exists.`);
      } else if (code === RegrelloGraphQlErrorCode.VALIDATION_ERROR_CODE_NAME_CONFLICT) {
        setErrorMessage(t`A field with that name already exists.`);
      } else {
        setErrorMessage(undefined);
        handleError(error, {
          toastMessage: t`Failed to create field. Please try again, or contact a Regrello admin if you continue to see this error.`,
        });
      }
    },
    refetchQueries: refetchQueries,
  });

  const loadedPropertyTypes = propertyTypesQueryResult.data?.propertyTypes ?? EMPTY_ARRAY;

  const handleSubmit: SubmitHandler<ConfigureFieldFormFormFields> = useCallback(
    async (data) => {
      const name = data.name;

      const customFieldPlugin = data.plugin as CustomFieldPlugin<unknown> | null; // (clewis): Methods aren't callable without this cast.
      if (customFieldPlugin == null) {
        consoleWarnInDevelopmentModeOnly("The CreateField form was submitted with a missing plugin.");
        return false;
      }

      const propertyTypeId = customFieldPlugin.findPropertyTypeIdFromLoadedPropertyTypeIds(loadedPropertyTypes);
      if (propertyTypeId == null) {
        consoleWarnInDevelopmentModeOnly(
          "Could not find a property type ID for the field in the submitted CreateField form.",
        );
        return false;
      }

      if (!customFieldPlugin.isMultiValued?.() && data.allowMultiple === true) {
        console.warn(
          "User provided multiple-valued variant of field that stricly disallows multiple value inputs. " +
            "This state should be impossible to reach and represents a misconfiguration of custom field " +
            `plugin '${customFieldPlugin.getFieldDisplayName()}'.`,
        );
      }

      const result = await createFieldAsync({
        variables: {
          input: {
            name,
            propertyTypeId,
            allowedValues: customFieldPlugin.hasAllowedValues?.()
              ? data.allowedValues.map(({ value }, index) => ({ displayOrder: index, allowedValueString: value }))
              : EMPTY_ARRAY,
            fieldUnitId: data.fieldUnit?.id,
            isMultiValued: customFieldPlugin.isMultiValued?.() && (data.allowMultiple ?? true),
          },
        },
      });

      if (result.errors != null) {
        return false;
      }

      if (result.data?.createField?.field == null) {
        console.warn("Created a field successfully, but no field data was returned in the response.");
        return false;
      }

      onFieldCreated?.(result.data.createField.field);
      return true;
    },
    [createFieldAsync, onFieldCreated, loadedPropertyTypes],
  );

  const defaultValues: ConfigureFieldFormFormFields = {
    name: defaultValue,
    plugin: null,
    allowedValues: defaultAllowedValues,
    fieldUnit: undefined,
    allowMultiple: defaultAllowMultiple,
  };

  return (
    <RegrelloFormDialog
      dataTestId={DataTestIds.CREATE_FIELD_DIALOG}
      defaultValues={defaultValues}
      error={errorMessage}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      scopeNameForSentry={RegrelloFormDialogName.CREATE_FIELD}
      showRequiredInstruction={true}
      submitButtonText={submitButtonText ?? t`Create`}
      title={t`Add field`}
    >
      {(form) => {
        return propertyTypesQueryResult.data?.propertyTypes == null ? (
          <RegrelloFullViewSpinner />
        ) : (
          <ConfigureFieldForm
            allowedFieldPlugins={allowedFieldPlugins}
            allowMultipleSwitchDisabledHelperText={allowMultipleSwitchDisabledHelperText}
            defaultAllowMultiple={defaultAllowMultiple}
            disableAllowedValuesEditing={disableAllowedValuesEditing}
            form={form}
            mode="create"
          />
        );
      }}
    </RegrelloFormDialog>
  );
});
