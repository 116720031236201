import { t } from "@lingui/macro";
import {
  EMPTY_ARRAY,
  EMPTY_STRING,
  getEmailDomain,
  isDefined,
  isSameGeneralizedDomain,
  useConfirmationDialog,
} from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { AccessRoleUserScope, TeamFields, TeamMemberFields, TeamType, UserFields } from "@regrello/graphql-api";
import { RegrelloConfirmationDialog } from "@regrello/ui-core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SetValueConfig, UseFormReturn, useWatch } from "react-hook-form";

import { ValidationRules } from "../../../../../constants/globalConstants";
import { FeatureFlagService } from "../../../../../services/FeatureFlagService";
import { PartyTypeUnion } from "../../../../../utils/parties/PartyTypeUnion";
import { getPartyEmail, getPartyId, getUserId } from "../../../../../utils/parties/partyUtils";
import { useUser } from "../../../../app/authentication/userContextUtils";
import { Permissions } from "../../../../app/authorization/permissions";
import {
  RegrelloControlledFormFieldPartySelect,
  RegrelloControlledFormFieldSelect,
} from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";

import { AllTeamMembersMustHaveSameTypeAsTeam } from "@/strings";

export enum FrontendTeamType {
  INTERNAL = "Internal",
  EXTERNAL = "External",
}

const TEAM_TYPE_OPTIONS = [FrontendTeamType.INTERNAL, FrontendTeamType.EXTERNAL];
const TEAM_TYPE_LABEL_WIDTH = 50;
const DEFAULT_LABEL_WIDTH = 110;

export namespace ConfigureTeamForm {
  export enum Context {
    CREATE = "create",
    EDIT = "edit",
  }
  export interface Fields {
    name: string;
    teamType: FrontendTeamType;
    teamAdmins: PartyTypeUnion[];
    members: PartyTypeUnion[];
    email: string;
  }

  export interface Props {
    context: Context;
    form: UseFormReturn<ConfigureTeamForm.Fields>;
  }

  export function createCreateMutationPayloadFromFormValues(formValues: Fields) {
    return {
      input: {
        name: formValues.name,
        email: formValues.email,
        type: formValues.teamType === FrontendTeamType.INTERNAL ? TeamType.INTERNAL : TeamType.EXTERNAL,
        teamAdminIds: formValues.teamAdmins.map(getUserId).filter(isDefined),
        memberIds: formValues.members.map(getUserId).filter(isDefined),
      },
    };
  }

  export function createUpdateMutationPayloadFromFormValues(teamId: number, formValues: Fields) {
    return {
      id: teamId,
      input: {
        name: formValues.name,
        email: formValues.email,
        teamAdminIds: formValues.teamAdmins.map(getUserId).filter(isDefined),
        memberIds: formValues.members.map(getUserId).filter(isDefined),
      },
    };
  }

  export function getDefaultValues(team?: TeamFields): Fields {
    if (team != null) {
      const { admins, nonAdmins } = splitMemberIntoTeamAdminAndNonAdmin(team.teamMembers);
      return {
        name: team.name,
        teamType: team.type === TeamType.INTERNAL ? FrontendTeamType.INTERNAL : FrontendTeamType.EXTERNAL,
        teamAdmins: admins.map((fields) => PartyTypeUnion.user(fields)),
        members: nonAdmins.map((fields) => PartyTypeUnion.user(fields)),
        email: team.email,
      };
    }
    return {
      name: EMPTY_STRING,
      teamType: FrontendTeamType.INTERNAL,
      teamAdmins: EMPTY_ARRAY,
      members: EMPTY_ARRAY,
      email: EMPTY_STRING,
    };
  }

  export const Component = React.memo<ConfigureTeamForm.Props>(function ConfigureTeamFormFn({ context, form }) {
    // (hchen): Watch the change that happened to select and multselects so that the component knows
    // to rerender and update the user list accordingly when they change.
    useWatch({ control: form.control });
    const formSetValue = form.setValue as <K extends keyof Fields>(
      name: K,
      value: Fields[K],
      options?: SetValueConfig,
    ) => void;
    const formValues = form.getValues();
    const { currentUser } = useUser();

    const [teamDomain, setTeamDomain] = useState<string | undefined>(undefined);
    const isTeamAdminsFieldEmphasized = useMemo(() => formValues.teamAdmins.length === 0, [formValues]);

    const teamTypeValueBeforeRef = useRef<FrontendTeamType>(FrontendTeamType.INTERNAL);

    // (wsheehan): Do not include the selected admins as options for the team members field.
    const adminIdsToOmit: Set<number> = useMemo(
      () => new Set(formValues.teamAdmins.map(getPartyId)),
      [formValues.teamAdmins],
    );

    // (wsheehan): Do not include the selected members as options for the team admins field.
    const memberIdsToOmit: Set<number> = useMemo(
      () => new Set(formValues.members.map(getPartyId)),
      [formValues.members],
    );

    const isUnlikeSelectedEmails = useCallback(
      (email: string) =>
        teamDomain != null &&
        teamDomain !== EMPTY_STRING &&
        !isSameGeneralizedDomain(getEmailDomain(email), teamDomain),
      [teamDomain],
    );

    const handleSwitchType = useCallback(async (): Promise<boolean> => {
      form.resetField("teamAdmins");
      form.resetField("members");
      setTeamDomain(undefined);
      teamTypeValueBeforeRef.current = formValues.teamType;
      return true;
    }, [form, formValues.teamType]);

    const {
      open: openSwitchTypeDialog,
      isOpen: isSwitchTypeDialogOpen,
      close: closeSwitchTypeDialog,
      confirm: confirmSwitchType,
    } = useConfirmationDialog({
      submitAsync: handleSwitchType,
    });

    const onTypeChange = useCallback(
      (_name: string, newValue: FrontendTeamType | null) => {
        if ((newValue !== formValues.teamType && formValues.teamAdmins.length > 0) || formValues.members.length > 0) {
          openSwitchTypeDialog();
        }
      },
      [formValues.members.length, formValues.teamAdmins.length, formValues.teamType, openSwitchTypeDialog],
    );

    useEffect(() => {
      if (formValues.teamAdmins.length > 0) {
        const representativeUser = formValues.teamAdmins[0];
        setTeamDomain(getEmailDomain(getPartyEmail(representativeUser) ?? EMPTY_STRING));
      } else if (formValues.members.length > 0) {
        const representativeUser = formValues.members[0];
        setTeamDomain(getEmailDomain(getPartyEmail(representativeUser) ?? EMPTY_STRING));
      } else {
        setTeamDomain(undefined);
      }
    }, [formValues.members, formValues.teamAdmins]);

    const onCancelSwitchType = useCallback(() => {
      if (teamTypeValueBeforeRef.current != null && teamTypeValueBeforeRef.current !== formValues.teamType) {
        formSetValue("teamType", teamTypeValueBeforeRef.current);
      }
      closeSwitchTypeDialog();
    }, [closeSwitchTypeDialog, formSetValue, formValues.teamType]);

    const validateTeamAdminsOrMembers = useCallback(
      (userFields: PartyTypeUnion[], type: "admins" | "members"): boolean | string => {
        const requiredUserScope =
          formValues.teamType === FrontendTeamType.INTERNAL
            ? AccessRoleUserScope.INTERNAL
            : AccessRoleUserScope.EXTERNAL;

        const userFieldsAsType = userFields
          .map((user) => {
            switch (user.type) {
              case "baseUser":
                return user.baseUser;
              case "user":
                return user.user;
              case "userWithRoles":
                return user.user;
              default:
                return undefined;
            }
          })
          .filter(isDefined);
        if (userFieldsAsType.some((user) => user.accessRole?.userScope !== requiredUserScope)) {
          return AllTeamMembersMustHaveSameTypeAsTeam(requiredUserScope.toLocaleLowerCase());
        } else if (
          (type === "members" && userFieldsAsType.some((user) => adminIdsToOmit.has(user.party.id))) ||
          (type === "admins" && userFieldsAsType.some((user) => memberIdsToOmit.has(user.party.id)))
        ) {
          return t`Cannot specify a user as both a team admin and member`;
        } else if (
          formValues.teamType !== FrontendTeamType.INTERNAL &&
          teamDomain != null &&
          teamDomain !== EMPTY_STRING &&
          userFieldsAsType.some((user) => !isSameGeneralizedDomain(getEmailDomain(user.email), teamDomain))
        ) {
          return t`All users must be in the same email domain for an internal team`;
        } else {
          return true;
        }
      },
      [adminIdsToOmit, formValues.teamType, memberIdsToOmit, teamDomain],
    );

    const teamAdminPermissionsEnabled = FeatureFlagService.isEnabled(FeatureFlagKey.TEAM_ADMIN_PERMISSIONS_2024_06);

    const partyLoadOptions = useMemo(
      () =>
        formValues.teamType === FrontendTeamType.INTERNAL
          ? { users: { showInternalOnly: true } }
          : { users: { showExternalOnly: true } },
      [formValues.teamType],
    );

    const canInvite = Permissions.Create.canCreateUsers(currentUser);

    return (
      <>
        <div className="flex">
          <RegrelloControlledFormFieldText
            autoFocus={true}
            className="grow mr-6"
            controllerProps={{
              control: form.control,
              name: "name",
              rules: ValidationRules.REQUIRED,
            }}
            dataTestId={DataTestIds.CONFIGURE_TEAM_DIALOG_TEAM_NAME}
            isRequiredAsteriskShown={true}
            label={t`Name`}
            labelWidth={DEFAULT_LABEL_WIDTH}
            placeholder={t`Team name`}
          />
          <RegrelloControlledFormFieldSelect
            className="basis-40"
            controllerProps={{
              control: form.control,
              name: "teamType",
              rules: ValidationRules.REQUIRED,
            }}
            dataTestId={DataTestIds.CONFIGURE_TEAM_DIALOG_TEAM_TYPE}
            disabled={context === Context.EDIT || currentUser.accessRole?.userScope === AccessRoleUserScope.EXTERNAL}
            getOptionLabel={(option) => option}
            isRequiredAsteriskShown={true}
            label={t`Type`}
            labelWidth={TEAM_TYPE_LABEL_WIDTH}
            onValueChange={onTypeChange}
            options={TEAM_TYPE_OPTIONS}
          />
        </div>
        <RegrelloControlledFormFieldPartySelect
          allowCreate={{
            teams: currentUser.isAllowedToCreateTeams,
            users: canInvite,
          }}
          controllerProps={{
            control: form.control,
            name: "teamAdmins",
            rules: {
              ...ValidationRules.REQUIRED,
              validate: (userUnions) => validateTeamAdminsOrMembers(userUnions, "admins"),
            },
          }}
          dataTestId={DataTestIds.CONFIGURE_TEAM_DIALOG_ADMINS}
          getIsPartyExcluded={formValues.teamType === FrontendTeamType.INTERNAL ? undefined : isUnlikeSelectedEmails}
          helperText={
            teamAdminPermissionsEnabled
              ? t`Can view workflows and tasks owned by or assigned to the team or any team member`
              : t`Team admins are able to add and remove members of the team. They have access to all workflows and tasks owned by any team member.`
          }
          hiddenTabs={{ teams: true, users: false }}
          isEmphasized={isTeamAdminsFieldEmphasized}
          isRequiredAsteriskShown={true}
          label={t`Team admins`}
          labelWidth={DEFAULT_LABEL_WIDTH}
          loadOptions={partyLoadOptions}
          partyIdsToExclude={memberIdsToOmit}
          placeholder={t`Choose members`}
        />
        <RegrelloControlledFormFieldPartySelect
          allowCreate={{
            teams: currentUser.isAllowedToCreateTeams,
            users: canInvite,
          }}
          controllerProps={{
            control: form.control,
            name: "members",
            rules: {
              validate: (userUnions) => validateTeamAdminsOrMembers(userUnions, "members"),
            },
          }}
          dataTestId={DataTestIds.CONFIGURE_TEAM_DIALOG_MEMBERS}
          getIsPartyExcluded={formValues.teamType === FrontendTeamType.INTERNAL ? undefined : isUnlikeSelectedEmails}
          hiddenTabs={{ teams: true, users: false }}
          label={t`Members`}
          labelWidth={DEFAULT_LABEL_WIDTH}
          loadOptions={partyLoadOptions}
          partyIdsToExclude={adminIdsToOmit}
          placeholder={t`Choose members`}
        />
        <RegrelloControlledFormFieldText
          controllerProps={{
            control: form.control,
            name: "email",
            rules: ValidationRules.VALID_OR_EMPTY_EMAIL,
          }}
          dataTestId={DataTestIds.CONFIGURE_TEAM_DIALOG_TEAM_EMAIL}
          helperText={t`If your team uses a group email for communication, you can provide it instead of adding members individually. Notifications will only go out to members of this team email.`}
          label={t`Team email`}
          labelWidth={DEFAULT_LABEL_WIDTH}
          placeholder={t`teamalias@yourcompany.com`}
        />

        <RegrelloConfirmationDialog
          content={t`Switching the team type will clear any people assignments you’ve made.`}
          isOpen={isSwitchTypeDialogOpen}
          onClose={onCancelSwitchType}
          onConfirm={confirmSwitchType}
          title={t`Are you sure?`}
        />
      </>
    );
  });
}

function splitMemberIntoTeamAdminAndNonAdmin(members: TeamMemberFields[]): {
  admins: UserFields[];
  nonAdmins: UserFields[];
} {
  const admins = new Array<UserFields>();
  const nonAdmins = new Array<UserFields>();
  for (const member of members) {
    // (hchen): TODO: Use enum once BE change accessRoles to enums.
    if (member.isAdmin) {
      admins.push(member.user);
    } else {
      nonAdmins.push(member.user);
    }
  }
  return {
    admins,
    nonAdmins,
  };
}
