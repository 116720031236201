import { t } from "@lingui/macro";
import {
  arrayRemoveAtIndex,
  assertNever,
  EMPTY_ARRAY,
  EMPTY_STRING,
  isDefined,
  useOnOpen,
  useSimpleDialog,
} from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  ActionItemStatus,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  FieldInstanceValueInputType,
  RoleFields,
  useRoleSelectorQueryResultsQueryLazyQuery,
  UserSelectorRoleBasedFilter,
  useTeamSelectorQueryResultsQueryLazyQuery,
  useUserWithRolesSelectorQueryResultsQueryLazyQuery,
} from "@regrello/graphql-api";
import { RegrelloPartyAvatar } from "@regrello/ui-app-molecules";
import {
  RegrelloIcon,
  RegrelloMultiSelectProps,
  RegrelloSize,
  RegrelloTabsList,
  RegrelloTabsRoot,
  RegrelloTabsTrigger,
  RegrelloTooltipV4,
  RegrelloTypography,
} from "@regrello/ui-core";
import throttle from "lodash/throttle";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useMount } from "react-use";

import { useFieldInstanceSearchResults } from "./_internal/useFieldInstanceSearchResults";
import { FeatureFlagService } from "../../../../services/FeatureFlagService";
import { WorkflowContext } from "../../../../types";
import { getCustomFieldInstanceInputType } from "../../../../utils/customFields/getCustomFieldInstanceInputType";
import { getIndexOfPartyInPartyTypeUnionArray } from "../../../../utils/getIndexOfPartyInPartyTypeUnionArray";
import { PartyTypeUnion } from "../../../../utils/parties/PartyTypeUnion";
import { getMenuItemKey, getPartyFieldInstance, partyListSortComparator } from "../../../../utils/parties/partyUtils";
import { toFlatParty } from "../../../../utils/parties/toFlatParty";
import { AsyncLoaded } from "../../../../utils/typescript/AsyncLoaded";
import { RegrelloFieldChip } from "../../../atoms/contentChips/RegrelloFieldChip";
import { RegrelloAddRoleDialog } from "../../../views/modals/formDialogs/roles/RegrelloAddRoleDialog";
import { ConfigureTeamDialog } from "../../../views/modals/formDialogs/teams/ConfigureTeamDialog";
import { AddUserDialog } from "../../../views/modals/formDialogs/users/AddUserDialog";
import { CustomFieldPluginRegistrar } from "../../customFields/plugins/registry/customFieldPluginRegistrar";
import { RegrelloFormFieldMultiSelect, RegrelloFormFieldMultiSelectProps } from "../RegrelloFormFieldMultiSelect";

import {
  RegrelloFormFieldPartySelectWithRoleFilterMultipleRoles,
  RegrelloFormFieldPartySelectWithRoleFilterSingleRole,
  ShowingAllSubjectInTheWorkspace,
} from "@/strings";

enum PartySelectTabId {
  ALL = "all",
  FIELDS = "fields",
  ROLES = "roles",
  TEAMS = "teams",
  USERS = "users",
}

const DEBOUNCED_SEARCH_DELAY_MS = 250;

const tabDataTestIds: Record<PartySelectTabId, string> = {
  [PartySelectTabId.ALL]: DataTestIds.PARTY_SELECT_TAB_ALL,
  [PartySelectTabId.USERS]: DataTestIds.PARTY_SELECT_TAB_USER,
  [PartySelectTabId.TEAMS]: DataTestIds.PARTY_SELECT_TAB_TEAM,
  [PartySelectTabId.ROLES]: DataTestIds.PARTY_SELECT_TAB_ROLE,
  [PartySelectTabId.FIELDS]: DataTestIds.PARTY_SELECT_TAB_FIELD_INSTANCE,
};

export interface RegrelloFormFieldPartySelectProps
  extends Omit<
    RegrelloFormFieldMultiSelectProps<PartyTypeUnion>,
    "getSelectedItemProps" | "itemPredicate" | "items" | "renderItem" | "isItemsEqual"
  > {
  /**
   * Defines whether the 'Add X' options are shown in each tab.
   *
   * ___Note:___ We don't support creating field instances.
   */
  allowCreate?: {
    teams?: boolean;
    users?: boolean;
    roles?: boolean;
  };

  /**
   * Callback invoked to get the tooltip text, if any, to render for a disabled selected party
   * value. If defined and returns a defined value, the provided party value will be prevented from
   * being removed from the input.
   */
  getDisabledSelectedValueTooltipText?: (value: PartyTypeUnion) => string | undefined;

  /**
   * Similar in spirit to `partyIdsToExclude`, this callback can return `true` dynamically to
   * indicate that a specified party should also be excluded - in addition to the static set of
   * party IDs provided in `partyIdsToExclude`.
   */
  getIsPartyExcluded?: (partyEmail: string) => boolean;

  /**
   * Forces an error state to be shown when `true`.
   * @default false
   */
  hasErrorOverride?: boolean;

  /**
   * Describes which tabs should be hidden (`true`) or visible (`false`). By default, all tabs are
   * and should be visible (`false`).
   */
  hiddenTabs?: {
    fields?: boolean;
    roles?: boolean;
    teams?: boolean;
    users?: boolean;
  };

  /**
   * Limits the number of parties that can be selected to 1.
   * @default false
   */
  isSingleParty?: boolean;

  /**
   * Options to control how various party types are queried. The hook
   * {@link useActionItemRoleReassignment} can help determine the values for this prop when used in
   * an assignee field.
   *
   * ___Note:___ The `loadOptions`, `fields`, and `users` objects themselves do not need to be
   * memoized.
   */
  loadOptions?: {
    /** Options to control how workflow/blueprint fields are queried. */
    fields?: {
      /**
       * Whether optional fields with empty value and the tasks it comes from is completed is shown as
       * disabled.
       * @default false
       */
      allowEmptyOptionalFieldInstances?: boolean;

      /** The IDs of the field instances that will be filtered out from the selectable list. */
      fieldInstanceIdsToExclude?: Set<number>;

      /**
       * Whether to show SCIM managers for party fields in the role selector.
       * @default false
       */
      showScimManagers?: boolean;

      /**
       * The current workflow context, defining which field instances are available to select from.
       *
       * ___Note:___ This prop is required for the 'Field instances' tab to appear.
       */
      workflowContext:
        | {
            type: WorkflowContext.WORKFLOW_TEMPLATE;
            workflowTemplateId: number;
            workflowStageTemplateId: number;
            dependingOnActionItemTemplateId?: number;
          }
        | {
            type: WorkflowContext.WORKFLOW_NOT_STARTED | WorkflowContext.WORKFLOW_STARTED;
            workflowId: number;
            workflowStageId: number;
            dependingOnActionItemTemplateId?: number;
          };
    };

    teams?: {
      /**
       * Limits the displayed teams to only those who are members of the given role IDs.
       */
      limitToRoleIds?: number[];
    };

    /** Options to control how users are queried. */
    users?: {
      /**
       * Limits the displayed users to only those who are members of the given role IDs.
       */
      limitToRoleIds?: number[];

      /**
       * Whether to show only admin users.
       * @default false
       */
      showAdminsOnly?: boolean;

      /**
       * Whether to limit selection to internal users.
       * @default false
       */
      showInternalOnly?: boolean;

      /**
       * Whether to limit selection to external users.
       * @default false
       */
      showExternalOnly?: boolean;

      /**
       * Whether to limit selection to internal users with creator, publisher, etc. only.
       * @default false
       */
      showInternalPrivilegedOnly?: boolean;
    };
  };

  /** Any provided tab strings will be used instead of the default tab names. */
  overrideTabNames?: {
    all?: string;
    fields?: string;
    roles?: string;
    teams?: string;
    users?: string;
  };

  /**
   * Describes parties that will be filtered out from the selectable list. IDs provided or returned
   * here will omit any overlapping IDs in {@link partyIdsToInclude}.
   */
  partyIdsToExclude?: Set<number>;

  /**
   * The IDs of the parties that will be included in the selectable list. Can be overridden by
   * {@link partyIdsToExclude}.
   */
  partyIdsToInclude?: Set<number>;

  /**
   * Describes roles that will be filtered out from the selectable list.
   */
  roleIdsToExclude?: Set<number>;
}

/**
 * A multi-select form input that allows the user to select from the parties available in their
 * Regrello workspace. Backed by `RegrelloMultiSelect`.
 */
export const RegrelloFormFieldPartySelect = React.memo<RegrelloFormFieldPartySelectProps>(
  function RegrelloFormFieldPartySelectFn({
    allowCreate,
    getDisabledSelectedValueTooltipText,
    getIsPartyExcluded,
    hasErrorOverride = false,
    helperText,
    hiddenTabs: propsHiddenTabs,
    isSingleParty = false,
    loadOptions,
    onChange,
    overrideTabNames,
    partyIdsToExclude,
    partyIdsToInclude,
    roleIdsToExclude,
    size = "large",
    startIcon,
    value: propsValue = EMPTY_ARRAY,
    ...formFieldMultiSelectV2Props
  }) {
    const [cachedIsOpen, setCachedIsOpen] = useState(false);
    const [cachedQuery, setCachedQuery] = useState(EMPTY_STRING);
    const [selectedTabId, setSelectedTabId] = React.useState<PartySelectTabId>(PartySelectTabId.ALL);

    const stableSortedValue = useMemo(() => {
      // (clewis): Sort so that the order of selected values doesn't shift during polling.
      return [...propsValue].sort(partyListSortComparator);
    }, [propsValue]);

    const isFieldsTabHidden = propsHiddenTabs?.fields ?? loadOptions?.fields == null;
    // (wsheehan): Hide the roles tab by default, unless explicity shown.
    const isRolesTabHidden = propsHiddenTabs?.roles ?? true;
    const isTeamsTabHidden = propsHiddenTabs?.teams ?? false;
    const isUsersTabHidden = propsHiddenTabs?.users ?? false;
    // (wsheehan): Show the 'All' tab if there is more than one tab shown.
    const isAllTabShown =
      [isFieldsTabHidden, isRolesTabHidden, isTeamsTabHidden, isUsersTabHidden].filter((hidden) => !hidden).length > 1;

    const hiddenTabs = useMemo(() => {
      return {
        all: !isAllTabShown,
        fields: isFieldsTabHidden,
        roles: isRolesTabHidden,
        teams: isTeamsTabHidden,
        users: isUsersTabHidden,
      };
    }, [isAllTabShown, isFieldsTabHidden, isRolesTabHidden, isTeamsTabHidden, isUsersTabHidden]);

    const shouldIncludePartyId = useCallback(
      (partyId: number, partyEmail?: string): boolean => {
        const isIncluded = partyIdsToInclude == null || partyIdsToInclude.has(partyId);
        const isExcluded = partyIdsToExclude?.has(partyId) || (partyEmail != null && getIsPartyExcluded?.(partyEmail));
        return isIncluded && !isExcluded;
      },
      [partyIdsToInclude, getIsPartyExcluded, partyIdsToExclude],
    );

    const shouldIncludeRoleId = useCallback(
      (roleId: number): boolean => {
        const isExcluded = roleIdsToExclude?.has(roleId);
        return !isExcluded;
      },
      [roleIdsToExclude],
    );

    const updateSelection = useCallback(
      (parties: PartyTypeUnion[]) => {
        let result: PartyTypeUnion[] = [...stableSortedValue];
        const optionalFieldInstancesToChangeInputType: Array<FieldInstanceFields | FieldInstanceFieldsWithBaseValues> =
          [];
        for (const party of parties) {
          const indexOfAlreadySelectedOption = getIndexOfPartyInPartyTypeUnionArray(party, stableSortedValue);

          const maybeFieldInstance = getPartyFieldInstance(party);
          // (hchen): Skip updating and show the prompt for confirming swtiching `OPTIONAL` field
          // instance to `REQUESTED` for open tasks and workflows.
          if (
            loadOptions?.fields?.allowEmptyOptionalFieldInstances != null &&
            indexOfAlreadySelectedOption < 0 &&
            maybeFieldInstance != null &&
            getCustomFieldInstanceInputType(maybeFieldInstance) === FieldInstanceValueInputType.OPTIONAL &&
            maybeFieldInstance.actionItem?.status !== ActionItemStatus.COMPLETED
          ) {
            optionalFieldInstancesToChangeInputType.push(maybeFieldInstance);
          }

          if (indexOfAlreadySelectedOption >= 0) {
            result = arrayRemoveAtIndex(result, indexOfAlreadySelectedOption);
          } else {
            result.push(party);
          }
        }

        onChange(result);
      },
      [loadOptions?.fields?.allowEmptyOptionalFieldInstances, onChange, stableSortedValue],
    );

    const {
      refetchUsers,
      asyncUserOptions,
      isRoleFilteringApplied: isRoleFilteringAppliedForUserResults,
      filteringByRoles: filteringByRolesForUserResults,
    } = useFetchUsers({
      loadOptionsForUsers: loadOptions?.users,
      partyIdsToInclude,
      shouldIncludePartyId,
    });

    const {
      refetchTeams,
      asyncTeamOptions,
      isRoleFilteringApplied: isRoleFilteringAppliedForTeamResults,
      filteringByRoles: filteringByRolesForTeamResults,
    } = useFetchTeams({
      loadOptionsForTeams: loadOptions?.teams,
      partyIdsToInclude,
      shouldIncludePartyId,
    });

    const { refetchFields, asyncFieldOptions } = useFetchFields({
      loadOptionsForFields: loadOptions?.fields,
    });

    const { refetchRoles, asyncRoleOptions } = useFetchRoles({
      shouldIncludeRoleId,
    });

    const refetchAll = useCallback(
      async (query: string) => {
        if (isTabShown(hiddenTabs, PartySelectTabId.USERS)) {
          refetchUsers(query);
        }
        if (isTabShown(hiddenTabs, PartySelectTabId.TEAMS)) {
          refetchTeams(query);
        }
        if (isTabShown(hiddenTabs, PartySelectTabId.FIELDS)) {
          refetchFields(query);
        }
        if (isTabShown(hiddenTabs, PartySelectTabId.ROLES)) {
          refetchRoles(query);
        }
      },
      [hiddenTabs, refetchUsers, refetchTeams, refetchFields, refetchRoles],
    );

    const refetchAllDebounced = useDebouncedRefetch(refetchAll);

    const loadResultsForTab = useCallback(
      (queryInternal: string, selectedTabIdInternal: PartySelectTabId) => {
        switch (selectedTabIdInternal) {
          case PartySelectTabId.ALL: {
            refetchAllDebounced(queryInternal);
            return;
          }
          case PartySelectTabId.FIELDS: {
            refetchFields(queryInternal);
            return;
          }
          case PartySelectTabId.USERS: {
            refetchUsers(queryInternal);
            return;
          }
          case PartySelectTabId.TEAMS: {
            refetchTeams(queryInternal);
            return;
          }
          case PartySelectTabId.ROLES:
            refetchRoles(queryInternal);
            return;
          default:
            assertNever(selectedTabIdInternal);
        }
      },
      [refetchAllDebounced, refetchFields, refetchRoles, refetchTeams, refetchUsers],
    );

    const getIsFieldInstanceItemDisabled = useCallback(
      (itemFieldInstance: FieldInstanceFieldsWithBaseValues) => {
        const disallowEmptyOptionalFieldInstances = !loadOptions?.fields?.allowEmptyOptionalFieldInstances;
        return (
          disallowEmptyOptionalFieldInstances &&
          getCustomFieldInstanceInputType(itemFieldInstance) === FieldInstanceValueInputType.OPTIONAL &&
          (itemFieldInstance.workflow?.scheduleStatus != null ||
            itemFieldInstance.actionItem?.status === ActionItemStatus.COMPLETED) &&
          CustomFieldPluginRegistrar.getPluginForFieldInstance(itemFieldInstance).isFieldInstanceEmpty(
            itemFieldInstance,
          )
        );
      },
      [loadOptions?.fields?.allowEmptyOptionalFieldInstances],
    );

    const getItemDisabled = useCallback(
      (item: PartyTypeUnion): boolean => {
        if (PartyTypeUnion.isFieldInstance(item)) {
          return getIsFieldInstanceItemDisabled(item.fieldInstance);
        }
        return false;
      },
      [getIsFieldInstanceItemDisabled],
    );

    const renderItem: RegrelloFormFieldMultiSelectProps<PartyTypeUnion>["renderItem"] = useCallback(
      (item: PartyTypeUnion) => {
        return {
          key: getMenuItemKey(item),
          text: <RegrelloPartyAvatar party={toFlatParty(item)} showEmail={true} showIsMutedIndicator={true} />,
          tooltip:
            PartyTypeUnion.isFieldInstance(item) && getIsFieldInstanceItemDisabled(item.fieldInstance)
              ? t`This role was optional and has not been assigned.`
              : undefined,
        };
      },
      [getIsFieldInstanceItemDisabled],
    );

    const handleOpenChange = useCallback((nextIsOpen: boolean) => {
      setCachedIsOpen(nextIsOpen);
    }, []);

    const hasRefreshedDataAfterOpen = useRef<boolean>(true);

    const handleChange = useCallback(
      (nextValue: PartyTypeUnion[]) => {
        const nextTrueValue: PartyTypeUnion[] =
          isSingleParty && nextValue.length > 0 ? [nextValue[nextValue.length - 1]] : nextValue;
        onChange(nextTrueValue);
      },
      [isSingleParty, onChange],
    );

    const handleTabChange = useCallback(
      (nextSelectedTabId: PartySelectTabId) => {
        setSelectedTabId(nextSelectedTabId);

        // (clewis): Immediately search for the same query on the new tab. This lazy approach avoids
        // having to query for data on tabs the user may never visit in the current usage session.
        loadResultsForTab(cachedQuery, nextSelectedTabId);
      },
      [loadResultsForTab, cachedQuery],
    );

    const handleSearch = useCallback(
      (nextQuery: string) => {
        setCachedQuery(nextQuery);
        loadResultsForTab(nextQuery, selectedTabId);
      },
      [loadResultsForTab, selectedTabId],
    );

    useMount(() => {
      // (clewis): Emit the selected tab ID on mount so that the caller can trigger an initial
      // search if desired. Also allows us to pre-set the initial highlighted item nicely.
      handleTabChange(selectedTabId);
    });

    useOnOpen(cachedIsOpen, () => {
      hasRefreshedDataAfterOpen.current = false;
      handleTabChange(PartySelectTabId.ALL);
    });

    const getSelectedItemProps: RegrelloFormFieldMultiSelectProps<PartyTypeUnion>["getSelectedItemProps"] = useCallback(
      (item, _index) => {
        const flatParty = toFlatParty(item);

        return {
          key: getMenuItemKey(item),
          children: <RegrelloPartyAvatar party={flatParty} showIsMutedIndicator={true} size={getAvatarSize(size)} />,
          disabled: getDisabledSelectedValueTooltipText?.(item) != null,
          disabledOverflowTooltip: getDisabledSelectedValueTooltipText?.(item) != null, // (clewis): Avoid competing tooltips.
          tooltip: getDisabledSelectedValueTooltipText?.(item),
        };
      },
      [getDisabledSelectedValueTooltipText, size],
    );

    const memoizedRenderDisplayValueForSelfContainedForm: RegrelloFormFieldMultiSelectProps<PartyTypeUnion>["renderDisplayValueForSelfContainedForm"] =
      useMemo(() => {
        return createRenderSelectedValuesForSelfContainedForm({
          size,
          getDisabledSelectedValueTooltipText,
        });
      }, [getDisabledSelectedValueTooltipText, size]);

    const asyncItems: AsyncLoaded<PartyTypeUnion[]> = useMemo(() => {
      switch (selectedTabId) {
        case PartySelectTabId.ALL: {
          const relevantAsyncOptions = [
            isTabShown(hiddenTabs, PartySelectTabId.USERS) ? asyncUserOptions : AsyncLoaded.loaded([]),
            isTabShown(hiddenTabs, PartySelectTabId.TEAMS) ? asyncTeamOptions : AsyncLoaded.loaded([]),
            isTabShown(hiddenTabs, PartySelectTabId.FIELDS) ? asyncFieldOptions : AsyncLoaded.loaded([]),
            isTabShown(hiddenTabs, PartySelectTabId.ROLES) ? asyncRoleOptions : AsyncLoaded.loaded([]),
          ];

          const asyncAllOptionsInternal = hasRefreshedDataAfterOpen.current
            ? // Avoid having results from different tabs load into the list in sputtery fashion.
              AsyncLoaded.composeFresh(...relevantAsyncOptions)
            : // Else, included previous values to avoid having to wait for a full data refresh on open.
              AsyncLoaded.compose(...relevantAsyncOptions);

          if (AsyncLoaded.isReady(asyncAllOptionsInternal) && !hasRefreshedDataAfterOpen.current) {
            hasRefreshedDataAfterOpen.current = true;
          }

          return asyncAllOptionsInternal;
        }
        case PartySelectTabId.USERS:
          return asyncUserOptions;
        case PartySelectTabId.TEAMS:
          return asyncTeamOptions;
        case PartySelectTabId.ROLES:
          return asyncRoleOptions;
        case PartySelectTabId.FIELDS:
          return asyncFieldOptions;
        default:
          assertNever(selectedTabId);
      }
    }, [asyncFieldOptions, asyncRoleOptions, asyncTeamOptions, asyncUserOptions, hiddenTabs, selectedTabId]);

    const isUsersFilterByRoleIdsAttempted = (loadOptions?.users?.limitToRoleIds || EMPTY_ARRAY).length > 0;
    const isTeamsFilterByRoleIdsAttempted = (loadOptions?.teams?.limitToRoleIds || EMPTY_ARRAY).length > 0;
    /**
     * Footer content for the popover, telling the user what results are being displayed and why.
     */
    const popoverCustomBottomContent = useMemo(() => {
      // FIXME (swerner): Is this mainly about styling? Then switch to use CSS instead. Does not seem to be a good use case for translation otherwise.
      const footerSubject = t`People`.toLocaleLowerCase();
      const footer = (text: React.ReactNode) => (
        <div className="flex flex-row gap-1 p-3 bg-backgroundSoft border-t rounded-b justify-center">
          <RegrelloTypography className="text-center" muted={true} variant="body-xs">
            {text}
          </RegrelloTypography>
        </div>
      );

      // No filtering attempted, nothing to show.
      if (!isUsersFilterByRoleIdsAttempted && !isTeamsFilterByRoleIdsAttempted) {
        return undefined;
      }

      // Role filtering was attempted, but no filtering was applied (can happen for deleted roles).
      if (!isRoleFilteringAppliedForUserResults && !isRoleFilteringAppliedForTeamResults) {
        return footer(ShowingAllSubjectInTheWorkspace(footerSubject));
      }

      const filteringByRoles = isUsersFilterByRoleIdsAttempted
        ? filteringByRolesForUserResults
        : filteringByRolesForTeamResults;

      // Single role being used for filtering.
      if (filteringByRoles.length === 1) {
        return footer(
          RegrelloFormFieldPartySelectWithRoleFilterSingleRole(
            filteringByRoles[0].name,
            footerSubject,
            <RegrelloIcon displayInline={true} iconName="role" size="x-small" />,
          ),
        );
      }

      // Otherwise, multiple roles are being used for filtering.
      return footer(
        RegrelloFormFieldPartySelectWithRoleFilterMultipleRoles(
          footerSubject,
          <RegrelloIcon displayInline={true} iconName="role" size="x-small" />,
        ),
      );
    }, [
      filteringByRolesForTeamResults,
      filteringByRolesForUserResults,
      isRoleFilteringAppliedForTeamResults,
      isRoleFilteringAppliedForUserResults,
      isTeamsFilterByRoleIdsAttempted,
      isUsersFilterByRoleIdsAttempted,
    ]);

    const memoizedItems: PartyTypeUnion[] = useMemo(() => {
      return AsyncLoaded.loadedValueOrFallback(asyncItems, EMPTY_ARRAY);
    }, [asyncItems]);

    const renderTab = useCallback(
      (tabId: PartySelectTabId, text: React.ReactNode) => {
        const resolvedTabName = overrideTabNames?.[tabId] ?? text;

        return (
          <RegrelloTabsTrigger
            className="w-full"
            data-testid={tabDataTestIds[tabId]}
            onClick={() => handleTabChange(tabId)}
            value={tabId}
          >
            <RegrelloTypography className="uppercase" variant="body-xs" weight="semi-bold">
              {resolvedTabName}
            </RegrelloTypography>
          </RegrelloTabsTrigger>
        );
      },
      [handleTabChange, overrideTabNames],
    );

    const { addRoleDialogElement, addTeamDialogElement, addUserDialogElement, createItemOptions } = useCreateItems({
      allowCreate,
      selectedTabId,
      updateSelection,
    });

    return (
      <>
        <RegrelloFormFieldMultiSelect
          {...formFieldMultiSelectV2Props}
          // (clewis): Anecdotally, users get really frustrated when this particular multiselect
          // doesn't close immediately after selection.
          //
          // See: https://regrello.slack.com/archives/C026DQBGB17/p1714671893930159
          closeOnSelect={true}
          createItemOptions={createItemOptions}
          disableSearchOnFocus={true} // (clewis): Avoid wasting time on a new search every time the user focuses the input.
          getItemDisabled={getItemDisabled}
          getSelectedItemProps={getSelectedItemProps}
          helperText={
            isSingleParty ? (
              <div className={`flex flex-col ${helperText != null && "gap-2"}`}>
                <p>{helperText != null && helperText}</p>
                <p>{t`Choose one person or team.`}</p>
              </div>
            ) : (
              helperText
            )
          }
          intent={hasErrorOverride ? "danger" : undefined}
          isItemsEqual={getIsItemsEqual}
          itemPredicate={returnTrue} // Unused; we filter via onSearch instead.
          items={memoizedItems}
          loading={!AsyncLoaded.isReady(asyncItems)}
          onChange={handleChange}
          onOpenChange={handleOpenChange}
          onSearch={handleSearch}
          popoverCustomBottomContent={
            FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01) ? popoverCustomBottomContent : undefined
          }
          popoverCustomTopContent={
            // Show tabs for all party types as custom top content in the popover.
            <div className="flex flex-col mx-1">
              {/* (clewis): Allow the tab underline to overlap the border below. */}
              <div className="relative z-1 -mb-px pt-0 sm:pt-0 flex flex-row justify-stretch gap-1 overflow-x-auto">
                <RegrelloTabsRoot className="p-0 w-full" value={selectedTabId}>
                  <RegrelloTabsList className="p-0 w-full">
                    {FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01) ? (
                      <>
                        {isTabShown(hiddenTabs, PartySelectTabId.ALL) && renderTab(PartySelectTabId.ALL, t`All`)}
                        {isTabShown(hiddenTabs, PartySelectTabId.FIELDS) &&
                          renderTab(PartySelectTabId.FIELDS, t`Fields`)}
                        {isTabShown(hiddenTabs, PartySelectTabId.USERS) && renderTab(PartySelectTabId.USERS, t`People`)}
                        {isTabShown(hiddenTabs, PartySelectTabId.TEAMS) && renderTab(PartySelectTabId.TEAMS, t`Teams`)}
                        {isTabShown(hiddenTabs, PartySelectTabId.ROLES) && renderTab(PartySelectTabId.ROLES, t`Roles`)}
                      </>
                    ) : (
                      <>
                        {isTabShown(hiddenTabs, PartySelectTabId.ALL) && renderTab(PartySelectTabId.ALL, t`All`)}
                        {isTabShown(hiddenTabs, PartySelectTabId.USERS) && renderTab(PartySelectTabId.USERS, t`People`)}
                        {isTabShown(hiddenTabs, PartySelectTabId.TEAMS) && renderTab(PartySelectTabId.TEAMS, t`Teams`)}
                        {isTabShown(hiddenTabs, PartySelectTabId.ROLES) && renderTab(PartySelectTabId.ROLES, t`Roles`)}
                        {isTabShown(hiddenTabs, PartySelectTabId.FIELDS) &&
                          renderTab(PartySelectTabId.FIELDS, t`Fields`)}
                      </>
                    )}
                  </RegrelloTabsList>
                </RegrelloTabsRoot>
              </div>
              <div className="z-0 border-b border-border" />
            </div>
          }
          renderDisplayValueForSelfContainedForm={memoizedRenderDisplayValueForSelfContainedForm}
          renderItem={renderItem}
          size={size}
          startIcon={startIcon}
          value={stableSortedValue}
        />

        {addUserDialogElement}
        {addTeamDialogElement}
        {addRoleDialogElement}
      </>
    );
  },
);

function returnTrue() {
  return true;
}

function getIsItemsEqual(a: PartyTypeUnion, b: PartyTypeUnion) {
  return getMenuItemKey(a) === getMenuItemKey(b);
}

function createRenderSelectedValuesForSelfContainedForm({
  getDisabledSelectedValueTooltipText,
  size,
}: {
  getDisabledSelectedValueTooltipText?: (value: PartyTypeUnion) => string | undefined;
  size: RegrelloSize;
}): RegrelloFormFieldMultiSelectProps<PartyTypeUnion>["renderDisplayValueForSelfContainedForm"] {
  // eslint-disable-next-line react/display-name
  return (selectedValues: PartyTypeUnion[]) => {
    const renderValue = (value: PartyTypeUnion) => {
      const flatParty = toFlatParty(value);
      return (
        <RegrelloPartyAvatar
          key={flatParty.id}
          party={flatParty}
          showIsMutedIndicator={true}
          size={getAvatarSize(size)}
        />
      );
    };

    return (
      <div className="flex flex-wrap gap-2">
        {/* (clewis): For some reason these values are sometimes not properly sorted here. */}
        {selectedValues.sort(partyListSortComparator).map((selectedValue) =>
          PartyTypeUnion.visit(selectedValue, {
            team: () => renderValue(selectedValue),
            user: () => renderValue(selectedValue),
            baseUser: () => renderValue(selectedValue),
            role: () => renderValue(selectedValue),
            userWithRoles: () => renderValue(selectedValue),
            fieldInstance: (obj: FieldInstanceFields | FieldInstanceFieldsWithBaseValues) => {
              const fieldInstanceChip = (
                <RegrelloTooltipV4 content={getDisabledSelectedValueTooltipText?.(selectedValue)}>
                  <div>
                    <RegrelloFieldChip
                      className="h-full"
                      field={obj.field}
                      onDelete={undefined}
                      party={selectedValue}
                      size={size === "x-small" || size === "small" ? "x-small" : "small"}
                    />
                  </div>
                </RegrelloTooltipV4>
              );
              return fieldInstanceChip;
            },
            unknown: () => null,
          }),
        )}
      </div>
    );
  };
}

function getAvatarSize(size: RegrelloSize) {
  switch (size) {
    case "x-small":
    case "small":
    case "medium":
      return "x-small";
    case "large":
    case "x-large":
      return "small";
    default:
      assertNever(size);
  }
}

function isTabShown<T extends NonNullable<RegrelloFormFieldPartySelectProps["hiddenTabs"]>>(
  hiddenTabs: T,
  tabId: keyof T,
): boolean {
  return hiddenTabs?.[tabId] !== true;
}

const BASE_FETCH_POLICY = "cache-and-network" as const;

/** The returned refetch function will be debounced.  */
function useFetchUsers({
  loadOptionsForUsers,
  partyIdsToInclude,
  shouldIncludePartyId,
}: {
  loadOptionsForUsers: NonNullable<RegrelloFormFieldPartySelectProps["loadOptions"]>["users"];
  partyIdsToInclude?: Set<number>;
  shouldIncludePartyId: (partyId: number, partyEmail?: string) => boolean;
}) {
  const [doUserSearchQueryAsync, userSearchQueryResult] = useUserWithRolesSelectorQueryResultsQueryLazyQuery({
    fetchPolicy: BASE_FETCH_POLICY,
  });

  const refetchUsers = useCallback(
    (nextQuery: string) =>
      doUserSearchQueryAsync({
        variables: {
          input: {
            query: nextQuery,
            partyIdsToPrioritize: Array.from(partyIdsToInclude ?? EMPTY_ARRAY),
            adminOnly: loadOptionsForUsers?.showAdminsOnly,
            roleIds: loadOptionsForUsers?.limitToRoleIds,
            filters: [
              loadOptionsForUsers?.showInternalPrivilegedOnly
                ? UserSelectorRoleBasedFilter.INTERNAL_ROLES_ONLY
                : undefined,
              loadOptionsForUsers?.showInternalOnly ? UserSelectorRoleBasedFilter.INTERNAL_ONLY : undefined,
              loadOptionsForUsers?.showExternalOnly ? UserSelectorRoleBasedFilter.EXTERNAL_ONLY : undefined,
            ].filter(isDefined),
          },
        },
      }),
    [
      doUserSearchQueryAsync,
      loadOptionsForUsers?.limitToRoleIds,
      loadOptionsForUsers?.showAdminsOnly,
      loadOptionsForUsers?.showInternalOnly,
      loadOptionsForUsers?.showExternalOnly,
      loadOptionsForUsers?.showInternalPrivilegedOnly,
      partyIdsToInclude,
    ],
  );

  const refetchUsersDebounced = useDebouncedRefetch(refetchUsers);

  const asyncUserOptions: AsyncLoaded<PartyTypeUnion[]> = useMemo(
    () =>
      AsyncLoaded.fromGraphQlQueryResult(userSearchQueryResult, (data) => {
        const resultUsers = data.userSelectorQueryResults?.users ?? EMPTY_ARRAY;
        return resultUsers
          .filter((user) => {
            const canInclude = shouldIncludePartyId(user.party.id, user.email);
            const passesAdminFilter = !loadOptionsForUsers?.showAdminsOnly || user.isAdmin;
            return canInclude && passesAdminFilter;
          })
          .map((userWithRoles) => PartyTypeUnion.userWithRoles(userWithRoles));
      }),
    [shouldIncludePartyId, loadOptionsForUsers?.showAdminsOnly, userSearchQueryResult],
  );
  const isRoleFilteringApplied = useMemo(
    () =>
      AsyncLoaded.loadedValueOrFallback(
        AsyncLoaded.fromGraphQlQueryResult(
          userSearchQueryResult,
          (data) => data.userSelectorQueryResults?.isRoleFilteringApplied ?? false,
        ),
        false,
      ),
    [userSearchQueryResult],
  );
  const filteringByRoles = useMemo(
    () =>
      AsyncLoaded.loadedValueOrFallback(
        AsyncLoaded.fromGraphQlQueryResult(
          userSearchQueryResult,
          (data) => data.userSelectorQueryResults?.filteringByRoles ?? EMPTY_ARRAY,
        ),
        EMPTY_ARRAY,
      ),
    [userSearchQueryResult],
  );

  return {
    asyncUserOptions,
    isRoleFilteringApplied,
    filteringByRoles,
    refetchUsers: refetchUsersDebounced,
  };
}

/** The returned refetch function will be debounced.  */
function useFetchTeams({
  loadOptionsForTeams,
  partyIdsToInclude,
  shouldIncludePartyId,
}: {
  loadOptionsForTeams: NonNullable<RegrelloFormFieldPartySelectProps["loadOptions"]>["teams"];
  partyIdsToInclude?: Set<number>;
  shouldIncludePartyId: (partyId: number, partyEmail?: string) => boolean;
}) {
  const [doTeamSearchQueryAsync, teamSearchQueryResult] = useTeamSelectorQueryResultsQueryLazyQuery({
    fetchPolicy: BASE_FETCH_POLICY,
  });

  const refetchTeams = useCallback(
    (nextQuery: string) =>
      doTeamSearchQueryAsync({
        variables: {
          query: nextQuery,
          roleIds: loadOptionsForTeams?.limitToRoleIds,
          partyIdsToPrioritize: Array.from(partyIdsToInclude ?? EMPTY_ARRAY),
        },
      }),
    [doTeamSearchQueryAsync, loadOptionsForTeams?.limitToRoleIds, partyIdsToInclude],
  );

  const refetchTeamsDebounced = useDebouncedRefetch(refetchTeams);

  const asyncTeamOptions: AsyncLoaded<PartyTypeUnion[]> = useMemo(
    () =>
      AsyncLoaded.fromGraphQlQueryResult(teamSearchQueryResult, (data) => {
        const resultTeams = data.teamSelectorQueryResults?.teams ?? EMPTY_ARRAY;
        return resultTeams
          .filter((team) => shouldIncludePartyId(team.party.id))
          .map((team) => PartyTypeUnion.team(team));
      }),
    [shouldIncludePartyId, teamSearchQueryResult],
  );
  const isRoleFilteringApplied = useMemo(
    () =>
      AsyncLoaded.loadedValueOrFallback(
        AsyncLoaded.fromGraphQlQueryResult(
          teamSearchQueryResult,
          (data) => data.teamSelectorQueryResults?.isRoleFilteringApplied ?? false,
        ),
        false,
      ),
    [teamSearchQueryResult],
  );
  const filteringByRoles = useMemo(
    () =>
      AsyncLoaded.loadedValueOrFallback(
        AsyncLoaded.fromGraphQlQueryResult(
          teamSearchQueryResult,
          (data) => data.teamSelectorQueryResults?.filteringByRoles ?? EMPTY_ARRAY,
        ),
        EMPTY_ARRAY,
      ),
    [teamSearchQueryResult],
  );

  return {
    asyncTeamOptions,
    isRoleFilteringApplied,
    filteringByRoles,
    refetchTeams: refetchTeamsDebounced,
  };
}

/** The returned refetch function will be debounced.  */
function useFetchFields({
  loadOptionsForFields,
}: {
  loadOptionsForFields: NonNullable<RegrelloFormFieldPartySelectProps["loadOptions"]>["fields"];
}) {
  const { loadResults: refetchFields, results: fieldSearchQueryResult } = useFieldInstanceSearchResults(
    loadOptionsForFields ?? {}, // (clewis): Memoizing this object doesn't do anything under the hood.
  );

  const refetchFieldsDebounced = useDebouncedRefetch(refetchFields);

  const asyncFieldOptions: AsyncLoaded<PartyTypeUnion[]> = useMemo(
    () =>
      AsyncLoaded.transform(fieldSearchQueryResult, (fieldInstances) => {
        return fieldInstances.map(PartyTypeUnion.fieldInstance);
      }),
    [fieldSearchQueryResult],
  );

  return {
    asyncFieldOptions,
    refetchFields: refetchFieldsDebounced,
  };
}

/** The returned refetch function will be debounced.  */
function useFetchRoles({
  shouldIncludeRoleId,
}: {
  shouldIncludeRoleId: (roleId: number) => boolean;
}) {
  // (clewis): Cache results since we expect them to change infrequently within a page visit.
  const [doRoleSearchQueryAsync, roleSearchQueryResult] = useRoleSelectorQueryResultsQueryLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const refetchRoles = useCallback(
    (nextQuery: string) => {
      void doRoleSearchQueryAsync({
        variables: {
          query: nextQuery,
        },
      });
    },
    [doRoleSearchQueryAsync],
  );

  const refetchRolesDebounced = useDebouncedRefetch(refetchRoles);

  const asyncRoleOptions: AsyncLoaded<PartyTypeUnion[]> = useMemo(() => {
    if (!FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
      // (clewis): This query will 403 if Permissions V2 is not enabled.
      return AsyncLoaded.loaded(EMPTY_ARRAY);
    }
    return AsyncLoaded.fromGraphQlQueryResult(roleSearchQueryResult, (data) => {
      const resultRoles = data.roleSelectorQueryResults.roles ?? EMPTY_ARRAY;
      return resultRoles.filter((role) => shouldIncludeRoleId(role.id)).map((role) => PartyTypeUnion.role(role));
    });
  }, [roleSearchQueryResult, shouldIncludeRoleId]);

  return {
    asyncRoleOptions,
    refetchRoles: refetchRolesDebounced,
  };
}

function useDebouncedRefetch(refetch: (nextQuery: string) => void) {
  const refetchDebounced = useMemo(
    () =>
      // (clewis): We actually use throttle here, not debounce because:
      // - We want to start the search immediately on open.
      // - We want to avoid the search being triggered multiple times in quick succession.
      // - We want to ensure we use the final query value after a flurry of keystrokes.
      throttle(refetch, DEBOUNCED_SEARCH_DELAY_MS, {
        leading: true,
        trailing: true,
      }),
    [refetch],
  );
  return refetchDebounced;
}

const BASE_CREATE_ITEM_PROPS: Partial<
  ReturnType<NonNullable<RegrelloMultiSelectProps<PartyTypeUnion>["createItemOptions"]>["renderCreateItem"]>
> = {
  dataTestId: DataTestIds.PARTY_SELECT_CREATE_BUTTON,
};

function useCreateItems({
  allowCreate,
  selectedTabId,
  updateSelection,
}: {
  allowCreate: RegrelloFormFieldPartySelectProps["allowCreate"];
  selectedTabId: PartySelectTabId;
  updateSelection: (newUsers: PartyTypeUnion[]) => void;
}) {
  const allowCreateUsers = allowCreate?.users === true;
  const allowCreateTeams = allowCreate?.teams === true;

  const { createUserOptions, addUserDialogElement } = useCreateUsers({
    allowCreateUsers,
    updateSelection,
  });
  const { createTeamOptions, addTeamDialogElement } = useCreateTeams({
    allowCreateTeams,
    updateSelection,
  });
  const { createRoleOptions, addRoleDialogElement } = useCreateRoles({
    updateSelection,
  });

  const memoizedCreateItemOptions = useMemo(() => {
    switch (selectedTabId) {
      case PartySelectTabId.ALL:
      case PartySelectTabId.USERS:
        return createUserOptions;
      case PartySelectTabId.TEAMS:
        return createTeamOptions;
      case PartySelectTabId.ROLES:
        return createRoleOptions;
      case PartySelectTabId.FIELDS:
        // Not possible to create fields from this component.
        return undefined;
      default:
        assertNever(selectedTabId);
    }
  }, [createTeamOptions, createRoleOptions, createUserOptions, selectedTabId]);

  return {
    addRoleDialogElement,
    addTeamDialogElement,
    addUserDialogElement,
    createItemOptions: memoizedCreateItemOptions,
  };
}

function useCreateUsers({
  allowCreateUsers,
  updateSelection,
}: {
  allowCreateUsers: boolean;
  updateSelection: (newUsers: PartyTypeUnion[]) => void;
}): {
  addUserDialogElement: React.ReactNode;
  createUserOptions: RegrelloMultiSelectProps<PartyTypeUnion>["createItemOptions"];
} {
  const { isOpen: isAddUserDialogOpen, close: closeAddUserDialog, open: openAddUserDialog } = useSimpleDialog();
  const [defaultUserValue, setDefaultUserValue] = useState<string>(EMPTY_STRING);

  if (!allowCreateUsers) {
    return {
      addUserDialogElement: null,
      createUserOptions: undefined,
    };
  }

  return {
    addUserDialogElement: (
      <AddUserDialog
        defaultValue={defaultUserValue}
        isOpen={isAddUserDialogOpen}
        onClose={closeAddUserDialog}
        onUsersAdded={(newUsers) => updateSelection(newUsers.map(PartyTypeUnion.user))}
      />
    ),
    createUserOptions: {
      onCreateItem: (query) => {
        setDefaultUserValue(query);
        openAddUserDialog();
      },
      renderCreateItem: () => {
        return { ...BASE_CREATE_ITEM_PROPS, text: t`Invite person` };
      },
    },
  };
}

function useCreateTeams({
  allowCreateTeams,
  updateSelection,
}: {
  allowCreateTeams: boolean;
  updateSelection: (newUsers: PartyTypeUnion[]) => void;
}): {
  addTeamDialogElement: React.ReactNode;
  createTeamOptions: RegrelloMultiSelectProps<PartyTypeUnion>["createItemOptions"];
} {
  const { isOpen: isAddTeamDialogOpen, close: closeAddTeamDialog, open: openAddTeamDialog } = useSimpleDialog();
  const [defaultTeamNameValue, setDefaultTeamNameValue] = useState<string>(EMPTY_STRING);

  if (!allowCreateTeams) {
    return {
      addTeamDialogElement: null,
      createTeamOptions: undefined,
    };
  }

  return {
    addTeamDialogElement: (
      <ConfigureTeamDialog
        defaultNameValue={defaultTeamNameValue}
        isOpen={isAddTeamDialogOpen}
        onClose={closeAddTeamDialog}
        onSubmit={(newTeam) => updateSelection([PartyTypeUnion.team(newTeam)])}
      />
    ),
    createTeamOptions: {
      onCreateItem: (query) => {
        setDefaultTeamNameValue(query);
        openAddTeamDialog();
      },
      renderCreateItem: () => {
        return { ...BASE_CREATE_ITEM_PROPS, text: t`Add team` };
      },
    },
  };
}

function useCreateRoles({
  updateSelection,
}: {
  updateSelection: (newRoles: PartyTypeUnion[]) => void;
}): {
  addRoleDialogElement: React.ReactNode;
  createRoleOptions: NonNullable<RegrelloMultiSelectProps<PartyTypeUnion>["createItemOptions"]>;
} {
  const dialogProps = useSimpleDialog();

  const onCreate = useCallback(
    (roles: RoleFields) => {
      updateSelection([roles].map(PartyTypeUnion.role));
    },
    [updateSelection],
  );

  return {
    addRoleDialogElement: <RegrelloAddRoleDialog {...dialogProps} onClose={dialogProps.close} onRoleAdded={onCreate} />,
    createRoleOptions: {
      onCreateItem: () => {
        dialogProps.open();
      },
      renderCreateItem: () => {
        return { ...BASE_CREATE_ITEM_PROPS, text: t`Create role` };
      },
    },
  };
}
