import { EMPTY_STRING, getKeys } from "@regrello/core-utils";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { ConditionOperator, TagFields, ViewFilterModifier } from "@regrello/graphql-api";

import {
  GetFilterOperators,
  RegrelloFilterDefinition_BooleanValue,
  RegrelloFilterDefinition_DateValue,
  RegrelloFilterDefinition_DocumentValue,
  RegrelloFilterDefinition_DocumentValue_FileType,
  RegrelloFilterDefinition_MultiSelectValue,
  RegrelloFilterDefinition_NumberValue,
  RegrelloFilterDefinition_SingleSelectValue,
  RegrelloFilterDefinition_StageStatusValue,
  RegrelloFilterDefinition_StringValue,
  RegrelloFilterDefinition_WorkflowTemplateValue,
  RegrelloFilterDefinitionUnion,
  RegrelloFilterValueType,
} from "./regrelloFilterV2Types";
import { FeatureFlagService } from "../../../../../services/FeatureFlagService";
import { RegrelloFormFieldFilterType } from "../../../../../types/FilterTypeUnion";
import { PartyTypeUnion } from "../../../../../utils/parties/PartyTypeUnion";
import { BlueprintUuidOption } from "../../../formFields/RegrelloFormFieldBlueprintUuidSelect";

import {
  ConditionAfter,
  ConditionBefore,
  ConditionContains,
  ConditionContainsAll,
  ConditionContainsAny,
  ConditionDocumentFilenamesContain,
  ConditionDocumentFileTypesContain,
  ConditionDoesNotContain,
  ConditionIs,
  ConditionIsAny,
  ConditionIsEmpty,
  ConditionIsEqual,
  ConditionIsExactly,
  ConditionIsGreaterThan,
  ConditionIsGreaterThanOrEqual,
  ConditionIsLessThan,
  ConditionIsLessThanOrEqual,
  ConditionIsNot,
  ConditionIsNotEmpty,
  ConditionIsNotEqual,
  ConditionOnOrAfter,
  ConditionOnOrBefore,
} from "@/strings";

// Internal
// ========
// These internal object ensure that we include all supported filter operators for each filter type.

const regrelloFilterOperators_BooleanValue: Record<GetFilterOperators<RegrelloFilterDefinition_BooleanValue>, true> = {
  [ConditionOperator.EQUALS]: true,
};

const regrelloFilterOperators_DateValue: Record<GetFilterOperators<RegrelloFilterDefinition_DateValue>, true> = {
  // [ConditionOperator.EMPTY]: true,
  [ConditionOperator.EQUALS]: true,
  // [ConditionOperator.GREATER_THAN_OR_EQUALS]: true,
  [ConditionOperator.GREATER_THAN]: true,
  // [ConditionOperator.LESS_THAN_OR_EQUALS]: true,
  [ConditionOperator.LESS_THAN]: true,
  // [ConditionOperator.NOT_EMPTY]: true,
  // [ConditionOperator.NOT_EQUALS]: true,
};

const regrelloFilterOperators_DocumentValue: Record<
  GetFilterOperators<RegrelloFilterDefinition_DocumentValue>,
  true
> = {
  // [ConditionOperator.CONTAINS_ALL_OF]: true,
  [ConditionOperator.CONTAINS_ALL_OF]: true,
  // [ConditionOperator.EMPTY]: true,
  // [ConditionOperator.NOT_EMPTY]: true,
};

const regrelloFilterOperators_MultiSelectValue: Record<
  GetFilterOperators<RegrelloFilterDefinition_MultiSelectValue<unknown>>,
  true | FeatureFlagKey
> = {
  [ConditionOperator.CONTAINS_ALL_OF]: true,
  [ConditionOperator.CONTAINS_ANY_OF]: true,
  [ConditionOperator.CONTAINS_NONE_OF]: true,
  // [ConditionOperator.EMPTY]: true,
  // [ConditionOperator.EQUALS]: true,
  // [ConditionOperator.NOT_EMPTY]: true,
};

const regrelloFilterOperators_NumberValue: Record<GetFilterOperators<RegrelloFilterDefinition_NumberValue>, true> = {
  // [ConditionOperator.EMPTY]: true,
  [ConditionOperator.EQUALS]: true,
  // [ConditionOperator.GREATER_THAN_OR_EQUALS]: true,
  [ConditionOperator.GREATER_THAN]: true,
  // [ConditionOperator.LESS_THAN_OR_EQUALS]: true,
  [ConditionOperator.LESS_THAN]: true,
  // [ConditionOperator.NOT_EMPTY]: true,
  // [ConditionOperator.NOT_EQUALS]: true,
};

const regrelloFilterOperators_SingleSelectValue: Record<
  GetFilterOperators<RegrelloFilterDefinition_SingleSelectValue<unknown>>,
  true | FeatureFlagKey
> = {
  [ConditionOperator.IS_ANY_OF]: true,
  [ConditionOperator.IS_NONE_OF]: true,
  // [ConditionOperator.CONTAINS_NONE_OF]: true,
  // [ConditionOperator.EMPTY]: true,
  // [ConditionOperator.EQUALS]: true,
  // [ConditionOperator.NOT_EMPTY]: true,
  // [ConditionOperator.NOT_EQUALS]: true,
};

const regrelloFilterOperators_StageStatus: Record<
  GetFilterOperators<RegrelloFilterDefinition_StageStatusValue<unknown>>,
  true | FeatureFlagKey
> = {
  [ConditionOperator.IS_ANY_OF]: true,
};

const regrelloFilterOperators_StringValue: Record<
  GetFilterOperators<RegrelloFilterDefinition_StringValue>,
  true | FeatureFlagKey
> = {
  // [ConditionOperator.CONTAINS_ALL_OF]: true,
  // [ConditionOperator.CONTAINS_NONE_OF]: true,
  // [ConditionOperator.EMPTY]: true,
  [ConditionOperator.EQUALS]: true,
  // [ConditionOperator.NOT_EMPTY]: true,
  [ConditionOperator.NOT_EQUALS]: true,
};

const regrelloFilterOperators_WorkflowTemplateValue: Record<
  GetFilterOperators<RegrelloFilterDefinition_WorkflowTemplateValue>,
  true | FeatureFlagKey
> = {
  // (hchen): Temporarily disable IS_ANY_OF until we fully support blueprints versioning to avoid
  // user confusion
  // [ConditionOperator.IS_ANY_OF]: true,
  [ConditionOperator.EQUALS]: true,
  [ConditionOperator.NOT_EQUALS]: true,
};

const regrelloDocumentFilterAvailableFileTypes: Record<RegrelloFilterDefinition_DocumentValue_FileType, true> = {
  image: true,
  text: true,
  doc: true,
  pdf: true,
  ppt: true,
  xls: true,
};

const getKeysWithFlagEnabled = <T extends Record<string, true | FeatureFlagKey>>(obj: T) => {
  return getKeys(obj).filter((key) => {
    const value = obj[key];
    return value === true || FeatureFlagService.isEnabled(value);
  });
};

// Exported
// ========

/**
 * A map from each known filter-value type to the array of operators that it supports.
 * (Example: Numbers support LESS_THAN, EQUALS, GREATER_THAN_OR_EQUALS).
 */
export function GetRegrelloAvailableFilterOperatorsByValueType(): Record<
  RegrelloFilterDefinitionUnion<unknown>["type"],
  ConditionOperator[]
> {
  return {
    boolean: getKeysWithFlagEnabled(regrelloFilterOperators_BooleanValue),
    date: getKeysWithFlagEnabled(regrelloFilterOperators_DateValue),
    document: getKeysWithFlagEnabled(regrelloFilterOperators_DocumentValue),
    multiSelect: getKeysWithFlagEnabled(regrelloFilterOperators_MultiSelectValue),
    number: getKeysWithFlagEnabled(regrelloFilterOperators_NumberValue),
    singleSelect: getKeysWithFlagEnabled(regrelloFilterOperators_SingleSelectValue),
    string: getKeysWithFlagEnabled(regrelloFilterOperators_StringValue),
    workflowTemplate: getKeysWithFlagEnabled(regrelloFilterOperators_WorkflowTemplateValue),
    stageStatus: getKeysWithFlagEnabled(regrelloFilterOperators_StageStatus),
  };
}

export const REGRELLO_DOCUMENT_FILTER_AVAILABLE_FILE_TYPES = getKeys(regrelloDocumentFilterAvailableFileTypes);

const NOT_SUPPORTED = "";

export const REGRELLO_FILTER_DISPLAY_STRINGS: Record<RegrelloFilterValueType, Record<ConditionOperator, string>> = {
  string: {
    BETWEEN: NOT_SUPPORTED,
    CONTAINS_ANY_OF: NOT_SUPPORTED,
    GREATER_THAN: NOT_SUPPORTED,
    GREATER_THAN_OR_EQUALS: NOT_SUPPORTED,
    LESS_THAN: NOT_SUPPORTED,
    LESS_THAN_OR_EQUALS: NOT_SUPPORTED,
    IS_ANY_OF: NOT_SUPPORTED,
    IS_NONE_OF: NOT_SUPPORTED,

    CONTAINS_ALL_OF: ConditionContains,
    CONTAINS_NONE_OF: ConditionDoesNotContain,
    EMPTY: ConditionIsEmpty,
    EQUALS: ConditionContains, // (clewis): Our backend for 'Equals' is implemented as substring match
    NOT_EQUALS: ConditionDoesNotContain, // (akager): Our backend for 'Not Equals' is implemented as substring match
    NOT_EMPTY: ConditionIsNotEmpty,
  },
  number: {
    BETWEEN: NOT_SUPPORTED,
    CONTAINS_ALL_OF: NOT_SUPPORTED,
    CONTAINS_ANY_OF: NOT_SUPPORTED,
    CONTAINS_NONE_OF: NOT_SUPPORTED,
    IS_ANY_OF: NOT_SUPPORTED,
    IS_NONE_OF: NOT_SUPPORTED,

    EMPTY: ConditionIsEmpty,
    EQUALS: ConditionIsEqual,
    GREATER_THAN: ConditionIsGreaterThan,
    GREATER_THAN_OR_EQUALS: ConditionIsGreaterThanOrEqual,
    LESS_THAN: ConditionIsLessThan,
    LESS_THAN_OR_EQUALS: ConditionIsLessThanOrEqual,
    NOT_EMPTY: ConditionIsNotEmpty,
    NOT_EQUALS: ConditionIsNotEqual,
  },
  boolean: {
    BETWEEN: NOT_SUPPORTED,
    CONTAINS_ALL_OF: NOT_SUPPORTED,
    CONTAINS_ANY_OF: NOT_SUPPORTED,
    CONTAINS_NONE_OF: NOT_SUPPORTED,
    EMPTY: NOT_SUPPORTED,
    GREATER_THAN: NOT_SUPPORTED,
    GREATER_THAN_OR_EQUALS: NOT_SUPPORTED,
    LESS_THAN: NOT_SUPPORTED,
    LESS_THAN_OR_EQUALS: NOT_SUPPORTED,
    NOT_EMPTY: NOT_SUPPORTED,
    NOT_EQUALS: NOT_SUPPORTED,
    IS_ANY_OF: NOT_SUPPORTED,
    IS_NONE_OF: NOT_SUPPORTED,

    EQUALS: ConditionIs,
  },
  date: {
    BETWEEN: NOT_SUPPORTED,
    CONTAINS_ALL_OF: NOT_SUPPORTED,
    CONTAINS_ANY_OF: NOT_SUPPORTED,
    CONTAINS_NONE_OF: NOT_SUPPORTED,
    IS_ANY_OF: NOT_SUPPORTED,
    IS_NONE_OF: NOT_SUPPORTED,

    EMPTY: ConditionIsEmpty,
    EQUALS: ConditionIs,
    GREATER_THAN: ConditionAfter,
    GREATER_THAN_OR_EQUALS: ConditionOnOrAfter,
    LESS_THAN: ConditionBefore,
    LESS_THAN_OR_EQUALS: ConditionOnOrBefore,
    NOT_EMPTY: ConditionIsNotEmpty,
    NOT_EQUALS: ConditionIsNot,
  },
  document: {
    BETWEEN: NOT_SUPPORTED,
    CONTAINS_NONE_OF: NOT_SUPPORTED,
    EQUALS: NOT_SUPPORTED,
    GREATER_THAN: NOT_SUPPORTED,
    GREATER_THAN_OR_EQUALS: NOT_SUPPORTED,
    LESS_THAN: NOT_SUPPORTED,
    LESS_THAN_OR_EQUALS: NOT_SUPPORTED,
    NOT_EQUALS: NOT_SUPPORTED,
    IS_ANY_OF: NOT_SUPPORTED,
    IS_NONE_OF: NOT_SUPPORTED,

    CONTAINS_ALL_OF: ConditionDocumentFilenamesContain,
    CONTAINS_ANY_OF: ConditionDocumentFileTypesContain,
    EMPTY: ConditionIsEmpty,
    NOT_EMPTY: ConditionIsNotEmpty,
  },
  singleSelect: {
    BETWEEN: NOT_SUPPORTED,
    CONTAINS_ALL_OF: NOT_SUPPORTED,
    GREATER_THAN: NOT_SUPPORTED,
    GREATER_THAN_OR_EQUALS: NOT_SUPPORTED,
    LESS_THAN: NOT_SUPPORTED,
    LESS_THAN_OR_EQUALS: NOT_SUPPORTED,
    CONTAINS_ANY_OF: NOT_SUPPORTED,
    CONTAINS_NONE_OF: NOT_SUPPORTED,

    IS_ANY_OF: ConditionIsAny,
    IS_NONE_OF: ConditionIsNot,
    EMPTY: ConditionIsEmpty,
    EQUALS: ConditionIs,
    NOT_EMPTY: ConditionIsNotEmpty,
    NOT_EQUALS: ConditionIsNot,
  },
  stageStatus: {
    BETWEEN: NOT_SUPPORTED,
    CONTAINS_ALL_OF: NOT_SUPPORTED,
    GREATER_THAN: NOT_SUPPORTED,
    GREATER_THAN_OR_EQUALS: NOT_SUPPORTED,
    LESS_THAN: NOT_SUPPORTED,
    LESS_THAN_OR_EQUALS: NOT_SUPPORTED,
    CONTAINS_NONE_OF: NOT_SUPPORTED,
    IS_NONE_OF: NOT_SUPPORTED,
    EMPTY: NOT_SUPPORTED,
    EQUALS: NOT_SUPPORTED,
    NOT_EMPTY: NOT_SUPPORTED,
    NOT_EQUALS: NOT_SUPPORTED,
    CONTAINS_ANY_OF: NOT_SUPPORTED,

    IS_ANY_OF: ConditionIsAny,
  },
  multiSelect: {
    BETWEEN: NOT_SUPPORTED,
    GREATER_THAN: NOT_SUPPORTED,
    GREATER_THAN_OR_EQUALS: NOT_SUPPORTED,
    LESS_THAN: NOT_SUPPORTED,
    LESS_THAN_OR_EQUALS: NOT_SUPPORTED,
    NOT_EQUALS: NOT_SUPPORTED,
    IS_ANY_OF: NOT_SUPPORTED,
    IS_NONE_OF: NOT_SUPPORTED,

    CONTAINS_ALL_OF: ConditionContainsAll,
    CONTAINS_ANY_OF: ConditionContainsAny,
    CONTAINS_NONE_OF: ConditionDoesNotContain,
    EMPTY: ConditionIsEmpty,
    EQUALS: ConditionIsExactly,
    NOT_EMPTY: ConditionIsNotEmpty,
  },
  workflowTemplate: {
    BETWEEN: NOT_SUPPORTED,
    CONTAINS_ALL_OF: NOT_SUPPORTED,
    CONTAINS_ANY_OF: NOT_SUPPORTED,
    CONTAINS_NONE_OF: NOT_SUPPORTED,
    EMPTY: NOT_SUPPORTED,
    GREATER_THAN: NOT_SUPPORTED,
    GREATER_THAN_OR_EQUALS: NOT_SUPPORTED,
    IS_NONE_OF: NOT_SUPPORTED,
    LESS_THAN: NOT_SUPPORTED,
    LESS_THAN_OR_EQUALS: NOT_SUPPORTED,
    NOT_EMPTY: NOT_SUPPORTED,

    // (hchen): Temporarily disable IS_ANY_OF until we fully support blueprints versioning to avoid
    // user confusion
    IS_ANY_OF: NOT_SUPPORTED,
    NOT_EQUALS: ConditionIsNot,
    EQUALS: ConditionIs,
  },
};

export function getRegrelloDefaultFilterDefinitionBooleanValue(): RegrelloFilterDefinition_BooleanValue {
  return {
    type: "boolean",
    filter: {
      operator: ConditionOperator.EQUALS,
      value: true,
    },
  };
}

export function getRegrelloDefaultFilterDefinitionDateValue(): RegrelloFilterDefinition_DateValue {
  return {
    type: "date",
    filter: {
      operator: ConditionOperator.EQUALS,
      value: { type: "exactDate", dateString: null },
    },
  };
}

export function getRegrelloDefaultFilterDefinitionDocumentValue(): RegrelloFilterDefinition_DocumentValue {
  return {
    type: "document",
    filter: {
      operator: ConditionOperator.CONTAINS_ALL_OF,
      value: "",
    },
  };
}

export function getRegrelloDefaultFilterDefinitionMultiSelectValue<T>(
  options: RegrelloFilterDefinition_MultiSelectValue<T>["options"],
): RegrelloFilterDefinition_MultiSelectValue<T> {
  return {
    type: "multiSelect",
    options,
    filter: {
      operator: ConditionOperator.CONTAINS_ANY_OF,
      values: [] as T[],
    },
  };
}

export function getRegrelloDefaultFilterDefinitionNumberValue(): RegrelloFilterDefinition_NumberValue {
  return {
    type: "number",
    filter: {
      operator: ConditionOperator.EQUALS,
      value: 0,
    },
  };
}

export function getRegrelloDefaultFilterDefinitionSingleSelectValue<T>(
  options: RegrelloFilterDefinition_SingleSelectValue<T>["options"],
): RegrelloFilterDefinition_SingleSelectValue<T> {
  return {
    type: "singleSelect",
    options,
    filter: {
      operator: ConditionOperator.IS_ANY_OF,
      values: [],
    },
  };
}

export function getRegrelloDefaultFilterDefinitionStageStatusValue<T>(
  options: RegrelloFilterDefinition_StageStatusValue<T>["options"],
): RegrelloFilterDefinition_StageStatusValue<T> {
  return {
    type: "stageStatus",
    options,
    filter: {
      operator: ConditionOperator.IS_ANY_OF,
      values: [],
    },
  };
}

export function getRegrelloDefaultFilterDefinitionStringValue(): RegrelloFilterDefinition_StringValue {
  return {
    type: "string",
    filter: {
      operator: ConditionOperator.EQUALS,
      value: "",
    },
  };
}

export function getRegrelloFilterDefinitionBooleanValue(value: boolean): RegrelloFilterDefinition_BooleanValue {
  return {
    type: "boolean",
    filter: {
      operator: ConditionOperator.EQUALS,
      value,
    },
  };
}

export function getRegrelloFilterDefinitionNumberValue(
  operator: ConditionOperator,
  value: number,
): RegrelloFilterDefinition_NumberValue | undefined {
  switch (operator) {
    case ConditionOperator.EQUALS:
    case ConditionOperator.GREATER_THAN:
    case ConditionOperator.LESS_THAN:
      return {
        type: "number",
        filter: {
          operator,
          value,
        },
      };
    default:
      console.warn("Unrecognized operator for number filter:", operator);
      return undefined;
  }
}

export function getRegrelloFilterDefinitionDateValue(
  operator: ConditionOperator,
  value: string | null | undefined,
  values: string[] | null | undefined,
): RegrelloFilterDefinition_DateValue | undefined {
  // (elle): The EQUAL date filter was converted to use the BETWEEN operator before sending an API request to the
  // backend for accurate time zone comparisons. Now, converting it back to EQUALS to ensure correct display in the UI.
  // This is achieved by using the minimum UTC date from the BETWEEN range, adjusted to the local time zone.
  if (operator === ConditionOperator.BETWEEN) {
    if (values?.[0] == null) {
      console.warn("Date values must not be null for operator:", operator);
      return undefined;
    }

    // Convert the min UTC date to local date
    const minUTCDate = new Date(values[0]);
    const localDate = minUTCDate.toLocaleDateString();

    return {
      type: RegrelloFormFieldFilterType.DATE,
      filter: {
        operator: ConditionOperator.EQUALS,
        value: {
          type: "exactDate",
          dateString: localDate,
        },
      },
    };
  }

  if (operator === ConditionOperator.GREATER_THAN || operator === ConditionOperator.LESS_THAN) {
    if (value == null) {
      console.warn("Date value must not be null for date filter:", operator);
      return undefined;
    }

    return {
      type: RegrelloFormFieldFilterType.DATE,
      filter: {
        operator,
        value: {
          type: "exactDate",
          dateString: value,
        },
      },
    };
  }

  console.warn("Unrecognized operator for date filter:", operator);
  return undefined;
}

export function getRegrelloFilterDefinitionDocumentValue(
  value: string,
): RegrelloFilterDefinition_DocumentValue | undefined {
  return {
    type: "document",
    filter: {
      operator: ConditionOperator.CONTAINS_ALL_OF,
      value: value,
    },
  };
}

export function getRegrelloFilterDefinitionStringValue(
  operator: ConditionOperator,
  value: string,
): RegrelloFilterDefinition_StringValue | undefined {
  switch (operator) {
    case ConditionOperator.EQUALS:
    case ConditionOperator.NOT_EQUALS:
      return {
        type: "string",
        filter: {
          operator,
          value,
        },
      };
    default:
      console.warn("Unrecognized operator for string filter:", operator);
      return undefined;
  }
}

export function getRegrelloFilterDefinitionPartyMultiSelectValue(
  operator: ConditionOperator,
  values: PartyTypeUnion[],
): RegrelloFilterDefinition_MultiSelectValue<unknown> | undefined {
  switch (operator) {
    case ConditionOperator.CONTAINS_ALL_OF:
    case ConditionOperator.CONTAINS_ANY_OF:
    case ConditionOperator.CONTAINS_NONE_OF:
      return {
        type: "multiSelect",
        options: {
          type: "party",
        },
        filter: {
          operator: operator,
          values: values,
        },
      };
    default:
      console.warn("Unrecognized operator for party multi-select filter:", operator);
      return undefined;
  }
}

export function getRegrelloFilterDefinitionPartySingleSelectValue(
  operator: ConditionOperator,
  values: PartyTypeUnion[],
): RegrelloFilterDefinition_SingleSelectValue<PartyTypeUnion> | undefined {
  switch (operator) {
    case ConditionOperator.IS_ANY_OF:
    case ConditionOperator.IS_NONE_OF:
      return {
        type: "singleSelect",
        options: {
          type: "party",
        },
        filter: {
          operator: operator,
          values: values,
        },
      };
    default:
      console.warn("Unrecognized operator for party single-select filter:", operator);
      return undefined;
  }
}

export function getRegrelloFilterDefinitionTagSelectValue(
  operator: ConditionOperator,
  values: TagFields[],
): RegrelloFilterDefinition_MultiSelectValue<unknown> | undefined {
  switch (operator) {
    case ConditionOperator.CONTAINS_ALL_OF:
    case ConditionOperator.CONTAINS_ANY_OF:
    case ConditionOperator.CONTAINS_NONE_OF:
      return {
        type: "multiSelect",
        options: {
          type: "tag",
        },
        filter: {
          operator: operator,
          values: values,
        },
      };
    default:
      console.warn("Unrecognized operator for tag filter:", operator);
      return undefined;
  }
}

export function getRegrelloFilterDefinitionBlueprintUuidSingleSelectValue(
  operator: ConditionOperator,
  values: BlueprintUuidOption[],
  modifier?: ViewFilterModifier,
): RegrelloFilterDefinition_SingleSelectValue<BlueprintUuidOption> | undefined {
  switch (operator) {
    case ConditionOperator.IS_ANY_OF:
    case ConditionOperator.IS_NONE_OF:
      return {
        type: "singleSelect",
        options: {
          type: "blueprintUuid",
        },
        filter: {
          operator: operator,
          values: values,
          modifier: modifier,
        },
      };
    default:
      console.warn("Unrecognized operator for blueprint UUID single-select filter:", operator);
      return undefined;
  }
}

export function getRegrelloFilterDefinitionWorkflowTemplateValue(
  operator: ConditionOperator,
  value: string | undefined,
  options: RegrelloFilterDefinition_WorkflowTemplateValue["options"],
): RegrelloFilterDefinition_WorkflowTemplateValue | undefined {
  switch (operator) {
    case ConditionOperator.EQUALS:
    case ConditionOperator.NOT_EQUALS:
      return {
        type: "workflowTemplate",
        options: options,
        filter: {
          operator: operator,
          value: value ?? EMPTY_STRING,
        },
      };
    default:
      console.warn("Unrecognized operator for workflow template filter:", operator);
      return undefined;
  }
}

export function getRegrelloFilterDefinitionMultiSelectValue(
  operator: ConditionOperator,
  values: string[],
  allowedValues: Array<{
    id: number;
    label: string;
    value: string;
  }>,
): RegrelloFilterDefinition_MultiSelectValue<unknown> | undefined {
  switch (operator) {
    case ConditionOperator.CONTAINS_ALL_OF:
    case ConditionOperator.CONTAINS_NONE_OF:
    case ConditionOperator.CONTAINS_ANY_OF:
      return {
        type: "multiSelect",
        options: {
          type: "default",
          values: allowedValues,
        },
        filter: {
          operator: operator,
          values: values.map((value, index) => {
            return { id: index, label: value, value: value };
          }),
        },
      };
    default:
      console.warn("Unrecognized operator for multiselect filter:", operator);
      return undefined;
  }
}

export function getRegrelloFilterDefinitionSingleSelectValue(
  operator: ConditionOperator,
  values: string[],
  allowedValues: Array<{
    id: number;
    label: string;
    value: string;
  }>,
): RegrelloFilterDefinition_SingleSelectValue<unknown> | undefined {
  switch (operator) {
    case ConditionOperator.IS_ANY_OF:
    case ConditionOperator.IS_NONE_OF:
      return {
        type: "singleSelect",
        options: {
          type: "default",
          values: allowedValues,
        },
        filter: {
          operator: operator,
          values: values.map((value, index) => {
            return { id: index, label: value, value: value };
          }),
        },
      };
    default:
      console.warn("Unrecognized operator for single select filter:", operator);
      return undefined;
  }
}

export function getRegrelloFilterDefinitionStageStatusValue(
  operator: ConditionOperator,
  values: string[],
  allowedValues: Array<{
    id: number;
    label: string;
    value: string;
  }>,
): RegrelloFilterDefinition_StageStatusValue<unknown> | undefined {
  switch (operator) {
    case ConditionOperator.IS_ANY_OF:
      return {
        type: "stageStatus",
        options: {
          type: "default",
          values: allowedValues,
        },
        filter: {
          operator: operator,
          values: values.map((value, index) => {
            return { id: index, label: value, value: value };
          }),
        },
      };
    default:
      console.warn("Unrecognized operator for single select filter:", operator);
      return undefined;
  }
}
