import { clsx, WithClassName, WithDataTestId } from "@regrello/core-utils";
import React from "react";

import { RegrelloIcon, RegrelloIconName } from "../../../atoms/icons/RegrelloIcon";
import { RegrelloTypography } from "../../../atoms/typography/RegrelloTypography";
import { RegrelloSize } from "../../../utils/enums/RegrelloSize";

export interface RegrelloDataGridColumnHeaderDisplayProps extends WithClassName, WithDataTestId {
  /** Icon or `RegrelloIconName` to render in the column header. Rendered before `displayName`. */
  displayIcon?: RegrelloIconName | JSX.Element;

  /** Display name to render in the header. Rendered after `displayIcon`. */
  displayName?: React.ReactNode;
}

/**
 * Standard display content for a RegrelloDataGrid header. Renders the display name and/or
 * displayIcon if provided.
 */
export const RegrelloDataGridColumnHeaderDisplay = React.memo<RegrelloDataGridColumnHeaderDisplayProps>(
  function RegrelloDataGridColumnHeaderDisplayFn({ className, dataTestId, displayIcon, displayName }) {
    return (
      <div className={clsx("flex items-center min-w-0 gap-1", className)} data-testid={dataTestId}>
        {displayIcon != null && typeof displayIcon === "string" && (
          <div className="flex items-center">
            <RegrelloIcon iconName={displayIcon} intent="neutral" size={RegrelloSize.SMALL} />
          </div>
        )}
        {displayIcon != null && typeof displayIcon !== "string" && (
          <div className="flex items-center">{displayIcon}</div>
        )}
        {displayName != null && (
          <RegrelloTypography noWrap={true} variant="body-xs" weight="semi-bold">
            {displayName}
          </RegrelloTypography>
        )}
      </div>
    );
  },
);
