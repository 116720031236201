/* eslint-disable @typescript-eslint/no-unused-vars */

// (dosipiuk): Disabled due to mixed JSX and const exports. Strings are not updated frequently so
// we can live with it for now.
/* eslint-disable react-refresh/only-export-components */
// (clewis): Strings from the whole app. Eventually, we should port these to i18n.
//
// The strategy with naming should be to make the string as general as possible by default (e.g.
// const Home = "Home") so that it can be discovered and reused easily, but to segment into multiple
// namespaced constants if you need need to change the string differently in the various places it's
// used (e.g., const PageTitleHome = "Home", const TabHome = "My home").
//
// If you need to import a string into a file that already includes an other variable having the
// same name, just add a '-String' suffix to the imported string name: import { Foo as FooString }.
//

import React, { ReactNode } from "react";
export const ActionItem = "Task";
export const ActionItemDeleted = (name: string) => `Task ${name} deleted`;
export const ActionItemProblemResolved = (name: string) => `Task ${name} problem resolved`;
export const Added = "Added";
export const AddedBy = "Added by";
export const AddedPrefix = (dateString: string) => `Added: ${dateString}`;
export const AddOptionToRegrello = ({ name }: { name: JSX.Element }) => <span>Add &apos;{name}&apos; to Regrello</span>;
export const AddFieldOptionToRegrello = ({ name }: { name: JSX.Element }) => (
  <span>Add field &apos;{name}&apos; to Regrello</span>
);
export const AddOptionToRegrelloNotAllowed = ({ name }: { name: JSX.Element }) => (
  <span>Please contact an admin if you would like to add {name}</span>
);
export const AddIndustryOption = ({ name }: { name: JSX.Element }) => <span>Use &apos;{name}&apos; for industry</span>;
export const ApprovalActionItemDeleted = (name: string) => `Approval for task ${name} deleted`;
export const All = "All";
export const Apply = "Apply";
export const ApprovalPrefixedName = (name: string) => `Approval: ${name}`;
export const ArrayToCommaSeparatedList = (strings: string[], lastStringSeparator: "and" | "or") => {
  const len = strings.length;
  const stringsLocal = [...strings];
  const maybeLastStr = stringsLocal.pop();
  const joinedStrs = stringsLocal.join(", ");
  return `${joinedStrs}${len > 2 ? `, ${lastStringSeparator} ` : len > 1 ? ` ${lastStringSeparator} ` : ""}${maybeLastStr}`;
};
export const AssignedBy = "Assigned by";
export const AssignedTo = "Assigned to";
export const AsteriskSymbol = "*";
export const Blueprints = "Blueprints";
export const OwnedByName = (name: string) => `Owned by ${name}`;
export const CompanyTemplateAlreadyExistsDialogText = (
  templateName: string,
  templateLink: JSX.Element,
  tenantDisplayName: string,
) => (
  <span>
    A blueprint named {templateName} already exists in {tenantDisplayName}&apos;s blueprints. Please rename the
    blueprint before sharing. Existing blueprint: {templateLink}
  </span>
);
export const CompanyTemplateAlreadyExistsDialogTextDefault = (templateName: string, templateLink: JSX.Element) => (
  <span>
    A blueprint with the name {templateName} already exists in Company Blueprints. Please rename the blueprint before
    sharing. Existing blueprint: {templateLink}
  </span>
);
export const PublishedBlueprintAlreadyExistsDialogTextDefault = (templateName: string, templateLink: JSX.Element) => (
  <span>
    A blueprint with the name {templateName} already exists in Published Blueprints. Please rename the blueprint before
    sharing. Existing blueprint: {templateLink}
  </span>
);
export const Cancel = "Cancel";
export const CanInvite = "Can invite";
export const CheckYourEmailForInviteInstructionsListItem1 = () => (
  <span>There should be a new invite from us in your inbox.</span>
);
export const CheckYourEmailForInviteInstructionsListItem2 = () => (
  <span>Don’t see it there? Check your Junk folder.</span>
);

export const CheckYourEmailForInviteInstructionsListItem3 = () => (
  <span>
    Click the <strong>Accept invite</strong> link.
  </span>
);
export const CheckYourEmailForInviteInstructionsListItem4 = () => <span>Create your password.</span>;
export const CheckYourEmailForInviteInstructionsListItem5 = () => (
  <span>That’s it. You and your customer can now collaborate with Regrello.</span>
);
export const Close = "Close";
export const Created = "Created";
export const CreatedBy = "Created by";
export const CreateNewFieldEmpty = "Add field";
export const CsvFile = "CSV file";
export const CsvFieldNameWithOptionalPrefix = (fieldName: string) => `(Optional) ${fieldName}`;
export const CompletedTasks = "Completed tasks";
export const CopyrightTitleCased = () => `©${new Date().getFullYear()} Regrello Group`;
export const ContactSupportForAssistance = (link: JSX.Element) => <>Please contact {link} for assistance</>;
export const DateAdded = "Date added";
export const Delete = "Delete";
export const DeleteUserConfirmation = (name: string) => `${name} will be removed from associated workflows and tasks.`;
export const DeletingWorkflows = (n: number) => (
  <span>
    Deleting <strong>{n}</strong> {n === 1 ? "workflow" : "workflows"}...
  </span>
);
export const Document = "Document";
export const DocumentDeleted = (name: string) => `Document ${name} deleted`;
export const DueDate = "Due date";
export const DuplicateTaskName = (name: string) => `${name} (Duplicate)`;
export const DurationInDays = (number: number) => `${number} ${number === 1 ? "day" : "days"}`;
export const DurationInWeeks = (number: number) => `${number} ${number === 1 ? "week" : "weeks"}`;
export const EndDateTooltipText = "The end date of a workflow is estimated if it is in progress.";
export const ActualEndDateTooltipText = "The date the workflow finished on, if applicable.";
export const EstimatedEndDateTooltipText = "Estimated end date will adjust as the workflow runs.";
export const PlannedEndDateTooltipText = "The original end date calculated when the workflow started.";
export const ConditionalPlannedEndDateTooltipText =
  "The end date calculated when the workflow started (and recalculated when stages are skipped).";
export const EndedOnDate = (date: string) => (
  <>
    Ended on <strong>{date}</strong>
  </>
);
export const ErrorMessage = "Error";
// TODO: This should probably say something different but I'm not sure if there is any other suggestion we can make to the user to fix this issue.
export const Problem = "Problem";
export const Problems = "Problems";
export const FieldOnlyAcceptOneDocument = (name: string | undefined) =>
  `${name ?? "This field"} only accepts one document`;
export const FieldOnlyAcceptNumberOfDocuments = (name: string | undefined, maxCount: number) =>
  `${name ?? "This field"} only accepts ${maxCount} document${maxCount !== 1 ? "s" : ""}`;
export const FieldCreated = (name: string) => `Field ${name} created`;
export const FieldDeleted = (name: string) => `Field ${name} deleted`;
export const FieldInstanceStageAndActionItemSource = (stageName: string, taskName: string) =>
  `stage: ${stageName} > task: ${taskName}`;
export const FieldsSelected = (count: number) => (
  <>
    {count} field{count === 1 ? "" : "s"} selected
  </>
);
export const FieldDeleteDialogTextStartConditions = (name: string) => (
  <span>
    Field <strong>{name}</strong> might be used in tasks, workflows, or stage starting conditions.
  </span>
);
export const CopiedToClipboard = (what: string) => `The ${what} has been copied to the clipboard`;
export const External = "External";
export const FieldUpdated = (name: string) => `Field ${name} updated`;
export const FileName = "File name";
export const From = "From";
export const Hyphen = "-";
export const Internal = "Internal";
export const LastActive = "Last active";
export const LastUpdated = "Last updated";
export const Link = "Link";
export const LoadingEllipses = "Loading...";
export const LinkCopied = CopiedToClipboard("link");
export const Members = "Members";
export const Messages = "Messages";
export const NMore = (n: number) => `${n} more`;
export const Name = "Name";
export const OnTrack = "On track";
export const OnTrackTasks = "On track tasks";
export const OpenActionItems = "Open tasks";
export const Overdue = "Overdue";
export const OverdueTasks = "Overdue tasks";
export const OwnedWorkflows = "Owned workflows";
export const Owner = "Owner";
export const OwnerPrefix = (ownerName: string) => `Owner: ${ownerName}`;
export const ProblemTasks = "Problem tasks";
export const RoleDeleted = (name: string) => `Role ${name} deleted`;
export const StatusPrefix = (status: string) => `Status: ${status}`;
export const TaskPrefix = (taskName: string) => `Task: ${taskName}`;
export const TypePrefix = (type: string) => `Type: ${type}`;
export const ParentTag = "Parent tag";
export const PausedOnDate = (date: string) => (
  <>
    Paused on <strong>{date}</strong>
  </>
);
export const Pending = "Pending";
export const PeopleAssigned = "People assigned";
export const PleaseContactLinkForAssistance = (link: JSX.Element) => <>Please contact {link} for assistance.</>;
export const Removed = "Removed";
export const NResults = (n: number) => `${n} Results`;
export const RestApi = "Rest API";
export const ResendEmailInviteText = (
  <span>
    You must click/tap <strong>Accept invite</strong> in your email invite in order to set your password.
  </span>
);
export const ResendEmailInviteTextSupplierMode = (
  <span>
    Been assigned tasks in Regrello? If you would like to sign in to complete your work, please click send email invite.
    You must click/tap <strong>Accept invite</strong> in your email invite in order to create your account password.
  </span>
);
export const WorkflowPrefix = (workflowName: string) => `Workflow: ${workflowName}`;
export const RequiresActionBy = (date: React.ReactChild) => <span>Requires action by {date}</span>;
export const RequiresActionByString = (date: string) => `Requires action by ${date}`;
export const Roles = "Roles";
export const SelectFrom = (isMultiselectEnabled: boolean, suffix?: string) =>
  `Select ${isMultiselectEnabled ? "one or more" : "one"} from ${suffix}`;
export const Snowflake = "Snowflake";
export const StageReevaluateDialogText =
  "Stages leveraging the updated fields in their starting conditions will be reevaluated and may start.";
export const Status = "Status";
export const StepOf = (current: number, total: number) => `Step ${current} of ${total}`;
export const SyncPageTitle = (objectName: string) => `Sync log - ${objectName || "unknown"}`;
export const Tags = "Tags";
export const TagCreated = (tagName: string) => `Tag ${tagName} created`;
export const TagDeleted = (tagName: string) => `Tag ${tagName} deleted`;
export const TagUpdated = (tagName: string) => `Tag ${tagName} updated`;
export const Task = "Task";
export const TeamAdmin = "Team admin";
export const Team = "Team";
export const Type = "Type";
export const Unread = "Unread";
export const Updated = "Updated";
export const UpdatedPrefix = (dateString: string) => `Updated: ${dateString}`;
export const UploadDate = "Upload date";
export const ViewData = "View data";
export const ViewAllSelectOptions = "View all select options";
export const VerificationRequiredSentInstructionBulletOne = () => (
  <span>
    Check your inbox for the email with the subject <strong>Password reset</strong>
  </span>
);
export const WorkspacePermission = "Workspace permission";
// Task status
export const Approvers = "Approvers";
export const Completed = "Completed";
export const Ended = "Ended";
// Stage status
// Task dialog
export const Action = "Action";
// Nonverified Page
// User access levels
// Tooltip
// Search
export const NoResultsFor = (str: string) => `No results found for "${str}"`;
// Detail View
// Problems
// Message threads
export const AuditHistoryAutomationResponseItemTitle = (n: number) => `Response #${n}:`;
export const AuditHistoryApprovalSubmission = (partyName: ReactNode) => (
  <>{partyName} submitted this task for approval</>
);
export const AuditHistoryFieldChanged = (partyName: ReactNode, fieldName: ReactNode) => (
  <>
    {partyName} changed the <strong>{fieldName}</strong>
  </>
);
export const AuditHistoryFieldChangedNoBold = (partyName: ReactNode, fieldName: ReactNode) => (
  <>
    {partyName} changed the {fieldName}
  </>
);
export const AuditHistoryDueDateAdjustedAhead = (
  <>Due date was pulled in because the workflow was running ahead of schedule.</>
);
export const AuditHistoryDueDateAdjustedBehind = (
  <span>Due date was pushed out because the workflow was running behind schedule.</span>
);
export const AuditHistoryCustomFieldChecked = (partyName: ReactNode, fieldName: ReactNode) => (
  <>
    {partyName} checked <strong>{fieldName}</strong>
  </>
);
export const AuditHistoryCustomFieldUnchecked = (partyName: ReactNode, fieldName: ReactNode) => (
  <>
    {partyName} unchecked <strong>{fieldName}</strong>
  </>
);
export const AuditHistoryFromDateToDate = (dateFrom: ReactNode, dateTo: ReactNode) => (
  <span>
    From <strong>{dateFrom}</strong> to <strong>{dateTo}</strong>
  </span>
);
export const AuditHistoryFromDateToDateTimezone = (timezone: ReactNode) => (
  <span>Assignees determined to be in {timezone}</span>
);
export const AuditHistoryFromDateToDateNoBold = (dateFrom: ReactNode, dateTo: ReactNode) => (
  <span>
    From {dateFrom} to {dateTo}
  </span>
);
export const AuditHistoryPlannedStartDate = (plannedStartDate: ReactNode) => (
  <>Planned start date: {plannedStartDate}</>
);
export const AuditHistoryPlannedDueDate = (plannedDueDate: ReactNode) => <>Planned due date: {plannedDueDate}</>;

export const AuditHistoryDelegation = (partyName: ReactNode) => <>{partyName} delegated this task</>;
export const AuditHistoryDelegationIsOutOfOffice = (partyName: ReactNode) => <>{partyName} is out of office</>;
export const AuditHistoryDelegationStarting = (dateFrom: ReactNode) => (
  <>
    Starting <strong>{dateFrom}</strong>
  </>
);
export const AuditHistoryStatusChange = (partyName: ReactNode) => <>{partyName} changed task status</>;
export const AuditHistoryStatusChangeAcknowledgedV2 = (partyName: ReactNode) => <>{partyName} acknowledged this task</>;
export const AuditHistoryStatusChangeApprovedV2 = (partyName: ReactNode) => <>Task approved by {partyName}</>;
export const AuditHistoryStatusChangeCompletedV2 = (partyName: ReactNode) => <>{partyName} completed this task</>;
export const AuditHistoryStatusChangeLinkedWorkflowCompleted = <>Linked workflow completed</>;
export const AuditHistoryStatusChangeStartedV2 = <>Task started</>;
export const AuditHistoryStatusChangeLinkedWorkflowStarted = <>Linked workflow started</>;
export const AuditHistoryStatusChangeRejectedV2 = (partyName: ReactNode) => <>Task rejected by {partyName}</>;
export const AuditHistoryStatusChangeRestartedBy = (partyName: ReactNode) => <>Task restarted by {partyName}</>;
export const AuditHistoryStatusChangeLinkedWorkflowRestarted = (
  <>Linked workflow previously completed now in progress</>
);
export const AuditHistoryStatusProblemReported = (partyName: ReactNode) => <>{partyName} reported a problem</>;
export const AuditHistoryStatusProblemResolved = (partyName: ReactNode) => <>{partyName} resolved a problem</>;
export const AuditHistoryStatusChangeDocusignTaskEnvelopeVoided = <>Envelope voided</>;
export const AuditHistoryStatusTaskReset = <>Task reset</>;

export const AuditHistoryRejectedMentioningAnotherTask = (taskLink: ReactNode) => <>rejected the task {taskLink}</>;
export const AuditHistoryAssigneesUpdatedV2 = (partyName: JSX.Element) => <>{partyName} updated the task assignees</>;
export const AuditHistoryLinkedWorkflowOwnerUpdated = (partyName: JSX.Element) => (
  <>{partyName} updated the linked workflow owner</>
);
export const AuditHistoryLinkedWorkflowInvalidOwnerFieldValue = (partyName: JSX.Element) => (
  <span>{partyName} has been made the workflow owner, since an external person cannot own a workflow</span>
);
export const AuditHistoryLinkedWorkflowInvalidOwnerFieldValueUpdate = (
  <span>The workflow owner has not updated, since an external person cannot own a workflow</span>
);
export const AuditHistoryAutomationResponseFailureMessage = (statusCode: string) =>
  `HTTP request sent to external system but failed with status code ${statusCode}`;
export const AuditHistoryAutomationResponseSuccessMessage = "HTTP request sent successfully to external system";
export const AuditHistoryCcsUpdatedV2 = (partyName: ReactNode) => (
  <>{partyName} updated the Cc&apos;d users on the task</>
);

export const AuditHistoryDescriptionChangedMessage = (partyName: ReactNode) => <>{partyName} changed the description</>;

export const AuditHistoryDocusignApproved = (partyName: ReactNode) => <>{partyName} approved the document</>;
export const AuditHistoryDocusignDeclined = (partyName: ReactNode) => <>{partyName} declined the document</>;
export const AuditHistoryDocusignSigned = (partyName: ReactNode) => <>{partyName} signed the document</>;

export const AuditHistoryDocusignSentForSignature = (partyName: ReactNode) => (
  <>Document sent for signature to {partyName}</>
);
export const AuditHistoryDraftEdit = (partyName: ReactNode, numberOfFields: number) => (
  <>
    {partyName} edited {numberOfFields} field{numberOfFields === 1 ? "" : "s"}
  </>
);

export const AuditHistoryDocusignReassignedV2 = (partyName: ReactNode, newPartyName: ReactNode) => (
  <>
    Document reassigned by {partyName} to {newPartyName}
  </>
);

export const AuditHistoryWorkflowPausedV2 = (partyName: ReactNode) => <>{partyName} paused this workflow</>;
export const AuditHistoryLinkedWorkflowPaused = (partyName: ReactNode) => <>{partyName} paused the linked workflow</>;

export const AuditHistoryWorkflowEndedV2 = (partyName: ReactNode) => <>{partyName} ended this workflow</>;
export const AuditHistoryLinkedWorkflowEnded = (partyName: ReactNode) => <>{partyName} ended the linked workflow</>;
export const AuditHistoryWorkflowResumedV2 = (partyName: ReactNode) => <>{partyName} resumed this workflow</>;
export const AuditHistoryLinkedWorkflowResumed = (partyName: ReactNode) => <>{partyName} resumed the linked workflow</>;

export const AuditHistoryFileNameError = ({
  taskName,
  fieldName = "it",
  isWorkflow = false,
}: {
  fieldName: React.ReactNode;
  taskName: React.ReactNode;
  isWorkflow: boolean;
}) => (
  <>
    Sharepoint does not allow filenames containing spaces or special characters such as <b>* : &lt; &gt; ? / \\ |</b> or
    any filename beginning with <b>~$</b>. To save this file,{" "}
    {isWorkflow ? "change workflow field" : <>restart the {taskName} task containing</>} {fieldName} and re upload the
    file after renaming it. Afterwards, resolve the problem or restart the automated task.
  </>
);
export const AuditHistoryFileUploadError = ({
  fileName,
  taskName,
  fieldName = "it",
  isWorkflow = false,
}: {
  fileName: React.ReactNode;
  fieldName: React.ReactNode;
  taskName: React.ReactNode;
  isWorkflow: boolean;
}) => (
  <>
    {fileName} could not be downloaded since it is a non-public or invalid link. To save this file,{" "}
    {isWorkflow ? "change workflow field" : <>restart the {taskName} task containing</>} {fieldName} and attach a file.
    Afterwards, resolve the problem or restart the automated task.
  </>
);
export const AuditHistoryUpdatedVia = (args: { field: React.ReactNode; trigger: string }) => (
  <>
    {args.field || "Field"} <span>was updated via {args.trigger}</span>
  </>
);
// Network errors
// Forms
export const FormBulkEmailSuccess = (numSucceeded: number, createdUsers: string) =>
  `Successfully created the following ${numSucceeded === 1 ? "user" : "users"}: ${createdUsers}. `;
export const FormBulkEmailFailure = (numFailed: number) =>
  `Failed to create ${numFailed} ${numFailed === 1 ? "user" : "users"}. `;
export const FormBulkEmailSuccessAndFailure = (numFailed: number, numSucceeded: number, createdUsers: string) =>
  `Successfully created the following ${
    numSucceeded === 1 ? "user" : "users"
  }: ${createdUsers}. Failed to create ${numFailed} other ${numFailed === 1 ? "user" : "users"}`;
export const FormErrorAfterDate = (formattedDateString: string) => `Must be after ${formattedDateString}`;
export const FormErrorBeforeDate = (formattedDateString: string) => `Must be before ${formattedDateString}`;
export const FormErrorIsRequired = "This field is required";
export const FormErrorEmailInvalid = "Email is invalid";
export const FormErrorBulkEmailInvalid = "Email(s) are invalid";
export const FormErrorFileTypeInvalid = (acceptedTypes: string[]) =>
  `Only ${acceptedTypes} ${acceptedTypes.length === 1 ? "is" : "are"} accepted.`;
export const FormErrorGreaterThanOrEqualToLowerBound = (lowerBound: number) => `Must be at least ${lowerBound}`;
export const FormErrorLessThanOrEqualToUpperBound = (upperBound: number) =>
  `Must be less than or equal to ${upperBound}`;
export const FormErrorMaxLength = (maxLength: number) => `Must be at most ${maxLength} characters`;
export const FormErrorMinLength = (minLength: number) => `Must be at least ${minLength} characters`;
// 'Avoid Losing Edits?' prompt
// Zero state
// Dialogs
export const DialogActionNext = "Next";

// Reject actions
export const ReopenTaskRejectActionTooltipText = (taskName: string) =>
  `Rejecting this task will restart the task ${taskName}`;
export const RestartStageRejectActionTooltipText = (stageName: string) =>
  `Rejecting this task will restart the stage ${stageName}`;
// Form fields
// Confirmation Dialog
export const DeleteTagWarning = (args: { count: number; name: React.ReactChild }) => {
  return (
    <span>
      {args.name} has {args.count} open task(s) visible to you.
    </span>
  );
};
// Create Field Dialog
// Custom Fields
export const FromPreviousHelperText = (taskName: string) => `From: ${taskName}`;
export const FromPreviousHelperTextWithStage = (stageName: string, taskName: string) =>
  `from ${stageName} > ${taskName}`;
export const SelectFieldEllipses = "Select field...";
export const FieldInUseDeleteButtonDisabled = (blueprintCount: number) => (
  <>
    This field cannot be deleted because it is still being used in <strong>{blueprintCount}</strong> company blueprint
    {blueprintCount === 1 ? "" : "s"}.
  </>
);
export const FieldInUseDeleteButtonDisabledV2 = (blueprintCount: number) => (
  <>
    This field cannot be deleted because it is still being used in <strong>{blueprintCount}</strong> published blueprint
    {blueprintCount === 1 ? "" : "s"}.
  </>
);
// Workflow
export const ActionItemStarted = (name: string) => `Task ${name} started`;
export const AttachDocumentsLimitReached = (limit: number) => `${limit} / ${limit} documents attached`;
export const AttachDocumentsV2 = (attachAction: React.ReactChild, pasteAction: React.ReactChild) => (
  <>
    Drop,{attachAction}, or {pasteAction} a link
  </>
);
export const EndedByUserOnDate = (name: string, date: string) => (
  <>
    This workflow was ended by <strong>{name}</strong> on <strong>{date}</strong>
  </>
);
export const Edit = "Edit";
export const EndDate = "End date";
export const EstimatedEndDate = "Estimated end date";
export const PlannedEndDate = "Planned end date";
export const ConditionalPlannedEndDate = "Conditional planned end date";
export const ActualEndDate = "Actual end date";
export const ActionItemDueDateMustBeInFuture = (actionItemName: string) =>
  `The due date for the task '${actionItemName}' is in the past. This may cause visual inconsistencies in your final Gantt Chart.`;
export const WorkflowStageMustStartInFuture = (stageNameOrNumber: string | number) =>
  `The start date for the stage ${
    typeof stageNameOrNumber === "number" ? `number ${stageNameOrNumber}` : `'${stageNameOrNumber}'`
  } is in the past. This may cause visual inconsistencies in your final Gantt Chart.`;
export const MustBeUniqueValue = "Must be a unique value";
export const MustBeValidUrl = "Must be a valid URL";
export const MustBeNumber = "Must be a number";
export const MustBeWholeNumber = "Must be a whole number";
export const MustBeWholeNumberInRange = (args: { min: number; max: number }) =>
  `Must be a whole number between ${args.min} and ${args.max}`;
export const OverdueActionItems = "Overdue tasks";
export const PausedByUserOnDate = (name: string, date: string) => (
  <>
    This workflow was paused by <strong>{name}</strong> on <strong>{date}</strong>
  </>
);
export const PleaseEnterAMessage = "Include a message";
export const StageAdded = (stageName: string) => `Stage ${stageName} added`;
export const StageDeleted = (stageName: string) => `Stage ${stageName} deleted`;
export const StageStatus = "Stage status";
export const StageStarted = (stageName: string) => `Stage ${stageName} started`;
export const StartDate = "Start date";
export const CompletedOnTime = (time: React.ReactChild) => `Completed on ${time}`;
export const UsedInCompanyBlueprints = "Used in company blueprints";
export const UsedInPublishedBlueprints = "Used in published blueprints";
export const Workflow = "Workflow";
export const WorkflowName = "Workflow name";
export const WorkflowHasBeenPausedForDays = (days: number) => `This workflow has been paused for ${days} days`;
export const WorkflowCreatorTooltip =
  "The creator of the workflow has commenter access on the workflow. They can also delete the workflow.";
export const WorkflowTemplateListByFieldDialogDescription = (fieldName: string, templateCount: number) => (
  <span>
    Here are the {templateCount} company blueprints using the <strong>{fieldName}</strong> field.
  </span>
);
export const WorkflowTemplateListByFieldDialogDescriptionSingular = (fieldName: string) => (
  <span>
    Here is the company blueprint using the <strong>{fieldName}</strong> field.
  </span>
);
export const WorkflowTemplateListByIntegrationDialogDescription = (integrationName: string, templateCount: number) => (
  <span>
    Here are the {templateCount} blueprints using the <strong>{integrationName}</strong> integration.
  </span>
);
export const WorkflowTemplateListByIntegrationDialogDescriptionSingular = (integrationName: string) => (
  <span>
    Here is the blueprint using the <strong>{integrationName}</strong> integration.
  </span>
);
export const WorkflowTemplateListByRoleDialogDescription = (roleName: string, templateCount: number) => (
  <span>
    Here are the {templateCount} blueprints using the <strong>{roleName}</strong> role.
  </span>
);
export const WorkflowTemplateListByRoleDialogDescriptionSingular = (roleName: string) => (
  <span>
    Here is the blueprint using the <strong>{roleName}</strong> role.
  </span>
);
export const WorkflowTemplate = "Blueprint";
export const WorkflowId = "Workflow Id";
export const WorkflowTemplateUpdated = (link: JSX.Element) => <span>Blueprint {link} updated</span>;
export const WorkflowUpdated = (link: JSX.Element) => <span>Workflow {link} updated</span>;
export const StageCompletedCount = (numCompleted: number, numSkipped: number) =>
  `${numCompleted} stage${numCompleted === 1 ? "" : "s"} completed (${numSkipped} skipped)`;
export const DaysLeadTimeWithNumber = (number: number) => `${number} ${number === 1 ? "day" : "days"}`;
export const InProgress = "In progress";
export const Paused = "Paused";
export const SaveChanges = "Save changes";
export const Day = "day";
export const Wk = "wk";
export const DeleteTheActionItemContent = (name: string) => `Delete the ${name} task.`;
export const WorkflowDeleted = (name: string) => `Workflow ${name} deleted`;
export const WorkflowNoAccessRoleName = "No access";
export const WorkflowNoAccessRoleDescription = "Only view assigned or cc'd tasks";
export const CommenterRoleName = "Commenter";
export const EditorRoleName = "Editor";
export const CommenterRoleDescription = "View entire workflow, its data, and comment";
export const EditorRoleDescription = "Add, remove, and edit tasks and stages";
export const WorkflowOwnerCannotBeEditedTooltip =
  "The workflow owner cannot be edited. The owner is managed by the parent workflow.";
// Templates
export const AutogenerateFileName = "Autogenerate file name";
export const BlueprintId = "Blueprint ID";
export const BlueprintUuidOptionSubtext = (blueprintName: string) => `Name: ${blueprintName}`;
export const BlueprintVersionPublished = (
  blueprintName: React.ReactNode,
  versionNumber: number,
  publishedBlueprintsLink: React.ReactNode,
) => (
  <>
    {blueprintName} has been published.
    <br />
    The version {versionNumber} is now available in {publishedBlueprintsLink}.
  </>
);
export const BulkStartWorkflowsDialogTitle = (workflowTemplateName: string) =>
  `Bulk add "${workflowTemplateName}" workflows`;
export const BulkStartWorkflowsLoading = (numWorkflows: number) =>
  `Starting ${numWorkflows} ${numWorkflows === 1 ? "workflow" : "workflows"}...`;
export const BulkStartWorkflowsValidationHintInvalidDateFormat = (commaSeparatedDateFormats: string) =>
  `Please enter dates in one of the following formats: ${commaSeparatedDateFormats}`;
export const BulkStartWorkflowsValidationHintInvalidEmailsV2 = (commaSeparatedEmails: string, numEmails: number) =>
  `These ${numEmails === 1 ? "user" : "users"} are unrecognized and will be created on submit: ${commaSeparatedEmails}`;
export const BulkStartWorkflowsValidationErrorInvalidEmails = (commaSeparatedEmails: string, numEmails: number) =>
  `These ${
    numEmails === 1 ? "user" : "users"
  } are unrecognized. Please enter internal users who exists in this workspace: ${commaSeparatedEmails}`;
export const BulkStartWorkflowsValidationHintInvalidValues = (commaSeparatedValues: string, numValues: number) =>
  `Unrecognized ${numValues === 1 ? "value" : "values"}: ${commaSeparatedValues}`;
export const BulkStartWorkflowsValidationHintUnrecognizedTags = (commaSeparatedTagNames: string) =>
  `Unrecognized tag: ${commaSeparatedTagNames}`;
export const DeleteCompanyBlueprintHelperText = (blueprintName: string) => (
  <>
    <p>
      Deleting <strong>{blueprintName}</strong> will also delete any versions of the blueprint. Workflows currently
      using this blueprint will not be affected.
    </p>
    <br />
    <p>Confirm deleting by typing the blueprint name below:</p>
  </>
);
export const EditBlueprint = "Edit blueprint";
export const LockTooltipTextRequestedFieldEdit = "This field cannot be edited";
export const NamingConventionEditFieldsConfirmContent =
  "The workflow's name will be regenerated to use the updated field values.";
export const NamingConventionStageReevaluateEditFieldsConfirmContent = `${NamingConventionEditFieldsConfirmContent} ${StageReevaluateDialogText}`;
// (zstanik) blueprint variations TODO: replace this with the actual how to guide once it gets
// published.
export const VariantBlueprintWorkflowCannotBeCreatedTooltip = (standardBlueprintName: string) =>
  `Workflows cannot be created from a variant blueprint. Create a workflow from ${standardBlueprintName}.`;
export const VariantOfStandard = (standardNameOrLink: React.ReactNode) => <>Variant of {standardNameOrLink}</>;
export const VariantsTabEmptyStateExample1 = (
  <>
    Use different tasks if <strong>Country</strong> = &quot;India&quot;
  </>
);
export const VariantsTabEmptyStateExample2 = (
  <>
    Request more fields if <strong>Region</strong> = &quot;EMEA&quot;
  </>
);
export const VariantsTabEmptyStateExample3 = (
  <>
    Delegate to different owners based on <strong>Factory</strong>
  </>
);
export const VersionNumber = (versionNumber: number) => `Version ${versionNumber}`;
export const VNumberParentheses = (versionNumber: number) => `(v${versionNumber})`;
export const VersionPound = "Version #";
export const WorkflowTemplatePublished = (templateNameLink: React.ReactNode, tenantDisplayName: string) => (
  <>
    {templateNameLink} blueprint published in {tenantDisplayName}&apos;s blueprints.
  </>
);
export const WorkflowTemplatePublishedDefault = (templateNameLink: React.ReactNode) => (
  <>{templateNameLink} blueprint was published.</>
);
export const WorkflowTemplateDeleted = (name: string) => `Blueprint ${name} deleted`;
export const DeleteTheWorkflowTemplateContent = (name: string) => `Delete the ${name} blueprint.`;
export const WorkflowTemplateSavedToMyBlueprints = (blueprintNameLink: React.ReactNode) => (
  <>{blueprintNameLink} copied to My blueprints.</>
);
export const WorkflowTemplateSavedToDraftBlueprints = (blueprintNameLink: React.ReactNode) => (
  <>{blueprintNameLink} copied to Draft Blueprints.</>
);
export const WorkspaceCreated = (name: string) => `Workspace ${name} added`;
export const WorkspaceRenamed = (newDisplayName: string) => `Workspace renamed to ${newDisplayName}`;

// Settings
export const YouAreNowSubscribedTo = (link: React.ReactNode) => <span>You are now subscribed to {link}</span>;

// Sidebar
export const PeopleAndTeams = "People & Teams";
// Schedule
export const TaskName = "Task name";

// Starred views
export const ToastSuccessOnStarEntity = (linkToEntity: JSX.Element) => <span>Successfully starred {linkToEntity}</span>;
export const ToastSuccessOnUnstarEntity = (linkToEntity: JSX.Element) => (
  <span>Successfully unstarred {linkToEntity}</span>
);

// Custom Fields
export const CreateNewField = "Add field";
export const DocumentsCount = (count: number) => `${count} document${count === 1 ? "" : "s"}`;
export const Fields = "Fields";
export const RemoveField = "Remove field";
// Home
export const HomeDaysDelayed = (days: number) => `${days} ${days > 1 ? "days" : "day"}`;
export const HomeDelayed = "Delayed";
export const HomeLatestActivity = "Latest activity";
export const HomeNoColumnsSelected =
  "There are no columns selected. Click on the columns button above to add columns to the table.";
export const HomePaginationLabel = (start: number, end: number, total: number) =>
  `${start}-${end} of ${total.toLocaleString()}`;
export const HomePercentComplete = "% Complete";
export const HomeSharedThisViewWithYou = (name: string) => `${name} shared this view with you.`;
export const HomeViewDeleted = (view: string) => (
  <span>
    <strong>{view}</strong> has been deleted.
  </span>
);

export const HomeViewShared = (view: string) => (
  <span>
    <strong>{view}</strong> shared list has been updated.
  </span>
);
export const HomeViewSaved = (view: string) => (
  <span>
    <strong>{view}</strong> has been saved.
  </span>
);
export const HomeWeeksDelayed = (weeks: number) => `${weeks} ${weeks > 1 ? "weeks" : "week"}`;

export const HomeCannotShareMyWorkflowsView = `You cannot share views created from "My Workflows" as other users may not have permission to view your workflows`;

export const HomeBulkEditWorkflowsSelected = (n: number) => (
  <span>
    <strong>{n}</strong> {n === 1 ? "workflow" : "workflows"} selected
  </span>
);
export const HomeBulkEditWorkflowsDeletedAllSuccessful = (n: number) => (
  <span>
    <strong>{n}</strong> {n === 1 ? "workflow" : "workflows"} deleted.
  </span>
);
export const HomeBulkEditWorkflowsDeletedPartialSuccess = (countSuccess: number, countFailure: number) => (
  <span>
    <strong>{countSuccess}</strong> {countSuccess === 1 ? "workflow" : "workflows"} deleted successfully.
    <strong>{countFailure}</strong> {countFailure === 1 ? "workflow" : "workflows"} could not be deleted.
  </span>
);
export const HomeBulkEditInProgressWorkflowsWillBeDeleted = (n: number) => (
  <span>
    <strong>{n}</strong> {n === 1 ? "workflow" : "workflows"} will be permanently deleted.
  </span>
);
export const HomeBulkEditConfirmDeleteWorkflows = (n: number) => `Delete ${n} ${n === 1 ? "workflow" : "workflows"}`;
export const HomeBulkEditYouLackNecessaryPermissions = (n: number) =>
  `You lack the necessary permissions to edit ${n === 1 ? "this workflow" : "one or more of these workflows"}.`;
export const HomeBulkEditClearSelections = (n: number) => `Clear ${n === 1 ? "selection" : "selections"}`;
export const HomeBulkEditFailedToDeleteWorkflows = (n: number) => (
  <span>
    <strong>Failed to delete {n === 1 ? "workflow" : "workflows"}.</strong> Please try again, or contact a Regrello
    admin if you continue to see this error.
  </span>
);

// User Custom Fields
export const AllTeamMembersMustHaveSameTypeAsTeam = (teamType: string) =>
  `All users must be ${teamType} in an ${teamType} team.`;
// Error Messages
export const ErrorCorruptDocuments = (documentNamesJoinedWithCommas: string) =>
  `Failed to submit task. The following documents were uploaded incorrectly: ${documentNamesJoinedWithCommas}`;
export const ErrorBoundaryTitle = "Something went wrong";
export const ErrorBoundaryDescription =
  "Please refresh, or contact a Regrello admin if you continue to see this error.";
export const ErrorDownloadDocument =
  "Failed to download document. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorSaveWorkflowPeople =
  "Failed to save workflow people. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorSaveWorkflowTemplatePeople =
  "Failed to save blueprint people. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdate =
  "Failed to update. Please try again, or contact a Regrello admin if you continue to see this error.";
export const QueryToastMessageError =
  "Unable to fetch data, please try again or contact a Regrello admin if you continue to encounter this problem";
export const InvalidRoleAssignmentMissingScimManager =
  "This role cannot be assigned to the user(s) because they do not have SCIM managers set, yet the role is linked to " +
  "a task that requires their current manager. The action could result in a task being left without an assignee.";
// Non-ideal states
export const NonIdealStateFetchFailed = "Data fetch failed. Please check your network status and try again.";
// Workflow Tabs
// Workflow Collaborators
// Automation tasks
export const AutomationAuthBearerTokenFieldTokenMaskSuffix = AsteriskSymbol.repeat(36);
export const AutomationDocuSignCreateAndSendNameTemplateHelpTooltipText =
  "A naming convention specifies the document name based on field values. Names will then be automatically generated by Regrello.";
export const AutomationDocuSignCreateAndSendWhereToSaveDocumentDescription =
  "Select a field where we should save the document from DocuSign:";
export const AutomationDocuSignCreateAndSendReceiveInfo = "Receive info";
export const AutomationDocuSignCreateAndSendSelectFieldDots = "Select field...";
export const AutomationDocuSignWorkflowFields = "Workflow fields";
export const AutomationDocuSignDocuSignFields = "DocuSign fields";
export const AutomationDocuSignSelectWorkflowFieldsDescription = (
  docusignTemplateName: string,
  learnMoreElement: JSX.Element,
) => (
  <>
    Select workflow fields to send from Regrello into the <strong>{docusignTemplateName}</strong> DocuSign template.{" "}
    {learnMoreElement}
  </>
);
export const AutomationDocuSignCreateAndSendLearnMoreLink =
  "https://regrello.notion.site/DocuSign-Integration-cf82c260c06b47f3ad8b1b395e4a6ef7";
export const AutomationDocuSignCreateAndSendDocumentForSignature = "Create and send document for signature";
export const SendInfo = "Send info";
export const LearnMore = "Learn more";
export const ConfigureAutomationCreateSuccess = (integrationName: string, actionItemTemplateName: string) =>
  `${integrationName}: ${actionItemTemplateName} added`;
export const ConfigureAutomationEditSuccess = (integrationName: string, actionItemTemplateName: string) =>
  `Changes saved for ${integrationName}: ${actionItemTemplateName}`;
export const ConfigureAutomationIntegrationBoxTitle = "Box";
export const ConfigureAutomationIntegrationBoxHelperText = "Secure file sharing";
export const ConfigureAutomationIntegrationDocuSignTitle = "DocuSign";
export const ConfigureAutomationIntegrationDocuSignHelperText = "eSignatures and agreements";
export const ConfigureAutomationIntegrationDocuSignDeveloperTitle = "DocuSign (Developer)";
export const ConfigureAutomationIntegrationGoogleDriveTitle = "Google Drive";
export const ConfigureAutomationIntegrationGoogleDriveHelperText = "File storage and backup";
export const ConfigureAutomationIntegrationNetsuiteTitle = "Netsuite";
export const ConfigureAutomationIntegrationNetsuiteHelperText = "ERP, inventory, and more";
export const ConfigureAutomationIntegrationOracleTitle = "Oracle";
export const ConfigureAutomationIntegrationOracleHelperText = "Cloud applications and platform";
export const ConfigureAutomationIntegrationSapTitle = "SAP";
export const ConfigureAutomationIntegrationSapHelperText = "Business applications";
export const ConfigureAutomationIntegrationSharePointTitle = "SharePoint";
export const ConfigureAutomationIntegrationSharePointHelperText = "Team collaboration";
export const ConfigureAutomationIntegrationCustomTitle = "Custom HTTP";
export const ConfigureAutomationIntegrationCustomHelperText = "Configure your own API request";
export const ConfigureAutomationConnectionCreatedAt = (createdAtDateString: string) =>
  `Created at ${createdAtDateString}`;
export const ConfigureAutomationSyncIntervalManual = "Manual";
export const ConfigureAutomationSelectAuthenticationEmptyState = (integrationName: string) => (
  <span>Sign in to {integrationName} and give the required permissions to connect this automation.</span>
);
export const ConfigureAutomationSignIntoService = (serviceName: string) => `Sign in to ${serviceName}`;
export const ConfigureIntegrationAutomationEditSuccess = (actionItemTemplateName: string) =>
  `Changes saved to ${actionItemTemplateName}`;
export const DeleteAutomationConfirm = (integrationName: string, name: string) => (
  <span>
    Are you sure you want to delete{" "}
    <strong>
      {integrationName}: {name}
    </strong>{" "}
    integration?
  </span>
);
export const DeleteAutomationSuccess = (integrationName: string, actionItemTemplateName: string) =>
  `${integrationName}: ${actionItemTemplateName} deleted`;
export const AutomatedTaskTestEmptyStateOutputs = <span>Click &ldquo;Test&rdquo; to see outputs</span>;
// Integration automation plugins - base
export const IntegrationAutomationFormSectionInputsTitle = (integrationName: string) => `${integrationName} inputs`;
export const IntegrationAutomationFormSectionInputsDescription = (integrationName: string) =>
  `Select custom fields to send to ${integrationName}.`;
export const IntegrationAutomationFormSectionOutputsTitle = (integrationName: string) => `${integrationName} outputs`;
export const IntegrationAutomationFormSectionOutputsDescription = (integrationName: string) =>
  `Select fields to store data received from ${integrationName}.`;
// Integration automation plugins - specific
export const IntegrationAutomationStorageSaveFileToFolderPluginTitle = "Save file to folder";
export const IntegrationAutomationStorageSaveFileToFolderPluginFolderLabel = "Folder";
export const IntegrationAutomationSharepointSiteName = "Site";

export const IntegrationAutomationDocusignCreateDraftFromTemplatePluginTemplateTitle = "Create draft from template";
export const IntegrationAutomationDocusignCreateDraftFromTemplatePluginTemplateSecionTitle = "DocuSign template";
export const IntegrationAutomationDocusignCreateDraftFromTemplatePluginTemplateSecionDescription =
  "Select a DocuSign template to use.";
export const IntegrationAutomationDocusignCreateDraftFromTemplatePluginTemplateStandaloneLabel = "Template";
export const IntegrationAutomationDocusignCreateDraftFromTemplatePluginTemplateLabel = "DocuSign template";

export const IntegrationAutomationDocusignCreateDraftAndSendForSignatureReceiveSignedPdfPluginTitle =
  "Create and send for signature";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginTitle = "Send for signature";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDraftFieldLabel = "DocuSign draft";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDraftFieldHelperText =
  "Select a DocuSign draft from earlier in the workflow.";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDetailViewCalloutText =
  "It will be completed automatically when all parties finish signing.";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDetailViewCalloutTitle =
  "This automated task is waiting for signatures via DocuSign.";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDetailViewCalloutTextProblem =
  "You must go to the task that generates the DocuSign draft and re-run it.";
export const IntegrationAutomationConnectionIssue =
  "Connection issue. Please contact your system administrator to update the connection.";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDetailViewCalloutTitleProblem =
  "This automated task's problem cannot be resolved manually.";
// Storage Folder Panel
export const StorageFolderPanelInitialPanelTitle = "Select a folder";
export const StorageFolderPanelButtonPlaceholder = "Select a folder...";

// OAuth callback page
export const OauthCallbackErrorTitle = (integrationDisplayName: string) =>
  `Failed to authenticate ${integrationDisplayName}`;
export const OauthCallbackErrorDescriptionMissingIntegration = (integrationDisplayName: string) =>
  `Could not load or locate Regrello's pre-configured ${integrationDisplayName} integration.`;
export const OauthCallbackSuccessTitle = (integrationDisplayName: string) =>
  `Successfully authenticated with ${integrationDisplayName}`;
// Line Items
// Task status
// Regrello Lite
// Teams
export const AtDomain = (domain: string) => `@${domain}`;
export const DeleteDeleteConfirm = (name: string) => (
  <span>
    Are you sure you want to delete the <strong>{name}</strong> team?
  </span>
);
export const ErrorAddingToWorkspace = (value: string) => `${value} could not be added to this workspace. Try again.`;
export const ErrorCreateTeamEmailAlreadyExists = (email: string) =>
  `Team could not be created. A team or user with email "${email}" already exists.`;
export const ErrorCreateTeamNameAlreadyExists = (name: string) =>
  `Team could not be created. A team with name "${name}" already exists.`;
export const ErrorRemovingFromTemplate = (name: string) => `${name} could not be removed this workflow. Try again.`;
export const ErrorRemovingFromWorkflow = (name: string) => `${name} could not be removed this workflow. Try again.`;
export const ErrorRemovingFromWorkspace = (name: string) => `${name} could not be removed this workspace. Try again.`;
export const ErrorUpdatingWorkspacePermission = (name: string) =>
  `${name}’s workspace permissions could not be changed. Try again.`;
export const SuccessAddingToWorkspace = (name: string) => `${name} has been added to this workspace.`;
export const SuccessRemovingFromWorkflow = (name: string) => `${name} has been removed from this workflow.`;
export const SuccessRemovingAccessFromWorkflow = (name: string) => `${name} can no longer access this workflow.`;
export const SuccessRemovingFromWorkspace = (name: string) => `${name} has been removed from this workspace.`;
export const SuccessUpdating = (name: string) => `${name} has been updated.`;
export const SuccessUpdatingTemplatePermission = (name: string, valueAfter: string) =>
  `${name}'s blueprint permissions have been changed to "${valueAfter}".`;
export const SuccessUpdatingWorkflowPermission = (name: string, valueAfter: string) =>
  `${name}'s workflow permissions have been changed to "${valueAfter}".`;
export const SuccessUpdatingWorkspacePermission = (name: string, valueAfter: string) =>
  `${name}'s workspace permissions have been changed to "${valueAfter}".`;
// Toast Message
// Workflow Stage Start Conditions
// Filters and conditions
export const ConditionConnectiveIf = "If";
export const ConditionConnectiveAndIf = "And if";
export const ConditionConnectiveOrIf = "Or if";
export const ConditionAfter = "After";
export const AddFilter = "Add filter";
export const ConditionBefore = "Before";
export const ConditionDocumentFilenamesContain = "Filenames contain";
export const ConditionDocumentFileTypesContain = "Has file type";
export const ConditionOnOrBefore = "On or before";
export const ConditionIsChecked = "Is checked";
export const ConditionIsNotChecked = "Is not checked";
export const ConditionIsEmpty = "Is empty";
export const ConditionIsNotEmpty = "Is not empty";
export const ConditionIsGreaterThan = "Is greater than";
export const ConditionIsGreaterThanOrEqual = "Is greater than or equal to";
export const ConditionIsLessThan = "Is less than";
export const ConditionIsLessThanOrEqual = "Is less than or equal to";
export const ConditionIs = "Is";
export const ConditionIsExactly = "Is exactly";
export const ConditionIsAny = "Is any";
export const ConditionIsNot = "Is not";
export const ConditionIsEqual = "Is equal to";
export const ConditionIsNotEqual = "Is not equal to";
export const ConditionIsBetween = "Is between";
export const ConditionIsAfterOrOn = "Is after or on";
export const ConditionIsBeforeOrOn = "Is before or on";
export const ConditionIsOnDate = "Is on same calendar day";
export const ConditionIsIn = "Is in";
export const ConditionIsNotIn = "Is not in";
export const ConditionOnOrAfter = "On or after";
export const ConditionContains = "Contains";
export const ConditionContainsAll = "Contains all of";
export const ConditionContainsAny = "Contains any of";
export const ConditionDoesNotContain = "Does not contain";
// Skipped Stage Status
// Schedule time form.
// Stage Start Conditions Info Banner
export const ConditionBannerStageStartAfterV2 = (stageName: string) => `After ${stageName}`;
export const ConditionSummaryString = (args: {
  fieldChip: JSX.Element;
  operator: JSX.Element;
  displayValue1: JSX.Element | undefined;
  displayValue2: JSX.Element | undefined;
}) => (
  <>
    {args.fieldChip}
    <>
      &nbsp;{args.operator}&nbsp;
      {args.displayValue1 != null && args.displayValue1}
      {args.displayValue2 != null && " and "}
      {args.displayValue2 != null && args.displayValue2}
    </>
  </>
);
// `AutomationExtractValueFromJsonErrorCode`s
export const AutomationResponseParserFailedToExtractBooleanDueToTypeMismatch = (values: string) => (
  <>
    Failed to extract values <code>{values}</code> into a checkbox field due to a type mismatch
  </>
);
export const AutomationResponseParserFailedToExtractDateDueToTypeMismatch = (values: string) => (
  <>
    Failed to extract values <code>{values}</code> into a date field due to a type mismatch
  </>
);
export const AutomationResponseParserFailedToExtractDocumentDueToTypeMismatch = (values: string) => (
  <>
    Failed to extract values <code>{values}</code> into a document field due to a type mismatch
  </>
);
export const AutomationResponseParserFailedToExtractNumberDueToTypeMismatch = (values: string) => (
  <>
    Failed to extract values <code>{values}</code> into a number field due to a type mismatch
  </>
);
export const AutomationResponseParserMultipleValuesReceivedForBooleanField = (values: string) => (
  <>
    Received too many values <code>{values}</code> for a number field
  </>
);
export const AutomationResponseParserMultipleValuesReceivedForDateField = (values: string) => (
  <>
    Received too many values <code>{values}</code> for a checkbox field
  </>
);
export const AutomationResponseParserMultipleValuesReceivedForDocumentField = (values: string) => (
  <>
    Received too many values <code>{values}</code> for a checkbox field
  </>
);
export const AutomationResponseParserMultipleValuesReceivedForNumberField = (values: string) => (
  <>
    Received too many values <code>{values}</code> for a checkbox field
  </>
);
export const AutomationResponseParserNoValuesExtractedFromResponse = (_values: string) => (
  <>No matching field values were found to extract from the response</>
);
export const AutomationResponseParserReceivedValueDoesNotExist = (values: string) => (
  <>
    Could not extract values <code>{values}</code> because they do not map to field values in Regrello
  </>
);
export const AutomationResponseParserFailedToExtractRoleMismatch = (values: string, roleName: string) => (
  <>
    Could not extract values <code>{values}</code> because they do not map to the role <code>{roleName}</code> in
    Regrello.
  </>
);

// Bulk actions
export const BulkEditDateFailure = (dueDate: string | undefined, numSucceeded: number) => {
  const resolvedDueDate = dueDate != null ? `to ${dueDate}` : "";
  return `Successfully updated due date ${resolvedDueDate} for ${numSucceeded} tasks. `;
};
export const BulkEditDateSuccess = (dueDate: string | undefined, numSucceeded: number) => {
  const resolvedDueDate = dueDate != null ? `to ${dueDate}` : "";
  return `Successfully updated due date ${resolvedDueDate} for ${numSucceeded} tasks. `;
};
export const BulkEditPartyFailure = (
  action: "add" | "remove",
  partyType: "assignees" | "ccs",
  numSucceeded: number,
) => {
  const preposition = action === "add" ? "to" : "from";
  return `Failed to ${action} ${partyType} ${preposition} ${numSucceeded} tasks. `;
};
export const BulkEditPartySuccess = (
  action: "added" | "removed",
  partyType: "assignees" | "ccs",
  numSucceeded: number,
) => {
  const preposition = action === "added" ? "to" : "from";
  return `Successfully ${action} ${partyType} ${preposition} ${numSucceeded} tasks. `;
};
// Regrello Lite confirmation page
export const RegrelloLiteAcceptInviteInstruction = (createdBy: string, tenantName: string) => (
  <span>
    <strong>{createdBy}</strong> has invited you to join the Regrello workspace <strong>{`"${tenantName}"`}</strong> by
    assigning you this task. You don’t need to sign in to Regrello to use it. If you would like to sign in to complete
    your work, please click send email invite.
  </span>
);
// Data Sources
export const DeleteSyncedObjectConfirmation = (name: string) => (
  <>
    <p>
      Data source <strong>{name}</strong> will be removed from the workspace.
    </p>
    <br />
    <p>
      This will not affect the existing workflows using this data source but you will not be able to use this in any new
      blueprint or workflow.
    </p>
  </>
);
export const DeleteSyncedObjectSuccess = (name: string) => `${name} was successfully deleted.`;
export const SyncedObjectsDialogStepAddSyncedObjectsTopInformationEditing = (name: string) => (
  <span>
    Update <strong>{name}</strong> object via CSV file.
  </span>
);
export const SyncedObjectsDialogStepAddSyncedObjectsFormattingTipsListItems = [
  "Field names should be located in the header row",
  "Primary field(s) should not contain duplicate or missing values",
  "CSV file should be delimited with comma",
];
export const SyncedObjectsDialogStepAddSyncedObjectsDropZone = (className: string) => (
  <span>
    Drag and drop or <span className={className}>click to select</span> a CSV file from your computer.
  </span>
);
export const SyncedObjectsDialogStepUploadSummaryErrorNotAdded = (objectName: string) => (
  <>
    <strong>{objectName}</strong> object was not added.
  </>
);
export const SyncedObjectsDialogStepUploadSummaryCompleteAdded = (objectName: string) => (
  <>
    <strong>{objectName}</strong> object has been added to the processing queue!
  </>
);
// Personalization
// Split tasks
export const SplitTaskFormSectionSplitterFieldDescription = (
  actionItemType: "linkedWorkflow" | "default" | "standaloneApproval",
) => `Split this ${actionItemType === "linkedWorkflow" ? "workflow" : "task"} into multiple based on`;
export const SplitTasks = (numSplitTasks: number) => `${numSplitTasks} split task${numSplitTasks === 1 ? "" : "s"}`;
// Admin > settings > SCIM
export const AlternateEmails = "Alternate emails";
export const ScimAccessTokenHelperText =
  "The access token won’t be accessible after leaving this screen. Copy it into the user active directory or " +
  "regenerate if a new one is required.";
// Admin > settings > SCIM > attribute mapping
export const InfoSectionOnAttributeMapping =
  "Make sure the following attributes have been correctly mapped when setting up provisioning in your user directory.";
export const ConfigureScimWarningMessage =
  "Please ensure you copy the SCIM URL and access token and complete the remaining steps of the SCIM " +
  "configuration before registering the application in the user active directory. It may take up to 2 minutes for " +
  "the SCIM connection to become available in the user active directory for setup. If the access token is grayed out and " +
  "you need a new one, please regenerate the credential to copy a new token and invalidate the old one.";
export const RegrelloAlternateEmailAttribute = "Alternate emails";
export const RegrelloUserAttributes = "Regrello user attributes";
export const RegrelloUserEmailAttribute = "Email";
export const RegrelloUserManagerAttribute = "Manager";
export const RegrelloUserNameAttribute = "Name";
export const RegrelloUserScimIdAttribute = "Scim ID";
export const RegrelloUserStatusAttribute = "Status";
export const RegrelloUserTimezoneAttribute = "Timezone";
export const RegrelloUserUsernameAttribute = "Username";
export const RegrelloUserWorkCountryAttribute = "Work Country";
export const ScimActiveAttribute = "active";
export const ScimAttributes = "SCIM attributes";
export const ScimExternalIdAttribute = "externalId";
export const ScimHomeEmailAttribute = `emails[type eq "home"].value`;
export const ScimManagerAttribute = "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User:manager";
export const ScimNameFormattedAttribute = "name.formatted";
export const ScimOtherEmailAttribute = `emails[type eq "other"].value`;
export const ScimTimezoneAttribute = "timezone";
export const ScimUsernameAttribute = "userName";
export const ScimWorkCountryAttribute = `addresses[type eq "work"].country`;
export const ScimWorkEmailAttribute = `emails[type eq "work"].value`;
// SCIM party avatar
export const ManagerSuffix = "'s manager";
export const Manager = "manager";
export const ManualConfigurationOptionDescription =
  "Users and teams are manually added, removed, and updated. Please select this option to disconnect from any " +
  "existing user directory application, if applicable, before creating a new connection to another one.";
export const RoleNotActivelyScimProvisionedTooltipText =
  "This role was initially managed via SCIM but is now manually provisioned.";
// People table
export const CapitalTo = "To";
export const User = "User";
export const UserAD = "User AD";
export const ViaScim = "via SCIM";

// RegrelloFileDropzone
// Error message overrides for the papaparse library. @see RegrelloCsvParser
export const PapaparseErrorMessagesEmptyColumns = (columns: Array<number | string>) =>
  `Invalid column header detected. Please check the columns at the following indexes: ${columns.join(", ")}`;
// Out of office
// Workflow status overviews.
// Notifications
// AI control center
// Linked workflows
export const AddFieldToParent = (parentName: string) => (
  <span>
    Add field to <strong>{parentName}</strong>. Adding will make it required when starting the workflow.
  </span>
);
export const Blueprint = "Blueprint";
export const DisabledFieldInstanceRowIsSelectedSplitterField = (
  actionItemType: "default" | "linkedWorkflow" | "standaloneApproval",
) => `This field is being used to split the ${actionItemType === "linkedWorkflow" ? "workflow" : "task"}.`;
export const InputFieldRestrictedTooltipText = (fieldDisplayName: string) =>
  `This ${fieldDisplayName} field cannot be selected as it has a different name and values. Please only select the field with the same name it is being mapped to.`;
export const ParentWorkflowDeletedWarning = (parentName: string) => (
  <>
    The parent workflow <strong>{parentName}</strong> has been deleted
  </>
);
export const ParentWorkflowEndedWarning = (parentName: string) => (
  <>
    The parent workflow <strong>{parentName}</strong> has been ended
  </>
);
export const ParentWorkflowPausedWarning = (parentName: string) => (
  <>
    The parent workflow <strong>{parentName}</strong> has been paused
  </>
);
export const ParentWorkflowUnlinkedWarning = (parentName: string) => (
  <>
    The parent workflow <strong>{parentName}</strong> has been updated and no longer depends on this workflow to
    complete
  </>
);
export const PercentComplete = (percentComplete: number) => `${percentComplete}% complete`;
export const PublishBlueprintAndLinkedBlueprintsDialogText = (tenantName: string) =>
  `Publish and make available in ${tenantName}'s blueprints? This will also publish any linked blueprints.`;
export const PublishBlueprintAndLinkedBlueprintsDialogTextV2 = (tenantName: string) => (
  <>
    Publishing will make it available within {tenantName}&apos;s blueprints.
    <br />
    If the blueprint contains any non-published linked blueprints, they will also be published.
  </>
);
export const PublishBlueprintAndLinkedBlueprintsDialogTextDefaultV3 = (
  <>
    Publishing will make it available within published blueprints.
    <br />
    If the blueprint contains any non-published linked blueprints, they will also be published.
  </>
);
export const SelectFieldsInputText = (parentName: JSX.Element, linkedName: JSX.Element) => (
  <>
    Select fields to pass from {parentName} to {linkedName}
  </>
);
export const SelectFieldsOutputText = (parentName: JSX.Element, linkedName: JSX.Element) => (
  <>
    Select fields from the linked workflow {linkedName} to return into {parentName}. The fields selected can then be
    shared with future tasks.
  </>
);
export const ThereAreNoFieldsInLinkedWorkflow = (linkedWorkflowName: JSX.Element) => (
  <>There are no fields to select from {linkedWorkflowName}</>
);
export const AddWorkflowDialogSelectedSort = (byAttribute: string) => `Sort: ${byAttribute}`;
// Spectrum
export const CreateNewSpectrumFieldEmpty = (name: string) => `Create "${name}"`;
export const DeleteFormNamed = (name: string, context: "blueprint" | "workflow") => (
  <span>
    Deleting form <strong>{name}</strong> means you can no longer edit it or use it again in this {context}.
  </span>
);
export const DeleteFormFromWorkspaceNamed = (name: string) => (
  <span>
    Deleting form <strong>{name}</strong> will remove it from this workspace, but it will continue to appear in any
    workflows or blueprints that are already using it. Deleting this form cannot be undone.
  </span>
);
export const FieldNameAlreadyInUse = (field: string) => `Field name ${field} is already in use`;
export const NumberOfFields = (count: number) => (count > 1 || count === 0 ? `${count} fields` : `${count} field`);
export const SavedAt = (time: string) => `Autosaved at ${time}`;
export const SpectrumFormBuilderFieldsTitleBlueprintFieldsLink = <span>Blueprints &rarr; Fields</span>;
export const SpectrumFormBuilderFieldsTitleTooltip = (fieldsLink: React.ReactNode) => (
  <p>This list shows all fields in your workspace. You can create new fields here or manage fields in {fieldsLink}.</p>
);
export const SuccessToastPublish = (name: string | JSX.Element) => <span>Form {name} successfully published</span>;
export const SuccessToastSaveToMyForms = (name: string | JSX.Element) => (
  <span>Form {name} successfully saved to My Forms</span>
);
// Workflow Data Tab - Outdated fields
// Workflow ID
// to be used where WorkflowReferenceID is too long to fit
export const WorkflowReferenceIdCustomPrefixText = `Workflows created from this blueprint will have a reference ID with a custom prefix and an auto-incrementing number. IDs are only generated for workflows created from published blueprints and will automatically be added to the workflow's naming convention.`;
export const WorkspaceChangeNotification = (workspaceName: string) => (
  <span>
    You&apos;re currently in the <strong>{workspaceName}</strong> workspace.
  </span>
);

// Device verification
export const DeviceVerificationToCompleteYourWorkCTA = (
  <span>
    <strong>We have sent you an email. You’ll need to verifiy your device to access the task.</strong>
  </span>
);
export const DeviceVerificationDontSeeAnEmail = (
  <span>
    Check your inbox for the email with the subject <strong>Verify device</strong>
  </span>
);
export const DeviceVerificationStillDidntGetIt = (
  <span>
    <strong>Didn’t get it?</strong> Only people who have been assigned to or CC’ed to this task will be able access the
    task.
  </span>
);
export const DeviceVerificationInstructionsListItemTwo = (
  <span>
    <strong>How do I access this task?</strong> Only people who have been assigned to or CC’ed to this task will be able
    access the task.
  </span>
);
// Create workflow via email
export const ConfigureDialogRadioOptionLabel = (name: string, label: string) => (
  <span>
    <strong>{name}</strong>. {label}
  </span>
);
export const ContactEmail = (username: string, domain: string) => `${username}@${domain}`;
export const EmailCopied = CopiedToClipboard("email");
export const EmailFieldChipTooltipText = (fieldName: string) =>
  `${fieldName} will only be included if the workflow was created via email.`;
export const InvalidEmailErrorMessage = "Email can only include letters (a-z), numbers, and periods.";
export const PublishBlueprintWithSameEmailConfirmationDialogContent = (blueprintLink: ReactNode) => (
  <>
    {blueprintLink} already uses the blueprint email address that you chose. If you proceed with publishing, the email
    address will be reassigned to this blueprint instead. Do you want to continue?
  </>
);
export const UnableToPublishDialogContent = (email: string) =>
  `The email associated with this blueprint (${email}) is no longer available. Please choose another email in order to publish.`;
// Privacy acknowledgement.
export const EmployeePrivacyNotice = "Employee Privacy Notice";
export const PrivacyAcknowledgementBannerMessage = (link: ReactNode) => (
  <>
    By using Regrello, you acknowledge that the tool administrators will use your personal data (name, email, and DSID)
    for authentication and notifications, in accordance with the {link != null ? link : EmployeePrivacyNotice}.
  </>
);

// App generation
export const BlueprintGenerationJobNotFoundDescription = (
  <span>
    We could not find an active AI Tailoring job with the provided job UUID. Please make sure you pasted the URL
    correctly. Taking you to <strong>My Blueprints</strong> now.
  </span>
);
export const BlueprintRedirectMessageMyBlueprints = (
  <span>
    Taking you to <strong>My Blueprints</strong> now.
  </span>
);
export const FormGenerationSuccessMessage = (formName: string) => (
  <span>
    Successfully generated <strong>{formName}</strong>
  </span>
);
export const GenerationSuccessMessage = (blueprintName: string) => (
  <span>
    Successfully generated <strong>{blueprintName}</strong>
  </span>
);
export const GenerationFailedMessage = (blueprintName: string) => (
  <span>
    AI Tailoring is unable to generate {blueprintName}. Please try using a more descriptive process name or check that
    the right industry was used.
  </span>
);
export const GeneratingFormNOfN = (currentFormIndex: number, formCount: number) =>
  `Generating form ${currentFormIndex + 1} of ${formCount}`;
// Blueprint transfer
export const ImportBlueprintFileUploadHelperText = `To export a blueprint from another workspace, find a blueprint on the Blueprints page and click “Export as .rex".`;
export const ImportSuccessMessageWithName = (blueprintName: string) => (
  <span>
    Successfully imported <strong>{blueprintName}</strong>
  </span>
);
export const NamedNewField = (newFieldName: string) => `(New) ${newFieldName}`;
export const ImportFailedForBlueprintMessage = (blueprintName: string) => (
  <span>
    We were unable to import {blueprintName}. Please review your field mapping and original export file and try again.
  </span>
);
// Permissions V2
export const AddRole = "Add role";
export const AddRoleDisabledText = "Please contact an admin if you would like to create a new role";
export const AddRoleOptionToRegrello = ({ name }: { name: JSX.Element }) => (
  <span>Add role &apos;{name}&apos; to Regrello</span>
);
export const RegrelloFormFieldPartySelectWithRoleFilterSingleRole = (
  roleName: string,
  subject: string,
  icon: JSX.Element,
) => (
  <>
    Showing {subject} who have the {icon} <span className="font-bold">{roleName}</span> role.
  </>
);
export const RegrelloFormFieldPartySelectWithRoleFilterMultipleRoles = (subject: string, icon: JSX.Element) => (
  <>
    {icon} Showing {subject} with multiple roles.
  </>
);
export const ShowingAllSubjectInTheWorkspace = (subject: string) => `Showing all ${subject} in the workspace.`;
export const RoleInUseDeleteButtonDisabled = (blueprintCount: number) => (
  <>
    This role cannot be deleted because it is being used in <strong>{blueprintCount}</strong>{" "}
    {blueprintCount === 1 ? "blueprint" : "blueprints"}.
  </>
);
export const RoleDeleteDialogUsageWarning = (name: string) => (
  <>
    <p>
      <strong>{name}</strong> may be used in active tasks and workflows.
    </p>
    <br />
    <p>
      People with this role will not able to access active and completed workflows, and tasks assigned to this role will
      need to be reassigned.
    </p>
  </>
);
export const YouLackNecessaryPermissions = "You lack the necessary permissions";

// Locked workflows
export const LockedWorkflowNamingConventionTooltip = "The naming convention cannot be edited";
export const LockedWorkflowReceiveInfoSharePointTooltip = "Fields received from SharePoint cannot be edited";
export const LockedWorkflowSendInfoDocuSignTooltip = "Fields passed to DocuSign cannot be edited";
export const LockedWorkflowSendInfoSharePointTooltip = "Fields passed to SharePoint cannot be edited";
// Auto adjusting due dates + Business calendar
// Integration authentication
export const IntegrationAuthenticationDisabledDeleteButtonTooltipText = (
  integrationsUsingAuthentication: number | undefined,
) =>
  `This connection cannot be deleted because it is still being used in ${integrationsUsingAuthentication} existing integration(s).`;
// AI Monitor.
export const AiMonitor = "AI Monitor";
export const NameReportedAProblemOnTask = (partyName: string, taskName: string) =>
  `${partyName} reported a problem on task ${taskName}`;
export const PrefixAgo = (prefix: string) => `${prefix} ago`;
