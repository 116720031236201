// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { NameTemplateWithSuffixFields } from './NameTemplateWithSuffixFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { WorkflowStageFields } from './WorkflowStageFields.generated';
import { WorkflowQueryActionItemTemplateFields } from './WorkflowQueryActionItemTemplateFields.generated';
import { WorkflowQueryActionItemFields } from './WorkflowQueryActionItemFields.generated';
import { AutomationRequestFields } from './AutomationRequestFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { AutomationRequestParamFields } from './AutomationRequestParamFields.generated';
import { FieldInstanceFields } from './FieldInstanceFields.generated';
import { FieldInstanceBaseFields } from './FieldInstanceBaseFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { FieldInstanceSpectrumMetadataFields } from './FieldInstanceSpectrumMetadataFields.generated';
import { FieldInstanceSpectrumFields } from './FieldInstanceSpectrumFields.generated';
import { SpectrumFieldVersionFields } from './SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldInstanceValueFieldsFieldInstanceMultiValueDocument, FieldInstanceValueFieldsFieldInstanceMultiValueFloat, FieldInstanceValueFieldsFieldInstanceMultiValueInt, FieldInstanceValueFieldsFieldInstanceMultiValueParty, FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueFieldsFieldInstanceMultiValueString, FieldInstanceValueFieldsFieldInstanceMultiValueTime, FieldInstanceValueFieldsFieldInstanceValueBoolean, FieldInstanceValueFieldsFieldInstanceValueFloat, FieldInstanceValueFieldsFieldInstanceValueInt, FieldInstanceValueFieldsFieldInstanceValueParty, FieldInstanceValueFieldsFieldInstanceValueString, FieldInstanceValueFieldsFieldInstanceValueTime } from './FieldInstanceValueFields.generated';
import { FieldInstanceValueStringFields } from './FieldInstanceValueStringFields.generated';
import { FieldInstanceValueStringBaseFields } from './FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from './AcyclicFieldInstanceValueStringFields.generated';
import { FieldInstanceMultiValueStringFields } from './FieldInstanceMultiValueStringFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from './FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from './AcyclicFieldInstanceMultiValueStringFields.generated';
import { FieldInstanceValueIntFields } from './FieldInstanceValueIntFields.generated';
import { FieldInstanceValueIntBaseFields } from './FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from './AcyclicFieldInstanceValueIntFields.generated';
import { FieldInstanceValueFloatFields } from './FieldInstanceValueFloatFields.generated';
import { FieldInstanceValueFloatBaseFields } from './FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from './AcyclicFieldInstanceValueFloatFields.generated';
import { FieldInstanceValueBooleanFields } from './FieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueBooleanBaseFields } from './FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from './AcyclicFieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueTimeFields } from './FieldInstanceValueTimeFields.generated';
import { FieldInstanceValueTimeBaseFields } from './FieldInstanceValueTimeBaseFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from './AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceFields } from './AcyclicFieldInstanceFields.generated';
import { AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime, AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean, AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceValueString, AcyclicFieldInstanceValueFieldsFieldInstanceValueTime } from './AcyclicFieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from './AcyclicFieldInstanceValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from './AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from './AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from './AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from './AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from './AcyclicFieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceValuePartyFields } from './FieldInstanceValuePartyFields.generated';
import { FieldInstanceMultiValuePartyFields } from './FieldInstanceMultiValuePartyFields.generated';
import { FieldInstanceMultiValueDocumentFields } from './FieldInstanceMultiValueDocumentFields.generated';
import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from './FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { FieldInstanceMultiValueIntFields } from './FieldInstanceMultiValueIntFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from './FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatFields } from './FieldInstanceMultiValueFloatFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from './FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeFields } from './FieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from './FieldInstanceMultiValueTimeBaseFields.generated';
import { ActionItemApprovalFields } from './ActionItemApprovalFields.generated';
import { ActionItemAuditHistoryFields } from './ActionItemAuditHistoryFields.generated';
import { ActionItemAuditHistoryDeltaFields } from './ActionItemAuditHistoryDeltaFields.generated';
import { ActionItemAuditHistoryDocumentRelatedRecordFields } from './ActionItemAuditHistoryDocumentRelatedRecordFields.generated';
import { ActionItemAuditHistoryFieldInstanceRelatedRecordFields } from './ActionItemAuditHistoryFieldInstanceRelatedRecordFields.generated';
import { RootCommentFields } from './RootCommentFields.generated';
import { CommentFields } from './CommentFields.generated';
import { IntegrationAutomationInstanceFields } from './IntegrationAutomationInstanceFields.generated';
import { IntegrationAutomationSummaryFields } from './IntegrationAutomationSummaryFields.generated';
import { IntegrationAuthenticationFields } from './IntegrationAuthenticationFields.generated';
import { IntegrationAutomationInputFields } from './IntegrationAutomationInputFields.generated';
import { IntegrationAutomationOutputFields } from './IntegrationAutomationOutputFields.generated';
import { ActionItemPermissionsFields } from './ActionItemPermissionsFields.generated';
import { LineFields } from './LineFields.generated';
import { WorkflowQueryChildActionItemTemplateFields } from './WorkflowQueryChildActionItemTemplateFields.generated';
import { ActionItemTemplatePermissionsFields } from './ActionItemTemplatePermissionsFields.generated';
import { ActionItemTemplateStartAfterActionItemTemplateChain } from './ActionItemTemplateStartAfterActionItemTemplateChain.generated';
import { ActionItemTemplateStartAfterActionItemTemplateFields } from './ActionItemTemplateStartAfterActionItemTemplateFields.generated';
import { RejectActionFields } from './RejectActionFields.generated';
import { NameTemplateFields } from './NameTemplateFields.generated';
import { ConditionalExpressionGroupFields } from './ConditionalExpressionGroupFields.generated';
import { FieldInstanceConditionalExpressionFields } from './FieldInstanceConditionalExpressionFields.generated';
import { FieldInstanceWithCrossWorkflowFields } from './FieldInstanceWithCrossWorkflowFields.generated';
import { FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDocument, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueFloat, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueInt, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueParty, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueString, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueTime, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueBoolean, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueFloat, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueInt, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueParty, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueString, FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueTime } from './FieldInstanceValueWithCrossWorkflowFields.generated';
import { FieldInstanceValueStringWithCrossWorkflowFields } from './FieldInstanceValueStringWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueStringWithCrossWorkflowFields } from './FieldInstanceMultiValueStringWithCrossWorkflowFields.generated';
import { FieldInstanceValueIntWithCrossWorkflowFields } from './FieldInstanceValueIntWithCrossWorkflowFields.generated';
import { FieldInstanceValueFloatWithCrossWorkflowFields } from './FieldInstanceValueFloatWithCrossWorkflowFields.generated';
import { FieldInstanceValueBooleanWithCrossWorkflowFields } from './FieldInstanceValueBooleanWithCrossWorkflowFields.generated';
import { FieldInstanceValueTimeWithCrossWorkflowFields } from './FieldInstanceValueTimeWithCrossWorkflowFields.generated';
import { FieldInstanceValuePartyWithCrossWorkflowFields } from './FieldInstanceValuePartyWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValuePartyWithCrossWorkflowFields } from './FieldInstanceMultiValuePartyWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueDocumentWithCrossWorkflowFields } from './FieldInstanceMultiValueDocumentWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields } from './FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueIntWithCrossWorkflowFields } from './FieldInstanceMultiValueIntWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueFloatWithCrossWorkflowFields } from './FieldInstanceMultiValueFloatWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueTimeWithCrossWorkflowFields } from './FieldInstanceMultiValueTimeWithCrossWorkflowFields.generated';
import { WorkflowCollaborationFields } from './WorkflowCollaborationFields.generated';
import { PartyFields } from './PartyFields.generated';
import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { StarWithoutEntityFields } from './StarWithoutEntityFields.generated';
import { WorkflowGraphFields } from './WorkflowGraphFields.generated';
import { WorkflowGraphStageFields } from './WorkflowGraphStageFields.generated';
import { GenericWorkflowStageFields } from './GenericWorkflowStageFields.generated';
import { GenericWorkflowActionItemFields } from './GenericWorkflowActionItemFields.generated';
import { WorkflowGraphActionItemFields } from './WorkflowGraphActionItemFields.generated';
import { NotificationCardBaseFields } from './NotificationCardBaseFields.generated';
import { gql } from '@apollo/client';
import { FieldInstanceValueFields } from './FieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValueFields } from './AcyclicFieldInstanceValueFields.generated';
import { FieldInstanceValueWithCrossWorkflowFields } from './FieldInstanceValueWithCrossWorkflowFields.generated';
export type WorkflowFields = { id: number, name: string, hasException: boolean, createdAt: string, endedAt?: string | null, updatedAt: string, description?: string | null, summary?: string | null, stageSummary?: string | null, scheduleStatus?: Types.WorkflowScheduleStatus | null, isVisibilityLimitedForExternalParties: boolean, startedAt?: string | null, completedAt?: string | null, referenceNumberPrefix?: string | null, referenceNumber?: number | null, isLocked: boolean, autoAdjustDueOn: boolean, plannedDueOn?: string | null, adjustedDueOn?: string | null, nameTemplate?: NameTemplateWithSuffixFields | null, createdBy: UserBaseFields, updatedBy: PartyBaseFields, scheduleHistory: Array<{ id: number, createdAt: string, status: Types.WorkflowScheduleStatus, createdBy: { id: number, name?: string | null } }>, stages: Array<WorkflowStageFields>, tags: Array<TagFields>, blueprint?: { id: number, name: string, deletedAt?: string | null, versionNumber?: number | null, permissions: { canRead: boolean }, variantData?: { conditionGroup?: ConditionalExpressionGroupFields | null } | null } | null, fieldInstances: Array<FieldInstanceWithCrossWorkflowFields>, collaborators: Array<PartyBaseFields>, lines: Array<LineFields>, collaborations: Array<WorkflowCollaborationFields>, permissions: WorkflowPermissionsFields, currentUserStar?: StarWithoutEntityFields | null, createdByActionItem?: { isCreatedByActionItemWorkflowDeleted: boolean, isParentWorkflowPaused: boolean, isParentWorkflowEnded: boolean, isActivelyLinkedToWorkflow: boolean, createdByActionItemWorkflow: { id: number, name: string, permissions: WorkflowPermissionsFields } } | null, graph: WorkflowGraphFields, notificationCards?: { notificationCards: Array<NotificationCardBaseFields> } | null };

export const WorkflowFields = gql`
    fragment WorkflowFields on Workflow {
  id
  name
  hasException
  nameTemplate {
    ...NameTemplateWithSuffixFields
  }
  createdAt
  createdBy {
    ...UserBaseFields
  }
  endedAt
  updatedAt
  updatedBy {
    ...PartyBaseFields
  }
  description
  summary
  stageSummary
  scheduleStatus
  scheduleHistory {
    id
    createdBy {
      id
      name
    }
    createdAt
    status
  }
  stages {
    ...WorkflowStageFields
  }
  tags {
    ...TagFields
  }
  blueprint {
    id
    name
    deletedAt
    permissions {
      canRead
    }
    versionNumber
    variantData {
      conditionGroup {
        ...ConditionalExpressionGroupFields
      }
    }
  }
  fieldInstances {
    ...FieldInstanceWithCrossWorkflowFields
  }
  collaborators {
    ...PartyBaseFields
  }
  lines {
    ...LineFields
  }
  collaborations {
    ...WorkflowCollaborationFields
  }
  permissions {
    ...WorkflowPermissionsFields
  }
  isVisibilityLimitedForExternalParties
  currentUserStar {
    ...StarWithoutEntityFields
  }
  startedAt
  completedAt
  createdByActionItem {
    createdByActionItemWorkflow {
      id
      name
      permissions {
        ...WorkflowPermissionsFields
      }
    }
    isCreatedByActionItemWorkflowDeleted
    isParentWorkflowPaused
    isParentWorkflowEnded
    isActivelyLinkedToWorkflow
  }
  referenceNumberPrefix
  referenceNumber
  graph {
    ...WorkflowGraphFields
  }
  isLocked
  autoAdjustDueOn
  plannedDueOn
  adjustedDueOn
  notificationCards {
    notificationCards {
      ...NotificationCardBaseFields
    }
  }
}
    `;