import { t } from "@lingui/macro";
import { clsx, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { RegrelloCommandItem, RegrelloIcon, RegrelloIconName, useCommandState } from "@regrello/ui-core";
import { ReactNode, useMemo } from "react";

import { FeatureFlagService } from "../../../../../services/FeatureFlagService";

import { AddOptionToRegrello, CreateNewFieldEmpty } from "@/strings";

interface RegrelloSelectV2AddOptionProps {
  /**
   * If allowCreateOptions is false, the text to display instead of the add button.
   */
  allowCreateDisabledText?: string;

  /**
   * Whether add option is allowed. Controlling styling and disabled state.
   */
  allowCreateOptions?: boolean;

  iconName?: RegrelloIconName;

  /**
   * Custom message to display when there is no input provided.
   */
  message?: ReactNode;

  /**
   * Custom message to display when an input is provided.
   */
  messageWithInput?: ({ name }: { name: JSX.Element }) => JSX.Element;

  /**
   * Invokes this callback on select with input value passed as parameter.
   */
  onSelect?: (inputValue: string) => void;

  /**
   * A unique value for this item.
   *
   * @default "__CREATE_NEW_FIELD__"
   */
  value?: string | undefined;
}

/**
 * This component can be used in `extraOptions` of `RegrelloFormFieldSelect` to render `add` button with access to the `input` state.
 */
export const RegrelloSelectV2AddOption = ({
  allowCreateDisabledText,
  allowCreateOptions,
  iconName,
  message,
  messageWithInput,
  onSelect,
  value = "__CREATE_NEW_FIELD__",
}: RegrelloSelectV2AddOptionProps) => {
  const searchInput = useCommandState((state) => state.search);

  let text: ReactNode = CreateNewFieldEmpty;

  if (allowCreateOptions) {
    if (searchInput != null && searchInput !== EMPTY_STRING) {
      text =
        messageWithInput != null
          ? messageWithInput({ name: <strong>{searchInput}</strong> })
          : AddOptionToRegrello({ name: <strong>{searchInput}</strong> });
    } else {
      text = message != null ? message : text;
    }
  }

  if (!allowCreateOptions) {
    text =
      allowCreateDisabledText != null
        ? allowCreateDisabledText
        : FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)
          ? t`You require at least 'Creator' permissions to add a new field`
          : t`Please contact an admin if you would like to add a new field`;
  }

  const textElement = useMemo(() => {
    if (iconName != null) {
      return (
        <div className={clsx("flex gap-2", { "text-primary-textMuted": allowCreateOptions })}>
          <RegrelloIcon iconName={iconName} />
          {text}
        </div>
      );
    }

    return <span className={clsx({ "text-primary-textMuted": allowCreateOptions })}>{text}</span>;
  }, [allowCreateOptions, iconName, text]);

  return (
    <RegrelloCommandItem
      data-testid={DataTestIds.FORM_FIELD_SELECT_ADD_OPTION}
      disabled={!allowCreateOptions}
      forceMount={true}
      onSelect={() => {
        onSelect?.(searchInput);
      }}
      selected={false}
      text={textElement}
      value={value}
    />
  );
};
