// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime, AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean, AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceValueString, AcyclicFieldInstanceValueFieldsFieldInstanceValueTime } from './AcyclicFieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValueStringFields } from './AcyclicFieldInstanceValueStringFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from './AcyclicFieldInstanceMultiValueStringFields.generated';
import { AcyclicFieldInstanceValueIntFields } from './AcyclicFieldInstanceValueIntFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from './AcyclicFieldInstanceValueFloatFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from './AcyclicFieldInstanceValueBooleanFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from './AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from './AcyclicFieldInstanceValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from './AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from './AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from './AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from './AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from './AcyclicFieldInstanceMultiValueTimeFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { gql } from '@apollo/client';
import { AcyclicFieldInstanceValueFields } from './AcyclicFieldInstanceValueFields.generated';
export type AcyclicFieldInstanceFields = { regrelloObjectPropertyId?: number | null, controlsActionItemOrActionItemTemplateField?: Types.ActionItemOrActionItemTemplateField | null, field: FieldFields, values: Array<AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime | AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean | AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat | AcyclicFieldInstanceValueFieldsFieldInstanceValueInt | AcyclicFieldInstanceValueFieldsFieldInstanceValueParty | AcyclicFieldInstanceValueFieldsFieldInstanceValueString | AcyclicFieldInstanceValueFieldsFieldInstanceValueTime>, spectrumFieldVersion?: { id: number, uuid: string, createdAt: string, name: string, helperText: string, description: string, internalName: string, spectrumField: SpectrumFieldFields, propertyType: PropertyTypeFields, validationType: SpectrumFieldValidationTypeFields, fieldConstraints: Array<SpectrumFieldConstraintFields>, allowedValues: Array<SpectrumFieldAllowedValueFields>, fieldUnit?: FieldUnitFields | null } | null, actionItem?: { id: number, name: string, status: Types.ActionItemStatus, displayOrder: number, workflowReference?: { stageId: number, stageName: string, stageExecutionOrder: number } | null } | null, actionItemTemplate?: { id: number, displayOrder: number, name: string } | null, workflow?: { id: number, name: string } | null, workflowTemplate?: { id: number, name: string } | null, writtenByActionItem?: { id: number, name: string, workflowReference?: { stageId: number, stageName: string, stageExecutionOrder: number } | null } | null };

export const AcyclicFieldInstanceFields = gql`
    fragment AcyclicFieldInstanceFields on FieldInstance {
  field {
    ...FieldFields
  }
  values {
    ...AcyclicFieldInstanceValueFields
  }
  spectrumFieldVersion {
    id
    uuid
    createdAt
    name
    helperText
    description
    internalName
    spectrumField {
      ...SpectrumFieldFields
    }
    propertyType {
      ...PropertyTypeFields
    }
    validationType {
      ...SpectrumFieldValidationTypeFields
    }
    fieldConstraints {
      ...SpectrumFieldConstraintFields
    }
    allowedValues {
      ...SpectrumFieldAllowedValueFields
    }
    fieldUnit {
      ...FieldUnitFields
    }
  }
  actionItem {
    id
    name
    status
    displayOrder
    workflowReference {
      stageId
      stageName
      stageExecutionOrder
    }
  }
  actionItemTemplate {
    id
    displayOrder
    name
  }
  workflow {
    id
    name
  }
  workflowTemplate {
    id
    name
  }
  writtenByActionItem {
    id
    name
    workflowReference {
      stageId
      stageName
      stageExecutionOrder
    }
  }
  regrelloObjectPropertyId
  controlsActionItemOrActionItemTemplateField
}
    `;