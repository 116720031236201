import { i18n } from "@lingui/core";
import { detect, fromNavigator, fromStorage, fromUrl } from "@lingui/detect-locale";
import { FeatureFlagKey } from "@regrello/feature-flags-api";

import { FeatureFlagService } from "./services/FeatureFlagService";

// can be a function with custom logic or just a string, `detect` method will handle it
const DEFAULT_LANGUAGE = "en";
const SUPPORTED_LANGUAGES = new Set(["en", "de", "fr"]);

function fromEnvironment() {
  // Detect as default when i18n is disabled
  return FeatureFlagService.isEnabled(FeatureFlagKey.I18N_PHASE_ONE_2024_10) ? undefined : DEFAULT_LANGUAGE;
}

export async function initTranslation() {
  const result = detect(fromEnvironment(), fromUrl("lang"), fromStorage("lang"), fromNavigator()) ?? DEFAULT_LANGUAGE;

  // biome-ignore lint/suspicious/noConsoleLog: i18n debug (temporary)
  console.log(
    `[i18n] Detected: env=${fromEnvironment()} url=${fromUrl("lang")} pref=${fromStorage("lang")} browser=${fromNavigator()}`,
  );

  // Strip an country postfix
  const language = result?.replace(/-[A-Z]+/, "");

  // Make sure we only deal with officially supported languages
  const effectiveLanguage = SUPPORTED_LANGUAGES.has(language) ? language : DEFAULT_LANGUAGE;

  // Make sure to let the browser know what language we are using
  document.documentElement.lang = effectiveLanguage;

  // Dynamically load messages from separate chunk
  const { messages } = await import(`./locales/${effectiveLanguage}/messages.po`);

  // biome-ignore lint/suspicious/noConsoleLog: i18n debug (temporary)
  console.log(`[i18n] Messages ${effectiveLanguage}=${Object.keys(messages).length} activated`);

  i18n.load(effectiveLanguage, messages);
  i18n.activate(effectiveLanguage);
}
